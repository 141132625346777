import {Button, FileInput, Modal, Stack} from "@mantine/core";
import {useState} from "react";
import {useForm} from "@mantine/form";
import {DeviceApi, ImportDeviceList} from "../../api/device/DeviceApi";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";

export type ImportDevicesDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
}

function ImportDevicesDialog(attrs: ImportDevicesDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { file: null }
    });

    // const [file, setFile] = useState<File | null>();

    const doSubmitSuccess = (result: ImportDeviceList) => {
        if (result.errors.length > 0) {
            showNotification({
                title: 'Nie udało się zaimportować niektórych urządzeń...',
                message: 'Upewnij się, że jednostka w każdym urządzeniu jest unikalna. W razie potrzeby poproś o pomoc administratora.',
                color: 'red',
                icon: <IconX/>
            });

            const pos = result.errors.map(e => e.rowIndex).join(", ");

            showNotification({
                title: 'Błąd wystąpił na wszystkich pozycjach:',
                message: pos,
                color: 'red',
                icon: <IconX/>
            });

            form.setFieldError('file', `Błąd na pozycji: ${pos}`)

            return;
        }

        showNotification({
            title: 'Pomyślnie zaimportowano listę urządzeń',
            message: 'Są one widoczne na stronie \'Urządzenia\'',
            color: 'green',
            icon: <IconCheck/>
        });

        attrs.closeAndUpdate();
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zaimportować urządzeń',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const doSubmit = (values: any) => {
        if (!values.file) {
            form.setFieldError('file', 'Proszę wybrać plik')
            return;
        }

        startLoading();

        DeviceApi.importDevices(values.file as File)
            .then(doSubmitSuccess)
            .catch(doSubmitError)
            .finally(stopLoading);
    }

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Importowanie listy urządzeń">
           <form onSubmit={form.onSubmit(doSubmit)}>
               <Stack>
                   <FileInput
                       withAsterisk
                       placeholder="Wybierz plik .xlsx"
                       accept=".xlsx"
                       key={form.key('file')}
                       { ...form.getInputProps('file') }
                   />

                   <Button type="submit" loading={loading}>
                       Wyślij
                   </Button>
               </Stack>
           </form>
        </Modal>
    )
}

export default ImportDevicesDialog;
