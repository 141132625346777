/* eslint-disable */
import {CompositeReport, CompositeReportApi, EditModel} from "../../../../api/composite/report/CompositeReportApi";
import {Button, Flex, Modal, Space} from "@mantine/core";
import {useEffect, useState} from "react";
import {CommentPresetsApi} from "../../../../api/comment/presets/CommentPresetsApi";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";
import NavSection from "./NavSection";
import ContentSection from "./ContentSection";
import SearchSection from "./SearchSection";
import CurrentCommentsSection from "./CurrentCommentsSection";

export type NiceCommentsDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
    compositeReport: CompositeReport | undefined;
}

export type CommentGroup = { firstLetter: string, comments: string[] };

function groupPresets(comments: string[]): CommentGroup[] {
    const groups = comments.sort().reduce((acc, str) => {
        const firstLetter = str.charAt(0).toLowerCase();

        if (!acc[firstLetter]) {
            acc[firstLetter] = [];
        }

        acc[firstLetter].push(str);

        return acc;
    }, {} as Record<string, string[]>);

    return Object.keys(groups).map(letter => ({
        firstLetter: letter,
        comments: groups[letter]
    }));
}

// function groupPresets(comments: string[]): CommentGroup[] {
//     const polishLetters = '';
//     const groups: Record<string, string[]> = {};
//
//     for (const letter of polishLetters) {
//         groups[letter] = [];
//     }
//
//     comments.forEach(str => {
//         const firstLetter = str.charAt(0).toLowerCase();
//         if (groups[firstLetter] !== undefined) {
//             groups[firstLetter].push(str);
//         }
//     });
//
//     return polishLetters.split('').map(letter => ({
//         firstLetter: letter,
//         comments: groups[letter]
//     }));
// }


function NiceCommentsDialog(attrs: NiceCommentsDialogAttrs) {
    const [activeLetter, setActiveLetter] = useState<string | undefined>('search');
    const [presets, setPresets] = useState<CommentGroup[]>([]);
    const [comments, setComments] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ]

    const reloadPresetsError = () => {
        showNotification({
            title: 'Nie udało się pobrać zdefiniowanych komentarzy',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const deleteComment = (s: string) => {
        setComments([...comments].filter(x => x !== s));
    }

    const addComment = (s: string) => {
        setComments([...comments, s]);
    }

    const addOrDelete = (s: string) => {
        if (isPresent(s)) {
            deleteComment(s);
            return;
        }

        addComment(s);
    }

    const isPresent = (s: string) => {
        return comments.includes(s);
    }

    const reloadPresets = () => {
        CommentPresetsApi.getAll()
            .then(groupPresets)
            .then(setPresets)
            .catch(reloadPresetsError);
    }

    const reload = () => {
        reloadPresets();
        setComments([...attrs.compositeReport?.comments ?? []]);
    }

    const saveError = () => {
        showNotification({
            title: 'Nie udało się zapisać komentarzy',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const saveSuccess = () => {
        showNotification({
            title: 'Edytowano komentarze',
            message: 'Pomyślnie ustawiono komentarze na raporcie',
            color: 'green',
            icon: <IconCheck/>
        })
    }

    const save = () => {
        if (!attrs.compositeReport) return;

        startLoading();

        const editModel: EditModel = {
            id: attrs.compositeReport.id,
            title: attrs.compositeReport.title!!,
            description: attrs.compositeReport.description,
            comments: comments,
            unit: attrs.compositeReport.unit
        }

        // startSaving();

        CompositeReportApi.edit(editModel)
            .then(saveSuccess)
            .then(attrs.closeAndUpdate)
            .catch(saveError)
            .finally(stopLoading);
    }

    const getActivePreset = () => {
        return presets.filter(x => x.firstLetter === activeLetter)[0];
    }

    const isSpecialPage = () => {
        if (!activeLetter) return false;
        return (activeLetter?.length ?? 0) > 1;
    }

    useEffect(reload, [attrs.compositeReport]);

    return (
        <>
            <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Komentarze" size="100%" >
                <Flex w="100%">
                    <NavSection
                        letter={activeLetter}
                        setLetter={(l) => setActiveLetter(l)}
                        presets={presets}
                    />

                    { activeLetter !== 'current' ? null : <CurrentCommentsSection commentsNotSaved={comments.sort()} comments={(attrs.compositeReport?.comments ?? []).sort()} addOrDelete={addOrDelete} isPresent={isPresent}/> }

                    { activeLetter !== 'search' ? null : <SearchSection presets={presets} addOrDelete={addOrDelete} isPresent={isPresent}/> }

                    { isSpecialPage() ? null : <ContentSection
                        addOrDelete={addOrDelete}
                        isPresent={isPresent}
                        preset={getActivePreset()}
                    /> }
                </Flex>

                <Space/>

                <Flex justify="right" align="flex-end" w="100%">
                    <Button onClick={save} loading={loading}>
                        Zapisz
                    </Button>
                </Flex>
            </Modal>
        </>
    );
}

export default NiceCommentsDialog;
