import "./Fonts.css";
import "./Colors.css";
import "./Components.css";
import {Outlet, useNavigate} from "react-router-dom";
import {ReactNode, useContext, useState} from "react";
import AuthContext from "../../contexts/AuthContext";
import {AppShell, Burger, Button, Group, Space} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {showNotification} from "@mantine/notifications";
import AuthenticateService from "../../services/auth/AuthenticateService";
import Navbar from "./Navbar";

/**
 * @author Kacper Faber
 * @since 0.0.1-SNAPSHOT
 */
export type LayoutContext = {
    title: string;
    setTitle: (title: string) => void;
    action?: ReactNode | null;
    setAction: (r: ReactNode | null) => void;
}

function Layout() {
    const [title, setTitle] = useState("TermoControl");
    const [action, setAction] = useState<ReactNode | null>(null);
    const layoutContext = { title, setTitle, action, setAction } satisfies LayoutContext;
    const [isNavOpen, navbar] = useDisclosure();

    const { user} = useContext(AuthContext);
    const navigate = useNavigate();

    const logout = () => {
        AuthenticateService.logout();

        showNotification({
            title: "Wylogowano!",
            message: "Zaloguj się ponownie, aby kontynuować pracę..."
        });

        navigate('/login');
    };

    return (
        <AppShell
            transitionDuration={500}
            transitionTimingFunction="ease"
            layout="alt"
            header={{ height: 50 }}
            navbar={{ width: 250, breakpoint: 'sm', collapsed: { mobile: !isNavOpen, desktop: !isNavOpen } }}
            padding="md"
        >
            <AppShell.Header>
                <Group h="100%" px="md" justify="space-between">
                    <Burger
                        opened={isNavOpen}
                        onClick={navbar.toggle}
                        size="sm"
                        color="grey"
                    />

                    { /* Awesome <3 */ }
                    <Space/>

                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                        <h5>{user?.username}</h5>
                        <Button variant="subtle" p={0} onClick={logout}>
                            {' (Wyloguj się)'}
                        </Button>
                    </div>
                </Group>
            </AppShell.Header>

            <Navbar
                isNavOpen={isNavOpen}
                toggle={navbar.toggle}
            />

            <AppShell.Main style={{background: 'var(--body-background)'}}>
                <Outlet
                    context={layoutContext}
                />
            </AppShell.Main>
        </AppShell>
    )
}

export default Layout;
