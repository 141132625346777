import {ActionIcon, Menu} from "@mantine/core";
import {IconDotsVertical} from "@tabler/icons-react";

export type CompositeReportTableDetailsMenuAttrs = {
    editData: () => void,
    editComments: () => void,
    approveReport: () => void,
    deleteReport: () => void,
    sendReport: () => void;
}

function CompositeReportTableDetailsMenu(attrs: CompositeReportTableDetailsMenuAttrs) {
    return (
        <Menu>
            <Menu.Target>
                <ActionIcon variant="transparent">
                    <IconDotsVertical size={32}/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item onClick={attrs.editData}>Edytuj dane</Menu.Item>
                <Menu.Item onClick={attrs.editComments}>Komentarze</Menu.Item>
                <Menu.Item onClick={attrs.approveReport}>Zatwierdź raport</Menu.Item>
                <Menu.Item onClick={attrs.sendReport}>Wyślij raport</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

export default CompositeReportTableDetailsMenu;
