// noinspection DuplicatedCode

import {Button, Modal, Stack} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useState} from "react";
import {DateInput} from "@mantine/dates";
import {CompositeReportApi, GenerateForSelectedDevicesQuery} from "../../../api/composite/report/CompositeReportApi";
import {showNotification} from "@mantine/notifications";
import {TimeInput} from "@mantine/dates";

type OptionalString = string | undefined;

export type GenerateForSelectedDevicesDialogAttrs = {
    deviceIds: string[];
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
}

const format = (dt: string) => {
    const date = new Date(dt);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

function GenerateForSelectedDevicesDialog(attrs: GenerateForSelectedDevicesDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            dateMin: '',
            dateMax: '',
            timeMin: undefined,
            timeMax: undefined
        }
    });

    const doSubmitError = () => {
        form.setFieldError('dateMin', 'Nie udało się wygenerować raportów...');

        showNotification({
            title: 'Nie udało się wygenerować raportów',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Wygenerowano raporty',
            message: 'Możesz teraz wyświetlić je na stronie \'Raporty\'',
            color: 'green'
        });
        attrs.closeAndUpdate();
    }

    const doSubmit = (values: {dateMin: string, dateMax: string, timeMax: OptionalString, timeMin: OptionalString}) => {
        startLoading();

        const dateMin = format(values.dateMin);
        const dateMax = format(values.dateMax);

        const query: GenerateForSelectedDevicesQuery = {
            dateMin,
            dateMax,
            deviceIds: attrs.deviceIds,
            timeMin: values.timeMin,
            timeMax: values.timeMax
        };

        CompositeReportApi.generateForSelectedDevices(query)
            .then(doSubmitSuccess)
            .catch(doSubmitError)
            .then(stopLoading);
    }

    return (
        <Modal centered opened={attrs.isOpen} onClose={attrs.justClose} title="Generowanie raportu dla wybranych urządzeń">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <DateInput
                        withAsterisk
                        valueFormat="DD-MM-YYYY"
                        label="Od"
                        placeholder="dd-mm-yyyy"
                        key={form.key('dateMin')}
                        { ...form.getInputProps('dateMin') }
                    />

                    <DateInput
                        withAsterisk
                        valueFormat="DD-MM-YYYY"
                        label="Do"
                        placeholder="dd-mm-yyyy"
                        key={form.key('dateMax')}
                        { ...form.getInputProps('dateMax') }
                    />

                    <TimeInput
                        label="Od"
                        placeholder="HH:mm"
                        key={form.key('timeMin')}
                        { ...form.getInputProps('timeMin') }
                    />

                    <TimeInput
                        label="Do"
                        placeholder="HH:mm"
                        key={form.key('timeMax')}
                        { ...form.getInputProps('timeMax') }
                    />

                    <Button type="submit" loading={loading}>
                        Wygeneruj
                    </Button>
                </Stack>
            </form>


        </Modal>
    );
}

export default GenerateForSelectedDevicesDialog;
