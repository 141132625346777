import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Layout from "./pages/_layout/Layout";
import IndexPage from "./pages/Index";
import ClientPage from "./pages/client/Client";
import LoginPage from "./pages/login/LoginPage";
import {ReactNode} from "react";
import AppContext from "./contexts/AppContext";
import {SessionStorage} from "./api/SessionStorage";
import EntireMantineContext from "./EntireMantineContext";
import DevicePage from "./pages/device/Device";
import DeviceReportPage from "./pages/device/report/DeviceReport";
import UserPage from "./pages/user/User";
import CompositeReport from "./pages/composite/report/CompositeReport";
import SendReport from "./pages/send/report/SendReport";
import SmtpConfig from "./pages/smtp/config/SmtpConfig";
import FtpConfig from "./pages/ftp/config/FtpConfig";
import CommentPresets from "./pages/comment/presets/CommentPresets";
import CompositeReportDetails from "./pages/composite/report/details/CompositeReportDetails";
import SendReportConfig from "./pages/send/report/config/SendReportConfig";
import PdfConfig from "./pages/pdf/config/PdfConfig";
import DeviceReportPreview from "./pages/device/report/DeviceReportPreview";
import DeviceView from "./pages/device/preview/DeviceView";
import CompositeReportViewDetails from "./pages/composite/report/view-details/CompositeReportViewDetails";
import CompositeReportTableDetails from "./pages/composite/report/table-details/CompositeReportTableDetails";
import ClientView from "./pages/client/view/ClientView";
import CalendarEvent from "./pages/calendar/event/CalendarEvent";
import CompositeReportExcelTableDetails from "./pages/composite/report/excel-details/CompositeReportExcelTableDetails";
import CompositeReportWork from "./pages/composite/report/work/CompositeReportWork";


type SecureElementAttrs = {
    children: ReactNode;
}

/**
 * Requires any user to be authenticated.
 * If it's not, it will redirect to /login.
 * @param children
 * @constructor
 */
function SecureElement({children}: SecureElementAttrs) {
    if (!SessionStorage.getToken()) {
        return (
            <Navigate to={"/login"} replace/>
        )
    }

    return (
        <>
            {children}
        </>
    );
}

function AppRouter() {
    return (
        <EntireMantineContext>
            <AppContext>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<LoginPage/>}/>

                        <Route element={<SecureElement children={<Layout/>}/> }>

                            <Route index={true} path="/" element={<IndexPage/>}/>

                            <Route path="/client" element={<ClientPage/>}/>

                            <Route path="/device" element={<DevicePage/>}/>
                            <Route path="/device/report" element={<DeviceReportPage/>}/>

                            <Route path="/composite/report" element={<CompositeReport/>}/>
                            <Route path="/comment/presets" element={<CommentPresets/>}/>
                            <Route path="/send/report" element={<SendReport/>}/>
                            <Route path="/smtp/config" element={<SmtpConfig/>}/>
                            <Route path="/composite/report/details/:compositeReportId" element={<CompositeReportDetails/>}/>
                            <Route path="/composite/report/view-details/:compositeReportId" element={<CompositeReportViewDetails/>}/>
                            <Route path="/composite/report/table-details/:compositeReportId" element={<CompositeReportTableDetails/>}/>
                            <Route path="/composite/report/excel-details/:compositeReportId" element={<CompositeReportExcelTableDetails/>}/>
                            <Route path="/send/report/config" element={<SendReportConfig/>}/>
                            <Route path="/pdf/config" element={<PdfConfig/>}/>

                            <Route path="/ftp/config" element={<FtpConfig/>}/>
                            <Route path="/device/report/preview/:deviceReportId" element={<DeviceReportPreview/>}/>
                            <Route path="/device/view/:deviceId" element={<DeviceView/>}/>

                            <Route path="/user" element={<UserPage/>}/>

                            <Route path="/client/view/:clientId" element={<ClientView/>}/>

                            <Route path="/calendar/event" element={<CalendarEvent/>}/>
                            <Route path="/composite/report/work" element={<CompositeReportWork/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AppContext>
        </EntireMantineContext>
    )
}

export default AppRouter;
