import {FtpConfig, FtpConfigApi} from "../../../api/ftp/config/FtpConfigApi";
import {Button, Modal, Stack, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useState} from "react";
import {showNotification} from "@mantine/notifications";
import {IconCircleCheck, IconCircleX} from "@tabler/icons-react";

export type TestFtpConfigDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    ftpConfig?: FtpConfig;
}

function TestFtpConfigDialog(attrs: TestFtpConfigDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { filePath: '/test.txt' },
        validate: {
            filePath: e => e.length < 2 ? 'Przynajmniej dwa znaki' : null
        }
    });

    const onSubmitError = () => {
        form.setFieldError('filePath', "Nie udało się odczytać zawartości pliku z serwera");

        showNotification({
            title: 'Nie udało się pobrać pliku z serwera FTP',
            message: 'Sprawdź konfigurację FTP, w razie potrzeby poproś o pomoc administratora ;)',
            color: 'red',
            icon: <IconCircleX size={16}/>
        });
    }

    const onSubmitSuccess = () => {
        showNotification({
            title: 'Konfiguracja FTP działa poprawnie',
            message: 'Pobrano plik z FTP',
            color: 'green',
            icon: <IconCircleCheck size={16}/>
        });
    }

    const doSubmit = ({filePath}: { filePath: string }) => {
        const doTestBody = { filePath, ftpConfigId: attrs.ftpConfig?.id!! };

        startLoading();

        FtpConfigApi.doTest(doTestBody)
            .then(onSubmitSuccess)
            .catch(onSubmitError)
            .finally(stopLoading);
    }

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Testowanie konfiguracji FTP">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Ścieżka do pliku"
                        placeholder="Ścieżka pliku do odczytania"
                        description="Spróbujemy pobrać zawartość tego pliku z serwera FTP"
                        key={form.key('filePath')}
                        { ...form.getInputProps('filePath') }
                    />

                    <Button type="submit" loading={loading}>
                        Wykonaj test
                    </Button>
                </Stack>
            </form>
        </Modal>
    )
}

export default TestFtpConfigDialog;
