import {Button, FileInput, Image, Modal, Stack} from "@mantine/core";
import {Device, DeviceApi} from "../../api/device/DeviceApi";
import React, {useEffect, useState} from "react";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";

export type DeviceCustomFooterDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
    deviceId?: string;
}

function DeviceCustomFooterDialog(attrs: DeviceCustomFooterDialogAttrs) {
    const [device, setDevice] = useState<Device>();
    const [sending, setSending] = useState(false);
    const [imageHash, setImageHash] = useState<number>();
    const [file, setFile] = useState<File | null>(null);

    const [startSending, stopSending] = [
        () => setSending(true),
        () => setSending(false)
    ];

    const reloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const reload = () => {
        if (!attrs.deviceId) return;

        DeviceApi.getById(attrs.deviceId)
            .then(setDevice)
            .then(() => setImageHash(Date.now()))
            .catch(reloadError);
    }

    const deleteFooterError = () => {
        showNotification({
            title: 'Nie udało się usunąć pieczątki',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const deleteFooter = () => {
        DeviceApi.resetFooterRight(attrs.deviceId!!)
            .then(reload)
            .catch(deleteFooterError);
    }

    const sendFileSuccess = () => {
        showNotification({
            title: 'Wysłano pieczątkę',
            message: 'Pieczątka zastąpi domyślną w nowych raportach.',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const sendFileError = () => {
        showNotification({
            title: 'Nie udało się wysłać pieczątki',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const sendFile = () => {
        startSending();

        DeviceApi.uploadFooterRight(attrs.deviceId!!, file!!)
            .then(reload)
            .then(sendFileSuccess)
            .then(() => setFile(null))
            .catch(sendFileError)
            .finally(stopSending);
    }

    useEffect(reload, [attrs.deviceId]);

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Edycja pieczątki">
            <Stack>
                {(device?.doesOverrideFooterRight !== true) ? null :
                    <Image
                        w={'10vw'}
                        h={'10vh'}
                        src={DeviceApi.getFooterRightUrl(device?.id) + `#${imageHash}`}
                    />
                }

                <FileInput
                    clearable
                    accept=".jpeg"
                    placeholder="Wybierz plik"
                    value={file}
                    onChange={setFile}
                />

                <Button disabled={!(device?.doesOverrideFooterRight === true)} color="red" onClick={deleteFooter}>Usuń</Button>
                <Button disabled={!file} onClick={sendFile} loading={sending}>Prześlij</Button>
            </Stack>
        </Modal>
    )
}

export default DeviceCustomFooterDialog;