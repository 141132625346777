import {SearchQuery} from "../../../api/send/report/SendReportApi";
import {useForm} from "@mantine/form";
import {Button, Flex, TextInput, Transition} from "@mantine/core";

export type SendReportFilterAttrs = {
    isOpen: boolean;
    justClose: () => void;
    searchQuery: SearchQuery;
    doUpdate: (n: SearchQuery) => void;
}

function SendReportFilter(attrs: SendReportFilterAttrs) {
    const form = useForm({
       mode: 'uncontrolled',
       initialValues: {
           query: '',
           clientId: '',
           compositeReportId: '',
           emailAddress: ''
       }
    });

    const doSubmit = (values: any) => {
        const searchQuery: SearchQuery = {
            ...attrs.searchQuery,
            q: values.query === '' ? undefined : values.query,
            emailAddress: values.emailAddress === '' ? undefined : values.emailAddress,
            compositeReportId: values.compositeReportId === '' ? undefined : values.compositeReportId,
            clientId: values.clientId === '' ? undefined : values.clientId
        };

        attrs.doUpdate(searchQuery);
    }

    return (
        <Transition mounted={attrs.isOpen} transition="pop">
            { styles =>
                <form onSubmit={form.onSubmit(doSubmit)} style={styles}>
                    <Flex direction="row" align="center" gap="md">
                        <TextInput
                            placeholder="Zapytanie"
                            key={form.key('query')}
                            { ...form.getInputProps('query') }
                        />

                        <TextInput
                            placeholder="Adres E-mail"
                            key={form.key('emailAddress')}
                            { ...form.getInputProps('emailAddress') }
                        />

                        <Button type="submit">
                            Szukaj
                        </Button>
                    </Flex>
                </form>
            }
        </Transition>
    );
}

export default SendReportFilter;
