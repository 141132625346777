import {Card, Group, Stack, Text} from "@mantine/core";
import React from "react";

function CardField(attrs: {title: string, description: string}) {
    return (
        <Card.Section withBorder inheritPadding p='md'>
            <Group justify="space-between">
                <Stack gap={0}>
                    <Text>
                        {attrs.title}
                    </Text>

                    <Text c="dimmed" size="sm">
                        {attrs.description}
                    </Text>
                </Stack>
            </Group>
        </Card.Section>
    );
}

export default CardField;