/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from "../../../../components/layout/PageHeader";
import PageContent from "../../../../components/layout/PageContent";
import {useForm} from "@mantine/form";
import {Button, Stack, TextInput} from "@mantine/core";
import {useEffect, useState} from "react";
import {SendReportApi, SendReportConfig} from "../../../../api/send/report/SendReportApi";
import {showNotification} from "@mantine/notifications";
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';

function SendReportConfigPage() {
    const [loading, setLoading] = useState(false);
    const startLoading = () => { setLoading(true); };
    const stopLoading = () => { setLoading(false); };

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content: ''
    });

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            subject: '',
            content: ''
        },

        validate: {
            subject: (e) => e.length < 5 ? 'Przynajmniej 5 znaków' : null
        }
    });

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Zapisano!',
            message: 'Zapisano!',
            color: 'green'
        });
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać ustawień',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSubmit = (values: any) => {
        startLoading();

        SendReportApi.editConfig({...values, content: editor?.getHTML() ?? ''} satisfies SendReportConfig)
            .then(doSubmitSuccess)
            .catch(doSubmitError)
            .finally(stopLoading);
    }

    const loadFormData = (config: SendReportConfig) => {
        form.setValues({
            content: config.content,
            subject: config.subject
        });

        if (editor) {
            editor?.commands?.setContent(config?.content ?? '')
        }
    }

    const onLoadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const reload = () => {
        startLoading();

        SendReportApi.getConfig()
            .then(loadFormData)
            .catch(onLoadError)
            .finally(stopLoading)
    }

    useEffect(reload, [editor]);

    // noinspection DuplicatedCode
    return (
        <>
            <PageHeader
                title="Ustawienia wysyłki"
                url="/send/report/config"
            />

            <PageContent>
                <form onSubmit={form.onSubmit(doSubmit)}>
                    <Stack>
                        <TextInput
                            withAsterisk
                            label="Temat wiadomości"
                            description="Np. 'Wygenerowano raport'"
                            key={form.key('subject')}
                            { ...form.getInputProps('subject') }
                        />

                        <RichTextEditor editor={editor} key={form.key('content')} { ...form.getInputProps('content') }>
                            <RichTextEditor.Toolbar sticky stickyOffset={60}>
                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.Bold />
                                    <RichTextEditor.Italic />
                                    <RichTextEditor.Underline />
                                    <RichTextEditor.Strikethrough />
                                    <RichTextEditor.ClearFormatting />
                                    <RichTextEditor.Highlight />
                                    <RichTextEditor.Code />
                                </RichTextEditor.ControlsGroup>

                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.H1 />
                                    <RichTextEditor.H2 />
                                    <RichTextEditor.H3 />
                                    <RichTextEditor.H4 />
                                </RichTextEditor.ControlsGroup>

                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.Blockquote />
                                    <RichTextEditor.Hr />
                                    <RichTextEditor.BulletList />
                                    <RichTextEditor.OrderedList />
                                    <RichTextEditor.Subscript />
                                    <RichTextEditor.Superscript />
                                </RichTextEditor.ControlsGroup>

                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.Link />
                                    <RichTextEditor.Unlink />
                                </RichTextEditor.ControlsGroup>

                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.AlignLeft />
                                    <RichTextEditor.AlignCenter />
                                    <RichTextEditor.AlignJustify />
                                    <RichTextEditor.AlignRight />
                                </RichTextEditor.ControlsGroup>

                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.Undo />
                                    <RichTextEditor.Redo />
                                </RichTextEditor.ControlsGroup>
                            </RichTextEditor.Toolbar>

                            <RichTextEditor.Content />
                        </RichTextEditor>

                        <Button type="submit" loading={loading}>
                            Zapisz
                        </Button>
                    </Stack>
                </form>
            </PageContent>
        </>
    );
}

export default SendReportConfigPage;