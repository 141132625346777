import {HttpClient} from "../_http/HttpClient";
import {Api} from "../Api";

export class FtpApi {
    /**
     * Redirects to resource in API. (/api/ftp/download?filePath)
     * @author Kacper Faber
     */
    static doDownloadFile(args: { filePath: string }): void {
        const baseUrl = Api.getUrl('/ftp/download');
        window.location.href = HttpClient.getUrl(baseUrl, { filePath: args.filePath });
    }

    static getDownloadUrl(args: { filePath: string}): string {
        const baseUrl = Api.getUrl('/ftp/download');
        return HttpClient.getUrl(baseUrl, { filePath: args.filePath });
    }
}
