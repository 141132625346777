/* eslint-disable */
import React, {useEffect, useState} from "react";
import Page from "../../../models/api/Page";
import CalendarEventApi, {Action, CalendarEvent, SearchQuery} from "../../../api/calendar/event/CalendarEventApi";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";
import PageContent from "../../../components/layout/PageContent";
import PageHeader from "../../../components/layout/PageHeader";
import {DataTable, DataTableColumn, DataTableSortStatus, useDataTableColumns} from "mantine-datatable";
import DateView from "../../../components/date/DateView";
import Cell from "../../../components/table/Cell";
import {useDisclosure} from "@mantine/hooks";
import CreateCalendarEventDialog from "../../../components/calendar/event/create/CreateCalendarEventDialog";
import CalendarEventMenu from "./CalendarEventMenu";
import {modals} from "@mantine/modals";
import PaginationFooter from "../../../components/pagination/PaginationFooter";

const init: SearchQuery = {
    page: 0,
    limit: 100,
    sortBy: 'date',
    sortDir: 'desc'
}

const initSort: DataTableSortStatus<CalendarEvent> = {
    direction: 'desc',
    columnAccessor: 'date'
};

const tableKey = 'CalendarEvent-tableKey';

const pickDeviceProperty = (record: any, property: string) => {
    return (record.devices.length > 0) ? record.devices[0][property] : undefined;
}

const columns: DataTableColumn[] = [
    {
        accessor: '_id',
        title: '_id',
        defaultToggle: false,
        toggleable: true
    },

    {
        accessor: 'unit',
        title: '#',
        render: r => <Cell orOptional={r.unit}/>,
        toggleable: true,
        defaultToggle: true,
        sortable: true,
        draggable: true
    },

    {
        accessor: 'device',
        title: 'Nazwa urządzenia',
        render: r => <Cell orOptional={pickDeviceProperty(r as any, 'name')}/>,
        toggleable: true,
        defaultToggle: true,
        sortable: false,
        draggable: true
    },

    {
        accessor: 'device_location',
        title: 'Lokalizacja urządzenia',
        render: r => <Cell orOptional={pickDeviceProperty(r as any, 'location')}/>,
        toggleable: true,
        defaultToggle: true,
        sortable: false,
        draggable: true
    },

    {
        accessor: 'device_client_visibleName',
        title: 'Klient',
        render: r => <Cell orOptional={pickDeviceProperty(r as any, 'client')?.visibleName}/>,
        toggleable: true,
        defaultToggle: true,
        sortable: false,
        draggable: true
    },

    {
        accessor: 'device_client_email',
        title: 'Klient',
        render: r => <Cell orOptional={pickDeviceProperty(r as any, 'client')?.emailAddress}/>,
        toggleable: true,
        defaultToggle: true,
        sortable: false,
        draggable: true
    },

    {
        accessor: 'date',
        title: 'Data',
        render: r => <DateView date={(r.date as [number, number, number])}/>,
        toggleable: true,
        defaultToggle: true,
        sortable: true,
        draggable: true
    },

    {
        accessor: 'comment',
        title: 'Komentarz',
        render: r => <Cell orOptional={r.comment}/>,
        toggleable: true,
        defaultToggle: true,
        sortable: true,
        draggable: true
    }
]

function CalendarEventPage() {
    const [searchQuery, setSearchQuery] = useState<SearchQuery>(init);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<Page<CalendarEvent>>();
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<CalendarEvent>>(initSort);
    const [selectedRecords, setSelectedRecords] = useState<CalendarEvent[]>([]);
    const { effectiveColumns } = useDataTableColumns({key: tableKey, columns})
    const [isCreateDialogOpen, createDialog] = useDisclosure();

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const onRowContextMenu = () => {

    }

    const reloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const reload = () => {
        startLoading();

        const sorted: SearchQuery = {
            ...searchQuery,
            sortBy: sortStatus.columnAccessor,
            sortDir: sortStatus.direction
        }

        CalendarEventApi.search(sorted)
            .then(setPage)
            .catch(reloadError)
            .finally(stopLoading);
    }

    const createNew = () => {
        createDialog.open();
    }

    const doDeleteSelectedError = () => {
        showNotification({
            title: 'Nie udało się usunąć zaznaczonych elementów',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doDeleteSelectedSuccess = () => {
        showNotification({
            title: 'Usunięto',
            message: 'Pomyślnie usunięto zaznaczone wydarzenia.',
            color: 'green',
            icon: <IconCheck/>
        })
    }

    const doDeleteSelected = () => {
        const action: Action = {
            action: 'delete',
            selectedElements: selectedRecords.map(record => record.id)
        }

        startLoading();

        CalendarEventApi.performAction(action)
            .then(doDeleteSelectedSuccess)
            .then(reload)
            .catch(doDeleteSelectedError);
    }

    const deleteSelected = () => {
        modals.openConfirmModal({
            title: "Usuwanie zaznaczonych",
            children: `Czy na pewno chcesz usunąć ${selectedRecords.length} wydarzeń?`,
            labels: { confirm: `Usuń ${selectedRecords.length} wydarzeń`, cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: doDeleteSelected
        });
    }

    const filterResults = () => {
        throw new Error('FilterResults is not implemented.');
    }

    const setLimit = (l: number) => setSearchQuery({...searchQuery, limit: l});

    const setPageNumber = (pn: number) => setSearchQuery({...searchQuery, page: pn});

    useEffect(reload, [searchQuery, sortStatus]);

    return (
        <>
            <PageHeader
                title="Kalendarz"
                url="/calendar/event"
                action={<CalendarEventMenu filterResults={filterResults} createEvent={createNew} deleteSelected={deleteSelected}/> }
            />

            <PageContent>
                <DataTable
                    height="60vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    storeColumnsKey={tableKey}
                    minHeight="150px"
                    fetching={loading}
                    highlightOnHover
                    columns={effectiveColumns}
                    style={{marginBottom: '50px'}}
                    backgroundColor={'transparent'}
                    noRecordsText={"Pusto..."}
                    loaderBackgroundBlur={1}
                    records={page?.content ?? []}
                    selectedRecords={selectedRecords}
                    onSelectedRecordsChange={setSelectedRecords}
                    onRowContextMenu={onRowContextMenu}
                    sortStatus={sortStatus}
                    onSortStatusChange={setSortStatus}
                />

                <PaginationFooter
                    setLimit={setLimit}
                    setPageNumber={setPageNumber}
                    pageNumber={searchQuery.page}
                    limit={searchQuery.limit}
                    page={page}
                />
            </PageContent>

            <CreateCalendarEventDialog
                isOpen={isCreateDialogOpen}
                justClose={createDialog.close}
                closeAndUpdate={() => { reload(); createDialog.close(); }}
            />
        </>
    );
}

export default CalendarEventPage;

// TODO: CalendarEventPage
//  - filter results functionality is missing (throws not implemented).
//  - rows will not be highlighted (I am using Cell orOptional)