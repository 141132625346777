import CardWrapper from "../../CardWrapper";
import {CompositeReport, CompositeReportApi} from "../../../../../../api/composite/report/CompositeReportApi";
import {useEffect, useState} from "react";
import CardPlaceholder from "../../CardPlaceholder";
import CardFooterButton from "../../CardFooterButton";
import {IconCheck, IconNote, IconTrash, IconX} from "@tabler/icons-react";
import CardItem from "../../CardItem";
import {ActionIcon} from "@mantine/core";
import {showNotification} from "@mantine/notifications";

/**
 * Max comments to show on card.
 * @author Kacper Faber
 */
const limit: number = 5;

export type CommentsPreviewCardAttrs = {
    compositeReport?: CompositeReport;
    reload: () => void;
}

function CommentsPreviewCard(attrs: CommentsPreviewCardAttrs) {
    const [comments, setComments] = useState<string[]>();
    const [commentsMore, setCommentsMore] = useState<number>(0);

    /**
     * @author Kacper Faber
     * @returns Count of comments that they're above limit.
     */
    const getMoreCommentsCount = (c: string[]) => {
        if (c.length > 5) return c.length - limit;
        return 0;
    }

    const reload = () => {
        const cr = attrs.compositeReport;
        if (!cr) return;
        setComments(cr.comments);
        setCommentsMore(getMoreCommentsCount(cr.comments));
    }

    const hasComments = () => (comments !== undefined && comments?.length > 0);

    const hasNoComments = () => !(hasComments())

    const doDeleteSuccess = () => {
        showNotification({
            title: 'Usunięto!',
            message: 'Komentarz został usunięty',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doDeleteError = () => {
        showNotification({
            title: 'Nie udało się usunąć komentarza',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doDelete = (text: string) => {
        CompositeReportApi.deleteCommentText({comment: text, compositeReportId: attrs.compositeReport?.id!!})
            .then(doDeleteSuccess)
            .then(reload)
            .then(attrs.reload)
            .catch(doDeleteError)
            .finally();
    }

    useEffect(reload, [attrs.compositeReport, attrs.compositeReport?.comments]);

    return (
        <CardWrapper loading={attrs.compositeReport === undefined} title={`Komentarze ${comments?.length === undefined ? '' : `(${comments?.length})`}`}>
            { /* This component defines own visible system. */ }
            <CardPlaceholder
                text="Brak komentarzy..."
                icon={<IconNote size={64} color="grey"/>}
                visible={hasNoComments()}
            />

            { comments?.slice(0, limit).map(comm => <CardItem compact title={comm} description={<ActionIcon onClick={() => doDelete(comm)} variant="transparent"><IconTrash color="red"/></ActionIcon>} onClick={() => {}}/>) }

            <CardFooterButton
                onClick={() => {}}
                text={commentsMore <= 0 ? "Zobacz wszystkie" : `Zobacz wszystkie (+${commentsMore})`}
                visible={hasComments()}
            />
        </CardWrapper>
    )
}

export default CommentsPreviewCard;

// TODO: Work with CommentsPreviewCard
//  - I want to get more compact items.
//  - Show more button does not work.