/* eslint-disable */
import {rem} from "@mantine/core";
import {Spotlight, SpotlightActionData, SpotlightActionGroupData} from "@mantine/spotlight";
import {useEffect, useState} from "react";
import {IconSearch} from "@tabler/icons-react";

export type CompositeReportWorkActionDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;

    doAddComment: (comment: string) => void;
    doApprove: () => void;
}

type SpotlightData = (SpotlightActionGroupData | SpotlightActionData)[];

function CompositeReportWorkActionDialog(attrs: CompositeReportWorkActionDialogAttrs) {
    const actionsInit: SpotlightData = [
        {
            group: 'Zarządzenie',
            title: 'Zarządzenie',
            actions: [
                {
                    id: 'approve-and-go-next',
                    title: 'Zatwierdź',
                    label: 'Zatwierdź',
                    description: "Zatwierdź ten raport i przejdź do następnego",
                    onClick: attrs.doApprove
                },

                {
                    id: 'delete',
                    label: 'Usuń',
                    description: 'Usuń ten raport i przejdź do następnego'
                }
            ]
        }
    ];

    const [actions, setActions] = useState<SpotlightData>([]);
    const [extraActions, setExtraActions] = useState<SpotlightData>([]);


    const init = () => {
        setActions(actionsInit);
    }

    const onQueryChange = (q: string) => {
        if (q.startsWith('/') && q.length > 3) {
            setExtraActions([
                {
                    id: 'extra-comment',
                    label: q.padStart(1),
                    description: 'Dodaj własny komentarz',
                    onClick: () => attrs.doAddComment(q.slice(1))
                }
            ]);
        }

        else {
            setExtraActions([]);
        }
    }

    useEffect(init, []);

    return (
        <Spotlight
            shortcut={'alt + /'}
            onQueryChange={onQueryChange}
            actions={[...actions, ...extraActions]}
            nothingFound="Nie znaleziono akcji..."
            highlightQuery
            searchProps={{
                leftSection: <IconSearch style={{width: rem(20), height: rem(20)}} stroke={1.5}/>,
                placeholder: 'Wyszukaj...',
            }}
        />
    );
}

export default CompositeReportWorkActionDialog;

// TODO: CompositeReportWorkActionDialog
//  - Does not work with <ExcelView/>
//   - This stupid ReactGrid does not allow to lose focus on cell. Currently we must to press some button to lose focus.