import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {DeviceReport, DeviceReportApi} from "../../../api/device/report/DeviceReportApi";
import {showNotification} from "@mantine/notifications";
import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {DataTable, DataTableColumn, DataTableSortStatus} from "mantine-datatable";
import Cell from "../../../components/table/Cell";

const columns: DataTableColumn[] = [
    {
        accessor: '_original_time',
        title: 'Czas (oryginalny)',
        render: (record) => <Cell orOptional={'_original_time' in record ? record['_original_time'] : null} />,
        sortable: true
    },

    {
        accessor: 'Temp1',
        title: 'Temp1',
        render: record => <Cell orOptional={'Temp1' in record ? record['Temp1'] : null} />,
        sortable: true
    },

    {
        accessor: 'Temp2',
        title: 'Temp2',
        render: record => <Cell orOptional={'Temp2' in record ? record['Temp2'] : null} />,
        sortable: true
    }
];

function DeviceReportPreview() {
    const [deviceReport, setDeviceReport] = useState<DeviceReport>();
    const [records, setRecords] = useState<({id?: string} & any)[]>();
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState<DataTableSortStatus>({columnAccessor: '_original_time', direction: 'desc'});
    const { deviceReportId } = useParams();

    const startLoading = () => setLoading(true);

    const stopLoading = () => setLoading(false);

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem.',
            color: 'red'
        });
    }

    const setRecordsAndReturn: (deviceReport: DeviceReport) => DeviceReport = (deviceReport: DeviceReport) => {
        setRecords(deviceReport.records);
        return deviceReport;
    }

    const reload = () => {
        if (deviceReportId === undefined) {
            return;
        }

        startLoading();

        DeviceReportApi.getReport(deviceReportId)
            .then(setRecordsAndReturn)
            .then(setDeviceReport)
            .catch(onReloadError)
            .finally(stopLoading)
    }

    const doSort = (sortStatus: DataTableSortStatus) => {
        const sortedRecords = records?.sort((a, b) => {
            const columnAccessor = sortStatus.columnAccessor === '_original_time' ? '_time' : sort?.columnAccessor;

            const aCol = a[columnAccessor ?? '_time'];
            const bCol = b[columnAccessor ?? '_time'];

            const compare = (argA: any, argB: any) => {
                if (argA > argB) return 1;
                else if (argA === argB) return 0;
                else return -1;
            }

            const compareValue = compare(aCol, bCol);

            if (sortStatus.direction === 'desc') {
                return compareValue;
            }
            else if (sortStatus.direction === 'asc') {
                return compareValue * -1;
            }
            return 0;
        });

        setRecords(sortedRecords);
        setSort(sortStatus);
    }

    useEffect(reload, [deviceReportId]);

    const formatDate = (date?: [number, number, number]) => {
        if (!date) return undefined;
        const day = date[2] < 10 ? `0${date[2]}` : date[2];
        const month = date[1] < 10 ? `0${date[1]}` : date[1];
        return `${day}.${month}.${date[0]}r.`;
    }

    return (
        <>
            <PageHeader
                title="Podgląd raportu z urządzenia"
                pageTitle={`Odczyt z dnia ${formatDate(deviceReport?.date) ?? '...'}`}
            />

            <PageContent>
                <DataTable
                    striped
                    highlightOnHover
                    backgroundColor="transparent"
                    minHeight={150}
                    columns={columns}
                    records={records}
                    loaderBackgroundBlur={1}
                    fetching={loading}
                    sortStatus={sort}
                    onSortStatusChange={doSort}
                    noRecordsText="Brak elementów..."
                />
            </PageContent>
        </>
    );
}

export default DeviceReportPreview;
