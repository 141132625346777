function hours() {
    const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    return arr.map(num => {
        if (num < 10) {
            return `0${num}:00`;
        }
        return `${num}:00`;
    });
}

const Hours = hours();

export default Hours;