import PageHeader from "../../../../components/layout/PageHeader";
import PageContent from "../../../../components/layout/PageContent";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    CompositeReport,
    CompositeReportApi,
    DateReport,
    Section
} from "../../../../api/composite/report/CompositeReportApi";
import {showNotification} from "@mantine/notifications";
import {ActionIcon, LoadingOverlay, Menu} from "@mantine/core";
import EditSimpleDataDialog from "./EditSimpleDataDialog";
import {useDisclosure} from "@mantine/hooks";
import DateView from "../../../../components/date/DateView";
import {Device, DeviceApi} from "../../../../api/device/DeviceApi";
import Hours from "../view-details/Hours";

function getValueByKey(obj: any, key: string): any | null {
    return key in obj ? obj[key] : null;
}

function SectionPreview({section, device}: {section: Section, device?: Device}) {
    const formatTemp = (temp: number|null|undefined) => {
        if (temp === undefined || temp === null) return '-';
        const t = temp.toString();
        if (t.length === 1) return `${t}.0`;
        return t;
    }

    const getRecord = (dateReport: DateReport, hour: string) => {
        for (const record of dateReport.records) {
            if (getValueByKey(record, '_original_time') === hour) {
                let color = '';
                const temp1o = getValueByKey(record, 'Temp1') as number | null;
                const temp2o = getValueByKey(record, 'Temp2') as number | null;

                if (device) {
                    if (device.maxAllowableTemp !== null && device.maxAllowableTemp !== undefined) {
                        if (temp1o) {
                            if (temp1o > device.maxAllowableTemp) color = 'red';
                        }

                        if (temp2o) {
                            if (temp2o > device.maxAllowableTemp) color = 'red';
                        }
                    }

                    if (device.minAllowableTemp !== null && device.minAllowableTemp !== undefined) {
                        if (temp1o) {
                            if (temp1o < device.minAllowableTemp) color = 'red';
                        }

                        if (temp2o) {
                            if (temp2o < device.minAllowableTemp) color = 'red';
                        }
                    }
                }

                const temp1 = formatTemp(temp1o);
                const temp2 = formatTemp(temp2o);
                return (<td style={{transition: 'background-color 1s ease-in-out', backgroundColor: color}}>{temp1} , {temp2}</td>)
            }
        }

        return <td>- , -</td>;
    }

    const getTds = (hour: string) => {
        return section.content.map((dateReport) => getRecord(dateReport, hour));
    }

    const getExtraHeaders = () => {
        if (section.content.length < 10) {
            const missing = 10 - section.content.length;
            return [...Array(missing)].map(() => <th style={{color: 'transparent'}}>00-00-0000</th>);
        }

        return (<></>);
    }

    return (
        <table className={"table table-striped"} style={{}}>
            <thead>
                <tr>
                    <th>Godzina</th>
                    { section.content.map(dr => <th key={`DateReport-${dr.id}`}><DateView date={dr.date as unknown as [number, number, number]}/></th>) }
                    { getExtraHeaders() }
                </tr>
            </thead>

            <tbody>
            { Hours.map(hour => <tr key={`Hour-${hour}`}><td>{hour}</td> { getTds(hour) } </tr>) }
            </tbody>
        </table>
    );
}

// function DateReportPreview({dateReport}: {dateReport: DateReport}) {
//     return (
//         <table>
//
//         </table>
//     )
// }

export type CompositeReportDetailsMenuAttrs = {
    editSimpleData: () => void;
}

function CompositeReportDetailsMenu(attrs: CompositeReportDetailsMenuAttrs) {
    return (
        <Menu>
            <Menu.Target>
                <ActionIcon>
                    <i className="fa fa-filter"></i>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Edycja</Menu.Label>
                <Menu.Item onClick={attrs.editSimpleData}>Edytuj tytuł i opis</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}

function CompositeReportDetails() {
    const [device, setDevice] = useState<Device>();
    const [loading, setLoading] = useState(false);
    const startLoading = () => { setLoading(true); };
    const stopLoading = () => { setLoading(false); };
    const [compositeReport, setCompositeReport] = useState<CompositeReport>();
    const { compositeReportId } = useParams();
    const [isEditDialogOpen, editDialog] = useDisclosure();

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const tryFetchDevice = () => {
        if (compositeReport?.unit) {
            DeviceApi.search({q: compositeReport?.unit ?? '<brak jednostki>', limit: 50, page: 0})
                .then(data => data.content?.find(x => x.unit === compositeReport?.unit))
                .then(setDevice);
        }
    }

    useEffect(tryFetchDevice, [compositeReport]);

    const reload = () => {
        startLoading();

        CompositeReportApi.details(compositeReportId as string)
            .then(setCompositeReport)
            .catch(onReloadError)
            .finally(stopLoading)
    }

    const editSimpleData = () => {
        editDialog.open();
    }

    useEffect(reload, [compositeReportId]);

    return (
        <>
            <PageHeader
                title={`Szczegóły raportu: ${compositeReport?.title}`}
                url="/composite/report/details"
                action={<CompositeReportDetailsMenu editSimpleData={editSimpleData}/> }
            />

            <PageContent>
                { compositeReport?.sections?.map(section => <SectionPreview device={device} section={section}/>) }
            </PageContent>

            <LoadingOverlay
                visible={loading}
            />

            <EditSimpleDataDialog
                compositeReport={compositeReport}
                isOpen={isEditDialogOpen}
                justClose={editDialog.close}
                closeAndUpdate={() => { editDialog.close(); reload(); }}
            />
        </>
    );
}

export default CompositeReportDetails;