// noinspection JSUnusedGlobalSymbols

const TOKEN_KEY = 'accessToken';

export class SessionStorage {

    static getToken(): string | null {
        return window.sessionStorage.getItem(TOKEN_KEY)
    }

    static setToken(token: string | null) {
        if (token === null) {
            window.sessionStorage.removeItem(TOKEN_KEY);
            return;
        }

        window.sessionStorage.setItem(TOKEN_KEY, token);
    }
}