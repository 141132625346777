import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {AspectRatio, Card, FileInput, Flex, Image, SimpleGrid} from "@mantine/core";
import {PdfConfigApi} from "../../../api/pdf/config/PdfConfigApi";
import {showNotification} from "@mantine/notifications";
import React, {useEffect, useState} from "react";

function PdfConfigPage() {
    const [imageHash, setImageHash] = useState<number>();

    const uploadSuccess = () => {
        showNotification({
            title: 'Wysłano pomyślnie',
            message: 'Nowa pieczątka będzie widoczna na wszystkich nowych raportach',
            color: 'green'
        });
    }

    const reload = () => {
        setImageHash(Date.now());
    }

    useEffect(reload, []);

    return (
        <>
            <PageHeader title="Ustawienia PDF" url="/pdf/config"/>

            <PageContent>
                <SimpleGrid spacing={{ base: 3 }} cols={{ base: 3, sm: 2, xs: 1}}>
                    <Card p='md' radius='md'>

                        <Flex justify="left" direction="column" p='xl' gap={'md'}>
                            <Image
                                w={'20vw'}
                                h={'20vh'}
                                src={PdfConfigApi.getFooterRightUrl() + `#${imageHash}`}
                            />
                            <AspectRatio mx="auto">

                            </AspectRatio>

                            <FileInput
                                clearable
                                accept=".jpeg"
                                placeholder="Wybierz plik"
                                onChange={(payload: File | null) => {
                                    PdfConfigApi.uploadFooter(payload).then(reload).then(uploadSuccess);
                                }}
                            />
                        </Flex>
                    </Card>
                </SimpleGrid>
            </PageContent>
        </>
    );
}

// TODO:
//  We can't see current footer.

export default PdfConfigPage;
