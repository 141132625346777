/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Modal, Stack, TextInput} from "@mantine/core";
import {Client, ClientApi, EditQuery} from "../../api/client/ClientApi";
import {useForm} from "@mantine/form";
import {useEffect, useState} from "react";
import {showNotification} from "@mantine/notifications";

export type ClientEditDrawerAttrs = {
    isOpen: boolean;
    justClose?: () => void;
    onClose: () => void;
    clientId?: string;
}

function ClientEditModal(attrs: ClientEditDrawerAttrs) {
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState<Client>();

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { id: '', visibleName: '', emailAddress: '', phoneNumber: '' },

        validate: {
            visibleName: (e) => e.length < 2 ? 'Przynajmniej dwa znaki.' : null,
        }
    });

    const loadClientData = () => {
        form.setValues({
            visibleName: client?.visibleName,
            emailAddress: client?.emailAddress,
            phoneNumber: client?.phoneNumber
        });
    }

    useEffect(loadClientData, [client]);

    const reload = () => {
        if (!attrs.clientId) {
            setClient(undefined);
            return;
        }

        ClientApi.getById(attrs.clientId)
            .then(setClient)
            .catch(onReloadError);
    }

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        })
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać',
            message: 'Nie udało się zapisać danych klienta',
            color: 'red'
        });

        form.setFieldError('visibleName', 'Nie udało się zapisać...');
    }

    const doSubmit = (values: any) => {
        setLoading(true);

        const id = attrs.clientId!!;
        const emailAddress = values.emailAddress === '' ? undefined : values.emailAddress;
        const phoneNumber = values.phoneNumber === '' ? undefined : values.phoneNumber;

        ClientApi.edit({visibleName: values.visibleName, emailAddress, phoneNumber, id} satisfies EditQuery)
            .then(attrs.onClose)
            .catch(doSubmitError)
            .finally(() => setLoading(false));
    }

    useEffect(reload, [attrs.clientId]);

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose ?? attrs.onClose} title="Edycja klienta">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Nazwa klienta"
                        placeholder="Nazwa klienta..."
                        variant="filled"
                        key={form.key('visibleName')}
                        { ...form.getInputProps('visibleName') }
                    />

                    <TextInput
                        label="Adres E-mail"
                        placeholder="Adres E-mail..."
                        variant="filled"
                        key={form.key('emailAddress')}
                        { ...form.getInputProps('emailAddress') }
                    />

                    <TextInput
                        label="Numer telefonu"
                        placeholder="Numer telefonu..."
                        variant="filled"
                        key={form.key('phoneNumber')}
                        { ...form.getInputProps('phoneNumber') }
                    />

                    <Button type="submit" loading={loading}>
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
}

export default ClientEditModal;
