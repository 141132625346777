import {HttpClient} from "../_http/HttpClient";

export type AuthenticateQuery = {
    username: string;
    password: string;
}

export type AuthenticateResult = {
    accessToken: string;
}

class AuthenticationApi {
    static async authenticate(query: AuthenticateQuery) {
        const url = `https://api.raporty.termmed.pl/authenticate`;
        return HttpClient.post<AuthenticateResult>(url, {}, { ...query });
    }
}

export default AuthenticationApi;
