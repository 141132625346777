import {CellChange, Column, ReactGrid, Row} from "@silevis/reactgrid";
import {useEffect, useState} from "react";
import {
    AddOrEditRecordItem,
    CompositeReport,
    CompositeReportApi,
    DateReport
} from "../../../../api/composite/report/CompositeReportApi";
import ExcelViewHelper from "./ExcelViewHelper";
import {showNotification} from "@mantine/notifications";
import {IconArrowBack, IconCheck, IconX} from "@tabler/icons-react";
import {ActionIcon, ScrollArea} from "@mantine/core";
import {Device} from "../../../../api/device/DeviceApi";
import {useViewportSize} from "@mantine/hooks";

export type ExcelViewAttrs = {
    compositeReport?: CompositeReport;
    device?: Device;
    reload: () => void;
    setLoading?: (b: boolean) => void;
    loading?: boolean;
    disableEditing?: boolean;
}

function ExcelView(attrs: ExcelViewAttrs) {
    const [columns, setColumns] = useState<Column[]>([]);
    const [rows, setRows] = useState<Row[]>([]);

    /**
     * Holds history of CompositeReport.
     * We can undo.
     * @author Kacper Faber
     * @since 0.1.92
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [history, _setHistory] = useState<CompositeReport[]>([]);

    const [startLoading, stopLoading] = [
        () => console.warn('Funkcja ładowania w komponencie <ExcelView/> nie jest obsługiwana.'),
        () => console.warn('Funkcja ładowania w komponencie <ExcelView/> nie jest obsługiwana.')
    ];

    const onEditSuccess = () => {
        showNotification({
            title: 'Zapisano zmiany',
            message: 'Wprowadzone zmiany zostały zapisane',
            color: 'green',
            icon: <IconCheck/>
        });

        console.log(
            JSON.stringify(history)
        )
    }

    const onEditError = () => {
        showNotification({
            title: 'Nie udało się zapisać zmian',
            message: 'Upewnij się, że wprowadzone dane są poprawne',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doEditMultiple = (cellChanges: CellChange[]) => {
        if (attrs.compositeReport) {
            history.push(attrs.compositeReport);
        }

        const items = cellChanges.map(cellChange => {
            const [temp1, temp2] = ((cellChange.newCell as any).text as string).split(",").map(x => parseFloat(x));
            const dateReportId = cellChange.rowId as string;

            return {
                dateReportId: dateReportId,
                hour: cellChange.columnId as string,
                temp1: temp1,
                temp2: temp2
            } as AddOrEditRecordItem;
        });

        startLoading();

        CompositeReportApi.addOrEditRecords({ compositeReportId: attrs.compositeReport?.id!!, items})
            .then(onEditSuccess)
            .then(attrs.reload)
            .catch(onEditError)
            .finally(stopLoading);
    }

    const onCellsChanged = (cellChanges: CellChange[]) => {
        if (attrs.disableEditing) {
            showNotification({
                title: 'Nie można edytować w trybie sugestii',
                message: 'Nie można edytować sugestii',
                color: 'yellow',
                icon: <IconX/>
            });

            return;
        }

        doEditMultiple(cellChanges);
    }

    const reload = () => {
        setColumns(ExcelViewHelper.getColumns());

        if (attrs.compositeReport) {
            setRows([
                ...ExcelViewHelper.getHeaderRows(),
                ...ExcelViewHelper.getRows(attrs.compositeReport, attrs.device)
            ]);
        }
    }

    useEffect(reload, [attrs.compositeReport, attrs.device]);

    const undo = () => {
        const toRestore = history.pop();

        if (!toRestore)  {
            return;
        }

        const dateReports = toRestore.sections?.flatMap( x => x.content) ?? [];
        const recordsMapped: {record: any, dateReport: DateReport}[] = dateReports.flatMap(x => x.records.map(y => ({ dateReport: x, record: y })));

        const items = recordsMapped.map(x => {
            return {
                dateReportId: x.dateReport.id,
                hour: x.record['_original_time'] as string,
                temp1: x.record['Temp1'],
                temp2: x.record['Temp2']
            } as AddOrEditRecordItem;
        });

        startLoading();

        CompositeReportApi.addOrEditRecords({ compositeReportId: attrs.compositeReport?.id!!, items})
            .then(onEditSuccess)
            .then(attrs.reload)
            .catch(onEditError)
            .finally(stopLoading);
    }

    const viewport = useViewportSize();

    if (!attrs.compositeReport) {
        return null;
    }

    return (
        <>
            <ActionIcon
                variant="light"
                children={ <IconArrowBack size={50}/> }
                onClick={undo}
                disabled={history.length === 0}
            />

            <ScrollArea pb="md" h={viewport.height * 0.8}>
                <ReactGrid
                    enableRangeSelection
                    enableFillHandle
                    stickyLeftColumns={1}
                    stickyTopRows={1}
                    enableFullWidthHeader={false}
                    columns={columns}
                    rows={rows}
                    onCellsChanged={onCellsChanged}
                />
            </ScrollArea>
        </>
    );
}

export default ExcelView;

// TODO: ExcelView
//  2. Make some style improvements.
//  3. Validate values to edit - must contain comma, optional two doubles: [t1?],[t2?]