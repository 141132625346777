import React, {useEffect, useState} from "react";
import {Client, ClientApi, SearchQuery} from "../../api/client/ClientApi";
import Page from "../../models/api/Page";
import {useDisclosure, useSessionStorage} from "@mantine/hooks";
import CreateClientDialog, {CreatedClientData} from "./CreateClientDialog";
import {ActionIcon, Button, Flex, Menu} from "@mantine/core";
import {notifications, showNotification} from "@mantine/notifications";
import {DataTable, DataTableColumn, DataTableSortStatus, useDataTableColumns} from "mantine-datatable";
import PaginationFooter from "../../components/pagination/PaginationFooter";
import PageContent from "../../components/layout/PageContent";
import PageHeader from "../../components/layout/PageHeader";
import ClientFilterDialog from "./ClientFilterDialog";
import {modals} from "@mantine/modals";
import ClientEditModal from "./ClientEditModal";
import {useContextMenu} from "mantine-contextmenu";
import {IconDevices, IconEdit, IconEye, IconSend, IconTrash} from "@tabler/icons-react";
import Cell from "../../components/table/Cell";
import BrowseClientDevicesDialog from "./BrowseClientDevicesDialog";
import {useNavigate} from "react-router-dom";


const columns: DataTableColumn[] = [
    {
        title: '#',
        accessor: 'id',
        toggleable: true,
        defaultToggle: false,
        sortable: true
    },

    {
        title: 'Nazwa',
        accessor: 'visibleName',
        toggleable: false,
        render: (record) => <Cell orOptional={record.visibleName} />,
        sortable: true
    },

    {
        title: 'Adres E-mail',
        accessor: 'emailAddress',
        toggleable: true,
        render: (record) => <Cell orOptional={record.emailAddress} />,
        sortable: true
    },

    {
        title: 'Numer telefonu',
        accessor: 'phoneNumber',
        toggleable: true,
        render: (record) => <Cell orOptional={record.phoneNumber} />,
        sortable: true
    }
];

type ClientPageMenuAttrs = {
    deleteSelected: () => void;
    newClient: () => void;
}

function ClientPageMenu(attrs: ClientPageMenuAttrs) {
    return (
        <Menu>
            <Menu.Target>
                <ActionIcon>
                    <i className="fa fa-filter"></i>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Zarządzanie</Menu.Label>
                <Menu.Item onClick={attrs.newClient}>Nowy klient</Menu.Item>

                <Menu.Label>Zaznaczenie wielokrotne</Menu.Label>
                <Menu.Item onClick={attrs.deleteSelected} color="red">Usuń zaznaczone</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
function ClientPage() {
    const { showContextMenu } = useContextMenu();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);
    /* Paginated data from API */
    const [page, setPage] = useState<Page<Client>>();
    const [createDialogOpen, createDialog] = useDisclosure(false);
    const [selectedRecords, setSelectedRecords] = useState<Client[]>([]);
    const [isFilterDialogOpen, filterDialog] = useDisclosure();
    const [q, setQ] = useState<string | undefined>(undefined);
    const [fetching, setFetching] = useState(false);
    const [editClientId, setEditClientId] = useState<string>();
    const [isEditClientOpen, editClientDrawer] = useDisclosure();
    const [isBrowseClientDevicesDialogOpen, browseClientDevicesDialog] = useDisclosure();
    const [browseClientDevicesSelectedClient, setBrowseClientDevicesSelectedClient] = useState<Client>();
    const [sortStatus, setSortStatus] = useSessionStorage<DataTableSortStatus<Client>>({
        key: 'Client_SendStatus',
        defaultValue: {
            columnAccessor: 'visibleName',
            direction: 'asc'
        }
    });
    const nav = useNavigate();
    const key = "Client-table-key";

    const { effectiveColumns } = useDataTableColumns({
        key, columns
    });

    const reloadError = () => notifications.show({
        title: 'Wystąpił błąd...',
        message: 'Wystąpił błąd podczas ładowania, jeśli uważasz, że to pomyłka proszę skontaktować się z administratorem.',
        color: 'red'
    });

    const reload = () => {
        setFetching(true);

        const query: SearchQuery = {
            page: pageNumber ?? 0,
            limit: limit ?? 10,
            q: q ? q : '',
            sortBy: sortStatus.columnAccessor,
            sortDir: sortStatus.direction
        }

        ClientApi.search(query)
            .then(page => setPage(page))
            .catch(reloadError)
            .finally(() => setFetching(false));
    }

    useEffect(reload, [limit, pageNumber, q, sortStatus]);

    const onClientCreated = (_: CreatedClientData) => {
        createDialog.close();
        reload();
    }

    const onDeleteSelectedSuccess = () => {
        showNotification({
            title: 'Usunięto zaznaczonych klientów',
            message: 'Pomyślnie usunięto zaznaczonych klientów',
            color: 'green'
        });
    }

    const onDeleteSelectedError = () => {
        showNotification({
            title: 'Nie udało się usunąć klientów',
            message: 'Jeśli uważasz, że to błąd proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const resetSelectedRecords = () => {
       setSelectedRecords([]);
    }

    const stopFetching = () => { setFetching(false); };

    const doDeleteSelected = () => {
        const selectedElements = selectedRecords.map(r => r.id);

        setFetching(true);

        ClientApi.action({action: 'delete', selectedElements})
            .then(onDeleteSelectedSuccess)
            .then(reload)
            .then(resetSelectedRecords)
            .catch(onDeleteSelectedError)
            .finally(stopFetching);
    }

    const deleteSelected = () => {
        modals.openConfirmModal({
            title: "Usuwanie zaznaczonych",
            children: `Czy na pewno chcesz usunąć ${selectedRecords.length} klientów?`,
            labels: { confirm: `Usuń ${selectedRecords.length} klientów`, cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: doDeleteSelected
        });
    }

    const deleteClient = (client: Client) => {
        modals.openConfirmModal({
            title: "Usuń klienta",
            children: `Czy na pewno chcesz usunąć klienta ${client.visibleName}?`,
            labels: { confirm: `Tak, usuń`, cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: () => doDeleteClient(client)
        });
    }

    const doDeleteClientSuccess = () => {
        showNotification({
            title: 'Usunięto klienta!',
            message: 'Usunięto wybranego klienta.',
            color: 'green'
        });
    }

    const doDeleteClientFailed = () => {
        showNotification({
            title: 'Nie udało się usunąć wybranego klienta',
            message: 'Jeśli uważasz, że to błąd proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doDeleteClient = (client: Client) => {
        ClientApi.action({action: 'delete', selectedElements: [client.id]})
            .then(doDeleteClientSuccess)
            .then(reload)
            .catch(doDeleteClientFailed);
    }

    const doBrowseClientDevices = (client: Client) => {
        setBrowseClientDevicesSelectedClient(client);
        browseClientDevicesDialog.open();
    }

    const onRowContextMenu = (data: {record: Client, event: any}) => {
        showContextMenu([
            {
                key: 'client-go-to-summary',
                title: 'Wyświetl',
                icon: <IconEye size={16}/>,
                onClick: () => nav(`/client/view/${data.record.id}`)
            },

            {
                key: 'go-to-client-device-menu-item',
                title: 'Przejdź do urządzeń',
                icon: <IconDevices size={16} />,
                onClick: () => doBrowseClientDevices(data.record)
            },

            {
                key: 'edit-client-menu-item',
                title: 'Edytuj dane klienta',
                icon: <IconEdit size={16} />,
                onClick: () => editClient(data.record.id)
            },

            {
                key: 'send-simple-mail-to-client-ctx-menu-item',
                title: 'Wyślij E-mail',
                icon: <IconSend size={16}/>,
                disabled: !data.record.emailAddress,
                onClick: () => { window.location.href = 'mailto:' + data.record.emailAddress }
            },

            { key: 'divider' },

            {
                key: 'delete-client-menu-item',
                title: 'Usuń klienta',
                color: 'red',
                icon: <IconTrash size={16} />,
                onClick: () => deleteClient(data.record)
            },

            {
                key: 'delete-selected-clients-menu-item',
                icon: <IconTrash size={16} />,
                color: 'red',
                title: 'Usuń zaznaczonych',
                onClick: deleteSelected
            }
        ])(data.event);
    }

    const editClient = (clientId: string) => {
        setEditClientId(clientId);
        editClientDrawer.open();
    }

    return (
        <>
            <PageHeader
                title={"Klienci: "}
                action={<ClientPageMenu deleteSelected={deleteSelected} newClient={createDialog.open} />}
                url="/client"
            />

            <PageContent>
                <Flex gap={'sm'} mb={'sm'}>
                    <Button onClick={filterDialog.open}>
                        Filtrowanie
                    </Button>
                </Flex>

                <DataTable
                    height="60vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    storeColumnsKey={key}
                    minHeight="150px"
                    fetching={fetching}
                    highlightOnHover
                    columns={effectiveColumns}
                    style={{marginBottom: '50px'}}
                    backgroundColor={'transparent'}
                    noRecordsText={"Pusto..."}
                    loaderBackgroundBlur={1}
                    records={page?.content}
                    selectedRecords={selectedRecords}
                    onSelectedRecordsChange={setSelectedRecords}
                    onRowContextMenu={onRowContextMenu}
                    sortStatus={sortStatus}
                    onSortStatusChange={setSortStatus}
                />

                <PaginationFooter
                    page={page}
                    setLimit={setLimit}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    limit={limit}
                />

                <CreateClientDialog
                    opened={createDialogOpen}
                    open={createDialog.open}
                    close={createDialog.close}
                    onClientCreated={onClientCreated}
                />
            </PageContent>

            <ClientFilterDialog
                isOpen={isFilterDialogOpen}
                setQ={setQ}
                q={q}
                onClose={filterDialog.close}
            />

            <ClientEditModal
                isOpen={isEditClientOpen}
                onClose={() => { editClientDrawer.close(); reload(); }}
                clientId={editClientId}
            />

            <BrowseClientDevicesDialog
                isOpen={isBrowseClientDevicesDialogOpen}
                justClose={browseClientDevicesDialog.close}
                client={browseClientDevicesSelectedClient}
            />
        </>
    );
}

export default ClientPage;
