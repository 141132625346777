import {Api} from "../Api";
import {HttpClient} from "../_http/HttpClient";

export type Stats = {
    totalUsers: number,
    totalCompositeReports: number,
    totalDevices: number,
    totalClients: number,
    totalDeviceReports: number,
    totalKnownFiles: number
}

export class StatsApi {
    static getStats() {
        const url = Api.getUrl('/stat');
        return HttpClient.get<Stats>(url, {});
    }
}
