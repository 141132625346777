import {omitBy } from "lodash";
import {SessionStorage} from "../SessionStorage";

export class HttpClient {

    static getHeaders(): any {
        const token = SessionStorage.getToken();

        if (token === null) {
            return new Headers({
                'Content-Type': 'application/json'
            });
        }

        return new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        });
    }

    static getHeadersNoContentType(): any {
        const token = SessionStorage.getToken();

        if (token === null) {
            return new Headers({});
        }

        return new Headers({
            'Authorization': `Bearer ${token}`
        });
    }

    static getUrl(url: string, params: any): string {
        const x = { ...omitBy(params, (x) => x === undefined) };
        const searchParams = new URLSearchParams(x);
        return `${url}?${searchParams}`;
    }

    static async get<T>(url: string, params = {}): Promise<T> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const response = await fetch(urlWithParams, { method: 'GET', headers, mode: 'cors', credentials: 'include' });
        return await response.json() satisfies T;
    }

    static async getFromJsonOrNull<T>(url: string, params = {}): Promise<T | null> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const response = await fetch(urlWithParams, { method: 'GET', headers, mode: 'cors', credentials: 'include' });
        if (response.headers.get('Content-Type') === 'application/json') {
            return await response.json() satisfies T
        }
        return null;
    }

    static async post<T>(url: string, params: any = {}, body: any): Promise<T> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const bodyJson = JSON.stringify(body);
        const response = await fetch(urlWithParams, { method: 'POST', headers, body: bodyJson, mode: 'cors', credentials: 'include' })
        return await response.json() satisfies T;
    }

    static async postAndExpectNoContent(url: string, params: any = {}, body: any) {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const bodyJson = JSON.stringify(body);
        const response = await fetch(urlWithParams, { method: 'POST', headers, body: bodyJson, mode: 'cors', credentials: 'include' });
        return new Promise<Response>((resolve, reject) => {
            if (response.status < 200 || response.status > 299) {
                reject(response);
            }

            resolve(response);
        });
    }

    static async put<T>(url: string, params: any = {}, body: any): Promise<T> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const bodyJson = JSON.stringify(body);
        const response = await fetch(urlWithParams, { method: 'PUT', headers, body: bodyJson, mode: 'cors', credentials: 'include'})
        return await response.json() satisfies T;
    }

    static async putAndExpectNoContent(url: string, params: any = {}, body: any): Promise<Response> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const bodyJson = JSON.stringify(body);
        const response = await fetch(urlWithParams, { method: 'PUT', headers, body: bodyJson, mode: 'cors', credentials: 'include'})
        return new Promise<Response>((resolve, reject) => {
            if (response.status < 200 || response.status > 299) {
                reject(response);
            }
            resolve(response);
        });
    }

    static async deleteAndExpectNoContent(url: string, params: any = {}, body: any) {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const bodyJson = JSON.stringify(body);
        const response = await fetch(urlWithParams, { method: 'DELETE', headers, body: bodyJson, mode: 'cors', credentials: 'include' });
        return new Promise<Response>((resolve, reject) => {
            if (response.status < 200 || response.status > 299) {
                reject(response);
            }

            resolve(response);
        });
    }

    static async postFormData(url: string, params: any = {}, formData: FormData) {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeadersNoContentType();
        const response = await fetch(urlWithParams, { method: 'POST', headers, body: formData, mode: 'cors', credentials: 'include'});

        return new Promise<Response>((resolve, reject) => {
            if (response.status < 200 || response.status > 299) {
                reject(response);
            }
            resolve(response);
        });
    }

    static async postFormDataAndGetJson<T>(url: string, params: any = {}, formData: FormData): Promise<T> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeadersNoContentType();
        const response = await fetch(urlWithParams, { method: 'POST', headers, body: formData, mode: 'cors', credentials: 'include'});
        return await response.json() satisfies T;
    }
}
