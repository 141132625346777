import highlight from "../highlight/TextHighlight";

function Cell({orOptional}: {orOptional?: any|null}) {
    return (
        <>
            { orOptional ?? '-' }
        </>
    )
}

/**
 *
 * @param text Text, if !text then -
 * @param searchText Optional text to highlight
 * @constructor
 * @author Kacper Faber
 */
export function CellHighlight({text, searchText}: {text?: string|null|undefined|any, searchText?: any}) {
    if (!text) {
        return (
            <>
                { '-' }
            </>
        );
    }

    if (!searchText) {
        return (
            <>
                { text }
            </>
        )
    }

    return (
        <>
            { highlight({searchText, text: text as unknown as string}) }
        </>
    )
}

export default Cell;
