/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Device, DeviceApi} from "../../../api/device/DeviceApi";
import {showNotification} from "@mantine/notifications";
import {IconCircleX} from "@tabler/icons-react";
import LastReportsCard from "../../../components/device/cards/LastReportsCard";
import {Box, LoadingOverlay, SimpleGrid,} from "@mantine/core";
import ClientCard from "../../../components/device/cards/ClientCard";
import DeviceCard from "../../../components/device/cards/DeviceCard";

function DeviceView() {
    const { deviceId } = useParams();
    const [device, setDevice] = useState<Device>();
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconCircleX size={32}/>
        });
    }

    const reload = () => {
        if (!deviceId) return;

        startLoading();

        DeviceApi.getById(deviceId)
            .then(setDevice)
            .catch(onReloadError)
            .finally(stopLoading);
    }

    useEffect(reload, [deviceId]);

    return (
        <>
            <Box pos="relative" h={'100%'}>
                <LoadingOverlay
                    visible={loading}
                    zIndex={1000}
                    overlayProps={{ radius: 'sm', blur: 5 }}
                />

                <PageHeader
                    title={`Podgląd urządzenia ${device?.name ?? ''}`}
                    pageTitle={`Urządzenie ${device?.name}`}
                />

                <PageContent>
                    <SimpleGrid cols={{ base: 1, sm: 1, md: 2, lg: 3 }} spacing={{ base: 10, sm: 'xl' }} verticalSpacing={{ base: 'sm', sm: 'md' }}>
                        <ClientCard device={device}/>

                        <DeviceCard reload={reload} device={device}/>

                        <LastReportsCard device={device}/>
                    </SimpleGrid>
                </PageContent>
            </Box>
        </>
    )
}


export default DeviceView;
