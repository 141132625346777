import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@mantine/tiptap/styles.css';
import AppRouter from "./AppRouter";
import 'dayjs/locale/pl';
import {DatesProvider} from "@mantine/dates";
import ObjectSupport from "dayjs/plugin/objectSupport";
import dayjs from "dayjs";
import "@silevis/reactgrid/styles.css"; // React Grid
import "@mantine/spotlight/styles.css";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.extend(ObjectSupport);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <DatesProvider settings={{ locale: 'pl', timezone: 'Europe/Warsaw' }}>
          <AppRouter/>
      </DatesProvider>
  </React.StrictMode>
);
