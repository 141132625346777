import {SearchQuery} from "../../../api/composite/report/CompositeReportApi";
import {useState} from "react";
import {Button, Modal, Stack, TextInput} from "@mantine/core";
import {DatePickerInput} from "@mantine/dates";
import "@mantine/dates/styles.css";

export type CompositeReportFilerDialogAttrs = {
    searchQuery: SearchQuery;
    doUpdate: (s: SearchQuery) => void;

    isOpen: boolean;
    onClose: () => void;
}

// noinspection DuplicatedCode
function formatDate(date: Date|null) {
    if (!date) { return undefined}
    const year = date.getFullYear();
    let month: string|number = date.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    let day: string|number = date.getDate();
    if (day < 10) {
        day = '0' + day;
    }
    return `${year}-${month}-${day}`;
}

function CompositeReportFilterDialog(attrs: CompositeReportFilerDialogAttrs) {
    const [searchQuery, setSearchQuery] = useState({ ...attrs.searchQuery });

    const [createdAtRange, setCreatedAtRange] = useState<[Date | null, Date | null]>([null, null]);

    const onSubmit = () => {
        attrs.doUpdate({...searchQuery, createdAtMin: formatDate(createdAtRange[0]), createdAtMax: formatDate(createdAtRange[1])});
        attrs.onClose();
    }

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.onClose} title="Filtrowanie">
            <Stack>
                <TextInput
                    label="Zapytanie"
                    defaultValue={searchQuery.q}
                    onChange={ev => setSearchQuery(e => { e.q = ev.target.value; return e; })}
                    variant="filled"
                    placeholder="Zapytanie..."
                />

                <TextInput
                    label="Jednostka"
                    defaultValue={searchQuery.unit}
                    variant="filled"
                    onChange={ev => setSearchQuery(e => { e.unit = ev.target.value; return e; })}
                    placeholder="Jednostka..."
                />

                <DatePickerInput
                    type="range"
                    label="Wygenerowano"
                    variant="filled"
                    value={createdAtRange}
                    onChange={setCreatedAtRange}
                />


                <Button onClick={onSubmit}>Szukaj...</Button>
            </Stack>
        </Modal>
    );
}
export default CompositeReportFilterDialog;
