import {Card, Flex, Group, Stack, Text} from "@mantine/core";
import {Section} from "../../../../api/composite/report/CompositeReportApi";
import DateReportDetails from "./DateReportDetails";
import Hours from "./Hours";
import {useMediaQuery} from "@mantine/hooks";

export type SectionDetailsBetterAttrs = {
    section: Section;
    isInvalid: (temp1: number | undefined, temp2: number | undefined) => boolean;
}

function HourColumn({useKey}: {useKey: string}) {
    return (
        <Stack>
            <Card.Section withBorder >Data</Card.Section>
            { Hours.map(hour => <Card.Section key={`${useKey}-${hour}`} withBorder >{hour}</Card.Section>) }
        </Stack>
    );
}

function SectionDetails(attrs: SectionDetailsBetterAttrs) {
    const minWidth = useMediaQuery('(min-width: 95em)');

    return (
        <Card withBorder radius="md" p="md">
            <Stack>
                <Card.Section p="md">
                    <Text>
                        Nazwa raportu
                    </Text>
                </Card.Section>

                <Flex>
                    { !minWidth ? null : <HourColumn useKey={attrs.section.id}/>}

                    <Group grow w={'100%'}>
                        { attrs.section.content.map(dateReport => <DateReportDetails key={dateReport.id} isInvalid={attrs.isInvalid} dateReport={dateReport} />) }
                    </Group>
                </Flex>
            </Stack>
        </Card>
    )
}

export default SectionDetails;