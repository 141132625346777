import {Button, Card, Group, Transition} from "@mantine/core";

export type CardFooterButtonAttrs = {
    onClick: () => void;
    text: string;
    visible: boolean;
}

function CardFooterButton(attrs: CardFooterButtonAttrs) {
    return (
        <Transition mounted={attrs.visible}>
            { style =>
                <Card.Section style={style} px="md">
                    <Group justify="center">
                        <Button variant="transparent" onClick={attrs.onClick}>{attrs.text}</Button>
                    </Group>
                </Card.Section>
            }
        </Transition>
    )
}

export default CardFooterButton;