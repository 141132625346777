import {Button, Modal, Stack, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useState} from "react";
import {CommentPresetsApi} from "../../../api/comment/presets/CommentPresetsApi";
import {showNotification} from "@mantine/notifications";

export type CreateCommentPresetDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
}

function CreateCommentPresetDialog(attrs: CreateCommentPresetDialogAttrs) {
    const [loading, setLoading] = useState(false);
    const startLoading = () => { setLoading(true); };
    const stopLoading = () => { setLoading(false); };

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { comment: '' },
        validate: { comment: e => e.length < 2 ? 'Przynajmniej dwa znaki' : null }
    });

    const onSubmitError = () => {
        showNotification({
            title: 'Nie udało się utworzyć komentarza',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSubmit = ({comment}: {comment: string}) => {
        startLoading();

        CommentPresetsApi.addPreset(comment)
            .then(attrs.closeAndUpdate)
            .catch(onSubmitError)
            .finally(stopLoading);
    }

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Nowy komentarz">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Komentarz"
                        placeholder="Komentarz"
                        key={form.key('comment')}
                        { ...form.getInputProps('comment') }
                    />

                    <Button type="submit" loading={loading}>
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    )
}

export default CreateCommentPresetDialog;