import {DataTable, DataTableColumn} from "mantine-datatable";
import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {useEffect, useState} from "react";
import {CommentPresetsApi} from "../../../api/comment/presets/CommentPresetsApi";
import {showNotification} from "@mantine/notifications";
import {useContextMenu} from "mantine-contextmenu";
import {IconPlus, IconTrash} from "@tabler/icons-react";
import {Button} from "@mantine/core";
import CreateCommentPresetDialog from "./CreateCommentPresetDialog";
import {useDisclosure} from "@mantine/hooks";

function CommentPresets() {
    const [presets, setPresets] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [isCreateCommentPresetDialogOpen, createCommentDialog] = useDisclosure();
    const { showContextMenu } = useContextMenu();

    const mapPresets = () => {
        return presets.map(pres => {
            return { comment: pres };
        });
    }

    const startLoading = () => { setLoading(true); };

    const stopLoading = () => { setLoading(false); };

    const columns: DataTableColumn[] = [
        {
            accessor: 'id',
            title: 'Lp.',
            render: (_, index) => <>{index}</>
        },
        {
            accessor: 'comment',
            title: 'Komentarz',
            render: (record) => <>{record.comment}</>
        }
    ];

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const onDeletePresetError = () => {
        showNotification({
            title: 'Nie udało się usunąć komentarza',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const onDeletePresetSuccess = () => {
        showNotification({
            title: 'Usunięto komentarz',
            message: 'Nie będzie on już dostępny jako podpowiedź',
            color: 'green'
        });
    }

    const deletePreset = (index: number) => {
        startLoading();

        CommentPresetsApi.deletePreset(index)
            .then(onDeletePresetSuccess)
            .then(reload)
            .catch(onDeletePresetError)
            .finally(stopLoading)
    }

    const reload = () => {
        startLoading();

        CommentPresetsApi.getAll()
            .then(setPresets)
            .catch(onReloadError)
            .finally(stopLoading);
    }

    const createPreset = () => {
        createCommentDialog.open();
    }

    useEffect(reload, []);

    return (
        <>
            <PageHeader
                title="Komentarze"
                url="/comment/presets"
                action={<Button onClick={createPreset}>Dodaj komentarz</Button>}
            />

            <PageContent>
                <DataTable
                    highlightOnHover
                    striped
                    minHeight={150}
                    fetching={loading}
                    backgroundColor="transparent"
                    noRecordsText="Brak przygotowanych komentarzy"
                    // presets.map(c => ({ id: c, comment: c}))
                    height="60vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    records={mapPresets()}
                    columns={columns}
                    onRowContextMenu={(data) => {
                        showContextMenu([
                            {
                                key: 'add-preset',
                                title: 'Nowy komentarz',
                                onClick: createPreset,
                                icon: <IconPlus size={16}/>
                            },
                            {
                                key: 'delete-preset',
                                title: 'Usuń',
                                color: 'red',
                                onClick: () => deletePreset(data.index),
                                icon: <IconTrash size={16}/>
                            }
                        ])(data.event)
                    }}
                />
            </PageContent>

            <CreateCommentPresetDialog
                isOpen={isCreateCommentPresetDialogOpen}
                justClose={createCommentDialog.close}
                closeAndUpdate={() => { createCommentDialog.close(); reload(); }}
            />
        </>
    )
}

export default CommentPresets;
