/* eslint-disable */
import {CompositeReport} from "../../../../../api/composite/report/CompositeReportApi";
import CardWrapper from "../CardWrapper";
import PresetItem from "./PresetItem";
import {Group} from "@mantine/core";
import {useEffect, useState} from "react";
import {CommentPresetsApi} from "../../../../../api/comment/presets/CommentPresetsApi";
import {showNotification} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";

export type AddCommentPresetsCardAttrs = {
    compositeReport?: CompositeReport;
    reload: () => void;
}

function AddCommentPresetsCard(attrs: AddCommentPresetsCardAttrs) {
    const [loading, setLoading] = useState(false);
    const [presets, setPresets] = useState<string[]>();
    
    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const reloadPresetsError = () => {
        showNotification({
            title: 'Nie udało się pobrać zapisanych komentarzy',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }
    
    const reloadPresets = () => {
        startLoading();
        
        CommentPresetsApi.getAll()
            .then(setPresets)
            .catch(reloadPresetsError)
            .finally(stopLoading);
    };

    /**
     * Is presets loading or composite report is missing (Loading by upper components)
     */
    const isLoading = () => {
        return loading || !attrs.compositeReport;
    }

    const createItems = () => {
        return presets?.map(p => (<PresetItem reload={attrs.reload} preset={p} compositeReport={attrs.compositeReport}/>));
    }
    
    useEffect(reloadPresets, [attrs.compositeReport]);

    return (
        <CardWrapper loading={isLoading()} title="Dodaj komentarz">
            <Group justify="center">
                <Group justify="center">
                    { createItems() }
                </Group>
            </Group>
        </CardWrapper>
    );
}

export default AddCommentPresetsCard;