import {Button, Modal, Stack, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import CalendarEventApi from "../../../../api/calendar/event/CalendarEventApi";
import {useState} from "react";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";
import {DatePickerInput} from "@mantine/dates";

export type CreateCalendarEventDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
}

export type CreateEventModel = {
    unit: string;
    date: Date | null;
    comment: string;
}

// noinspection DuplicatedCode
function formatDate(date: Date|null) {
    if (!date) { return undefined }
    const year = date.getFullYear();
    let month: string|number = date.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    let day: string|number = date.getDate();
    if (day < 10) {
        day = '0' + day;
    }
    return `${year}-${month}-${day}`;
}

function CreateCalendarEventDialog(attrs: CreateCalendarEventDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { unit: '', date: new Date(), comment: '' },
        validate: {
            unit: c => c.length < 2 ? 'Przynajmniej dwa znaki' : null,
            comment: c => c.length < 2 ? 'Przynajmniej dwa znaki' : null
        }
    });

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Zapisano wydarzenie',
            message: 'Wydarzenie zostało utworzone',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać wydarzenia',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doSubmit = (values: CreateEventModel) => {
        startLoading();

        CalendarEventApi.create({...values, date: formatDate(values.date)!!})
            .then(doSubmitSuccess)
            .then(attrs.closeAndUpdate)
            .catch(doSubmitError)
            .finally(stopLoading);
    }

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Nowe wydarzenie">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        placeholder="Jednostka"
                        key={form.key('unit')}
                        { ...form.getInputProps('unit') }
                    />

                    <DatePickerInput
                        withAsterisk
                        placeholder="Data"
                        key={form.key('date')}
                        { ...form.getInputProps('date') }
                    />

                    <TextInput
                        withAsterisk
                        placeholder="Komentarz"
                        key={form.key('comment')}
                        { ...form.getInputProps('comment') }
                    />

                    <Button type="submit" loading={loading}>
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
}

export default CreateCalendarEventDialog;

// TODO: CreateCalendarEventDialog
//  - Ability to pick device.