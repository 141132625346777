import {ActionIcon, Menu} from "@mantine/core";
import {IconDotsVertical} from "@tabler/icons-react";

export type CompositeReportWorkMenuAttrs = {
    toggleCards: () => void;
    approveAndNext: () => void;

    isSuggestionMode: boolean;
    setSuggestionMode: (b: boolean) => void;

    saveSuggestion: () => void;
}

function CompositeReportWorkMenu(attrs: CompositeReportWorkMenuAttrs) {
    const toggleSuggestion = () => attrs.setSuggestionMode(!attrs.isSuggestionMode);

    return (
        <Menu width={250}>
            <Menu.Target>
                <ActionIcon variant="transparent">
                    <IconDotsVertical/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Widok</Menu.Label>
                <Menu.Item onClick={attrs.toggleCards}>Przełącz karty</Menu.Item>
                <Menu.Item onClick={toggleSuggestion}>{`${attrs.isSuggestionMode ? 'Wyłącz' : 'Włącz'} tryb sugestii`}</Menu.Item>
                <Menu.Item onClick={attrs.saveSuggestion} disabled={!attrs.isSuggestionMode}>Zapisz sugestię</Menu.Item>

                <Menu.Label children="Wykonaj i przewiń"/>
                <Menu.Item children="Zatwierdź" onClick={attrs.approveAndNext}/>
            </Menu.Dropdown>
        </Menu>
    )
}

export default CompositeReportWorkMenu;