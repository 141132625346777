function DateView({date}: {date?: [number, number, number]}) {
    if (!date) {
        return (
            <>-</>
        );
    }

    const formatNum = (d: number) => {
        if (d < 10) return `0${d}`;
        return d;
    }

    const day = date[2];
    const mon = date[1];

    return (
        <>{`${formatNum(day)}-${formatNum(mon)}-${date[0]}`}</>
    );
}

export default DateView;
