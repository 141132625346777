import {ReactNode, useContext, useEffect} from "react";
import {SelectedPageContext} from "../../contexts/SelectedPageContext";

export type PageHeaderAttrs = {
    title: string;
    action?: ReactNode;

    /**
     * Set selected url in SelectedPageContext.
     * @author Kacper Faber
     * @since 0.0.1-SNAPSHOT
     */
    url?: string;

    pageTitle?: string;
};

function setPageTitle(pageTitle?: string) {
    document.title = pageTitle ?? 'TermoControl';
}

function PageHeader({action, title, url, pageTitle}: PageHeaderAttrs) {
    const { setSelectedUrl } = useContext(SelectedPageContext);

    useEffect(() => setPageTitle(pageTitle), [pageTitle]);

    if (url && setSelectedUrl) setSelectedUrl(url);

    return (
        <div id="app_page_header">
            <h4 id="app_page_header__title">
                {title}
            </h4>

            <div>
                {action}
            </div>
        </div>
    );
}

export default PageHeader;
