import {useEffect, useState} from "react";
import {Action, FtpConfig, FtpConfigApi} from "../../../api/ftp/config/FtpConfigApi";
import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {DataTable, DataTableColumn, useDataTableColumns} from "mantine-datatable";
import {showNotification} from "@mantine/notifications";
import {useContextMenu} from "mantine-contextmenu";
import CreateFtpConfigDialog from "./CreateFtpConfigDialog";
import {useDisclosure} from "@mantine/hooks";
import {ActionIcon, Alert, Flex, Menu} from "@mantine/core";
import {IconCheck, IconDotsVertical, IconPlus, IconTestPipe, IconTrash, IconX} from "@tabler/icons-react";
import {modals} from "@mantine/modals";
import TestFtpConfigDialog from "./TestFtpConfigDialog";

export type FtpConfigPageMenuAttrs = {
    createNew: () => void;
    deleteSelected: () => void;
}

function FtpConfigPageMenu(attrs: FtpConfigPageMenuAttrs) {
    return (
        <Menu width={200}>
            <Menu.Target>
                <ActionIcon>
                    <IconDotsVertical size={16}/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item onClick={attrs.createNew}>Nowa konfiguracja</Menu.Item>
                <Menu.Item onClick={attrs.deleteSelected} color="red">Usuń zaznaczone</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

function IsActiveCell({ftpConfig, activeConfig}: {ftpConfig: FtpConfig, activeConfig: FtpConfig | null}) {
    if (activeConfig?.id === ftpConfig.id) {
        return (
            <IconCheck size={16} color="green"/>
        )
    }

    return (
        <IconX size={16} color="red"/>
    )
}

function FtpConfigPage() {
    const key = 'FtpConfig-table-key';
    const { showContextMenu } = useContextMenu();
    const [selectedRecords, setSelectedRecords] = useState<FtpConfig[]>([]);
    const [isCreateFtpConfigDialogOpen, createFtpConfigDialog] = useDisclosure();
    const [selectedFtpConfigForTest, setSelectedFtpConfigForTest] = useState<FtpConfig>();
    const [activeConfig, setActiveConfig] = useState<FtpConfig | null>(null);
    const [isTestFtpConfigOpen, ftpConfigDialog] = useDisclosure();

    const columns: DataTableColumn[] = [
        { accessor: 'id', title: '_id', draggable: true, toggleable: true },
        { accessor: 'hostname', title: 'Nazwa hosta', draggable: true, toggleable: true },
        { accessor: 'port', title: 'Port', draggable: true, toggleable: true },
        { accessor: 'username', title: 'Nazwa użytkownika', draggable: true, toggleable: true },
        { accessor: 'password', title: 'Hasło', render: () => <>***</>, draggable: true, toggleable: true },
        {
            accessor: '',
            title: 'Używana',
            render: (record) => <IsActiveCell ftpConfig={record as FtpConfig} activeConfig={activeConfig}/>,
            draggable: true,
            toggleable: true
        }
    ];

    const { effectiveColumns } = useDataTableColumns({key, columns});

    const [ftpConfigs, setFtpConfigs] = useState<FtpConfig[]>([]);
    const [loading, setLoading] = useState(false);

    const startLoading = () => { setLoading(true); };

    const stopLoading = () => { setLoading(false); };

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const reload = () => {
        startLoading();

        FtpConfigApi.getAll()
            .then(setFtpConfigs)
            .then(() => { FtpConfigApi.getActive().then(setActiveConfig) })
            .catch(onReloadError)
            .finally(stopLoading);
    }

    const doDeleteSelectedError = () => {
        showNotification({
            title: 'Nie udało się usunąć zaznaczonych konfiguracji FTP',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem.',
            color: 'red'
        });
    }

    const doDeleteSelectedSuccess = () => {
        showNotification({
            title: 'Usunięto',
            message: 'Usunięto zaznaczone konfigurację FTP',
            color: 'green'
        });
    }

    const doDeleteSelected = () => {
        startLoading();

        const action: Action = {
            action: 'delete',
            selectedElements: selectedRecords.map(r => r.id)
        };

        FtpConfigApi.performAction(action)
            .then(reload)
            .then(doDeleteSelectedSuccess)
            .catch(doDeleteSelectedError)
            .finally(stopLoading);
    }

    const deleteSelected = () => {
        modals.openConfirmModal({
            title: "Usuwanie zaznaczonych konfiguracji FTP",
            children: `Czy na pewno chcesz usunąć zaznaczone konfiguracje FTP?`,
            labels: { confirm: `Tak, usuń wszystkie`, cancel: 'Nie, nie usuwaj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: doDeleteSelected
        });
    }

    const doDeleteItemError = () => {
        showNotification({
            title: 'Nie udało się usunąć konfiguracji FTP',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem.',
            color: 'red'
        });
    }

    const doDeleteItemSuccess = () => {
        showNotification({
            title: 'Usunięto konfigurację FTP',
            message: 'Usunięto wybraną konfigurację',
            color: 'green'
        });
    }

    const doDeleteItem = (ftpConfig: FtpConfig) => {
        startLoading();

        const action: Action = {
            action: 'delete',
            selectedElements: [ftpConfig.id!!]
        };

        FtpConfigApi.performAction(action)
            .then(reload)
            .then(doDeleteItemSuccess)
            .catch(doDeleteItemError)
            .finally(stopLoading);
    }

    const deleteItem = (ftpConfig: FtpConfig) => {
        modals.openConfirmModal({
            title: "Usuwanie konfiguracji FTP",
            children: `Czy na pewno chcesz usunąć konfigurację FTP?`,
            labels: { confirm: `Tak, usuń`, cancel: 'Nie, nie usuwaj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: () => doDeleteItem(ftpConfig)
        });
    }

    useEffect(reload, []);

    const setActive = (ftpConfig: FtpConfig) => {
        startLoading();

        FtpConfigApi.setActive(ftpConfig.id)
            .then()
            .then(reload)
            .catch()
            .finally(stopLoading);
    }

    const testFtpConfig = (ftpConfig: FtpConfig) => {
        setSelectedFtpConfigForTest(ftpConfig);
        ftpConfigDialog.open();
    }

    return (
        <>
            <PageHeader
                title="Ustawienia FTP"
                url="/ftp/config"
                action={<FtpConfigPageMenu createNew={createFtpConfigDialog.open} deleteSelected={deleteSelected}/> }
            />

            <PageContent>
                <Flex direction="column" gap="md">
                    { activeConfig ? null :
                        <Alert color="red" title="Nie ma aktywnej konfiguracji FTP">
                            Jeśli konfiguracja FTP nie będzie ustawiona, system nie będzie działać poprawnie.
                        </Alert>
                    }

                    <DataTable
                        storeColumnsKey={key}
                        fetching={loading}
                        striped
                        highlightOnHover
                        backgroundColor="transparent"
                        noRecordsText="Brak konfiguracji FTP..."
                        records={ftpConfigs}
                        columns={effectiveColumns}
                        minHeight={150}
                        selectedRecords={selectedRecords}
                        onSelectedRecordsChange={setSelectedRecords}
                        onRowContextMenu={({record, event}) => {
                            showContextMenu([
                                {
                                    key: 'create-new-ftp-config-menu-item',
                                    title: 'Nowa',
                                    icon: <IconPlus size={16}/>,
                                    onClick: createFtpConfigDialog.open
                                },

                                {
                                    key: 'set-primary',
                                    title: 'Ustaw jako aktywną',
                                    onClick: () => setActive(record)
                                },

                                {
                                    key: 'test-ftp-config-ctx-menu-item',
                                    title: 'Przetestuj',
                                    icon: <IconTestPipe size={16}/>,
                                    onClick: () => testFtpConfig(record)
                                },

                                {
                                    key: 'delete-item',
                                    title: 'Usuń',
                                    color: 'red',
                                    icon: <IconTrash size={16}/>,
                                    onClick: () => deleteItem(record)
                                },

                                {
                                    key: 'delete-item-selected',
                                    title: 'Usuń zaznaczone',
                                    color: 'red',
                                    icon: <IconTrash size={16}/>,
                                    onClick: deleteSelected
                                }
                            ])(event)
                        }}
                    />
                </Flex>
            </PageContent>

            <CreateFtpConfigDialog
                isOpen={isCreateFtpConfigDialogOpen}
                closeAndUpdate={() => { createFtpConfigDialog.close(); reload();}}
                justClose={createFtpConfigDialog.close}
            />

            <TestFtpConfigDialog
                isOpen={isTestFtpConfigOpen}
                justClose={ftpConfigDialog.close}
                ftpConfig={selectedFtpConfigForTest}
            />
        </>
    );
}

// TODO:
//  we can't see which config is active.

export default FtpConfigPage;
