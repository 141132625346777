import {CompositeReport} from "../../composite/report/CompositeReportApi";
import {Client} from "../../client/ClientApi";
import {PageRequest} from "../../../models/api/PageRequest";
import {SortedRequest} from "../../../models/api/SortedRequest";
import {Api} from "../../Api";
import {HttpClient} from "../../_http/HttpClient";
import { omitBy } from "lodash";
import Page from "../../../models/api/Page";

export type SendReportItem = {
    id: string;
    subject: string;
    content: string;
    emailAddress: string;
    compositeReport?: CompositeReport;
    client?: Client;
    sendStatus: SendStatus;
    generatedAt: number[];
    processedAt: number[];
}

export type SearchQueryBase = {
    q?: string;
    clientId?: string;
    compositeReportId?: string;
    emailAddress?: string;
}

export type SendStatus = 'ok' | 'ftp_error' |
    'failed_to_read_file' | 'smtp_error' | 'missing_email' |
    'missing_pdf' | 'not_approved';

export type SearchQuery = SearchQueryBase & PageRequest & SortedRequest;

export type Action = { selectedElements: string[]; action: 'delete' | 'send_item' };

export type SendReportConfig = {
    subject?: string;
    content?: string;
}

export class SendReportApi {
    static async search(query: SearchQuery): Promise<Page<SendReportItem>> {
        const url = Api.getUrl('/send/report/search');
        const body = { ...omitBy(query, x => x === undefined || x === null) } as any;
        return HttpClient.post<Page<SendReportItem>>(url, {}, body);
    }

    static async performAction(act: Action) {
        const url = Api.getUrl('/send/report/item_multiple_selection');
        return HttpClient.postAndExpectNoContent(url, {}, { ...act });
    }

    static editConfig(config: SendReportConfig) {
        const url = Api.getUrl('/send/report/edit_config');
        return HttpClient.post(url, {}, {...config});
    }

    static getConfig() {
        const url = Api.getUrl('/send/report/get_config');
        return HttpClient.get<SendReportConfig>(url, {});
    }

    static saveItem(item: SendReportItem) {
        const url = Api.getUrl('/send/report/save_item');
        return HttpClient.post(url, {}, {...item});
    }
}
