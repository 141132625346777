import {Api} from "../Api";
import {HttpClient} from "../_http/HttpClient";
import {Action} from "../../models/api/Action";

export type User = {
    id: string;
    username: string;
    emailAddress?: string;
    isAdmin: boolean;
}

export type CreateQuery = {
    username: string;
    password: string;
    isAdmin: boolean;

    /**
     * Warning! API Currently (03.05.2024) does not support creating with email.
     * @author Kacper Faber
     * @since 0.0.1-SNAPSHOT
     */
    emailAddress?: string;
};

export type SearchQuery = { q?: string };

export type EditUser = {
    id: string;
    username: string;
    isAdmin: boolean;
    emailAddress?: string;
}

export type ChangePassword = {
    id: string;
    password: string;
}

class UserApi {
    static search(query: SearchQuery): Promise<User[]> {
        const url = Api.getUrl("/user");
        return HttpClient.get<User[]>(url, { ...query });
    }

    // noinspection JSUnusedGlobalSymbols
    static performAction(action: Action): Promise<Response> {
        const url = Api.getUrl("/user/action");
        return HttpClient.postAndExpectNoContent(url, {}, { ...action });
    }

    static create(createQuery: CreateQuery): Promise<User> {
        const url = Api.getUrl("/user/create");
        return HttpClient.post(url, {}, {...createQuery});
    }

    // noinspection JSUnusedGlobalSymbols
    static save(user: User): Promise<User> {
        const url = Api.getUrl("/user/save");
        return HttpClient.post(url, {}, { ...user });
    }

    static edit(editUser: EditUser): Promise<User> {
        const url = Api.getUrl('/user/edit')
        return HttpClient.post(url, {}, { ...editUser });
    }

    static changePassword(changePassword: ChangePassword): Promise<Response> {
        const url = Api.getUrl('/user/change-password');
        return HttpClient.postAndExpectNoContent(url, {}, { ...changePassword });
    }

    /**
     * Get current user.
     * GET /api/user/current
     * @author Kacper Faber
     * @since 0.0.1-SNAPSHOT
     */
    static async current() {
        const url = Api.getUrl("/user/current");
        return HttpClient.get<User>(url, {});
    }

    static async simpleList() {
        const url = Api.getUrl('/user/simple-list');
        return HttpClient.get<User[]>(url, {});
    }
}

export default UserApi;
