import {Flex, NavLink} from "@mantine/core";
import {CommentGroup} from "./NiceCommentsDialog";

type NavSectionAttrs = {
    presets: CommentGroup[];
    setLetter: (s: string | undefined) => void;
    letter: string | undefined;
}

function NavSection(attrs: NavSectionAttrs) {
    return (
        <Flex direction="column" w='200px' style={{ borderRight: '1px solid rgba(150, 150, 150, .15)', padding: '10px' }}>
            <NavLink
                component="div"
                variant="filled"
                onClick={() => attrs.setLetter('current')}
                active={attrs.letter === 'current'}
                label="Wszystkie"
                description="Komentarze w raporcie"
            />

            <NavLink
                component="div"
                variant="filled"
                onClick={() => attrs.setLetter('search')}
                active={attrs.letter === 'search'}
                label="Szukaj"
                description="Wyszukaj lub utwórz"
            />

            { attrs.presets.map((preset) =>
                <NavLink
                    component="div"
                    variant="filled"
                    description={`Zaczynające się od ${preset.firstLetter}`}
                    onClick={() => attrs.setLetter(preset.firstLetter)}
                    active={attrs.letter === preset.firstLetter}
                    label={preset.firstLetter.toUpperCase()}
                />)
            }
        </Flex>
    );
}

export default NavSection;
