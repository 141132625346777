import {Button, Checkbox, Modal, Stack} from "@mantine/core";
import {useState} from "react";
import {FtpConfigApi} from "../../../api/ftp/config/FtpConfigApi";
import {showNotification} from "@mantine/notifications";
import {useForm} from "@mantine/form";
import {PasswordInput, TextInput} from "@mantine/core";

export type CreateFtpConfigDialogAttrs = {
    isOpen: boolean;
    closeAndUpdate: () => void;
    justClose: () => void;
}

function CreateFtpConfigDialog(attrs: CreateFtpConfigDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const form = useForm({
        mode: 'uncontrolled',

        initialValues: {
            hostname: '',
            port: 0,
            username: '',
            password: '',
            isActive: false
        },

        validate: {
            hostname: (h) => h.length < 4 ? 'Przynajmniej 4 znaki' : null,
            username: (h) => h.length < 2 ? 'Przynajmniej 2 znaki' : null,
            password: (h) => h.length < 2 ? 'Przynajmniej 2 znaki' : null,
            port: (h) => h > 25000 || h < 1 ? 'Proszę wskazać poprawny port.' : null
        }
    })

    const startLoading = () => { setLoading(true); };

    const stopLoading = () => { setLoading(false); };

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Utworzono konfiguracje FTP!',
            message: 'Można ustawić ją jako aktywną',
            color: 'green'
        });

        attrs.closeAndUpdate();
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSubmit = (values: any) => {
        startLoading();

        FtpConfigApi.save({...values})
            .then(doSubmitSuccess)
            .catch(doSubmitError)
            .finally(stopLoading);
    }

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Nowa konfiguracja FTP">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Nazwa hosta"
                        description="Np. ftp.termmed.pl"
                        key={form.key('hostname')}
                        { ...form.getInputProps('hostname') }
                    />

                    <TextInput
                        withAsterisk
                        type="number"
                        label="Numer portu"
                        description="Np. 123"
                        key={form.key('port')}
                        { ...form.getInputProps('port') }
                    />

                    <TextInput
                        withAsterisk
                        label="Nazwa użytkownika"
                        description="Np. TermMed"
                        key={form.key('username')}
                        { ...form.getInputProps('username') }
                    />

                    <PasswordInput
                        withAsterisk
                        label="Hasło"
                        key={form.key('password')}
                        { ...form.getInputProps('password') }
                    />

                    <Checkbox
                        label="Ustaw jako aktywną"
                        key={form.key('isActive')}
                        { ...form.getInputProps('isActive') }
                    />

                    <Button type="submit" loading={loading}>
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    )
}

// TODO:
//  Is active check box does nothing

export default CreateFtpConfigDialog;