/* eslint-disable */
import {CompositeReport, CompositeReportApi, EditModel} from "../../../../api/composite/report/CompositeReportApi";
import {Button, Modal, Stack, Textarea, TextInput} from "@mantine/core";
import {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";

export type EditSimpleDataDialogAttrs = {
    isOpen: boolean;
    compositeReport?: CompositeReport;
    justClose: () => void;
    closeAndUpdate: () => void;
}


function EditSimpleDataDialog(attrs: EditSimpleDataDialogAttrs) {
    const [loading, setLoading] = useState(false);
    const startLoading = () => { setLoading(true); };
    const stopLoading = () => { setLoading(false); };

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { unit: '', title: '', description: ''},
        validate: {
            unit: e => e.length < 2 ? 'Przynajmniej dwa znaki' : null,
            title: e => e.length < 2 ? 'Przynajmniej dwa znaki' : null
        }
    });

    const onSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać zmian',
            message: 'Jeśli problem będzie się utrzymywał, proszę o kontakt z administratorem',
            color: 'red'
        });
    }

    const doSubmit = (values: any) => {
        startLoading();

        const editModel: EditModel = {
            unit: values.unit,
            title: values.title,
            description: values.description,
            id: attrs.compositeReport!!.id,
            comments: attrs.compositeReport?.comments
        }

        CompositeReportApi.edit(editModel)
            .then(attrs.closeAndUpdate)
            .catch(onSubmitError)
            .finally(stopLoading);
    }

    const loadData = () => {
        form.setValues({
            unit: attrs.compositeReport?.unit ?? '',
            title: attrs.compositeReport?.title ?? '',
            description: attrs.compositeReport?.description ?? ''
        });
    }

    useEffect(loadData, [attrs.compositeReport]);

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Edycja danych z raportu">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Jednostka"
                        description="Odpowiada wartość unit z raportów"
                        key={form.key('unit')}
                        { ...form.getInputProps('unit') }
                    />

                    <TextInput
                        withAsterisk
                        label="Tytuł raportu"
                        key={form.key('title')}
                        { ...form.getInputProps('title') }
                    />

                    <Textarea
                        label="Opis"
                        minRows={3}
                        resize={'vertical'}
                        key={form.key('description')}
                        { ...form.getInputProps('description') }
                    />

                    <Button type="submit" loading={loading}>
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    )
}

export default EditSimpleDataDialog;