import {PageRequest} from "../../../models/api/PageRequest";
import {SortedRequest} from "../../../models/api/SortedRequest";
import {HttpClient} from "../../_http/HttpClient";
import {Api} from "../../Api";
import Page from "../../../models/api/Page";

export type Record = { temp1?: number, temp2?: number };

export type DeviceReport = {
    id: string;
    unit?: string;
    name?: string;
    date?: [number, number, number];
    recordNames?: string[];
    records?: Record[];
    filePath?: string;
}

type SearchQueryBase = {
    unit?: string;
    q?: string;
    dateMin?: string;
    dateMax?: string;
}

export type SearchQuery = SearchQueryBase & PageRequest & SortedRequest;

export type ActionPayload = {
    action: "delete" | "create_report",
    selectedElements: string[];
}

export class DeviceReportApi {
    static search(searchRequest: SearchQuery) {
        const url = Api.getUrl("/device/report");
        const copy = {...searchRequest};
        if (copy.unit === '') copy.unit = undefined;
        const r = JSON.parse(JSON.stringify(copy));
        return HttpClient.get<Page<DeviceReport>>(url, r);
    }

    static performAction(p: ActionPayload) {
        const url = Api.getUrl("/device/report/action");
        return HttpClient.postAndExpectNoContent(url, {}, { ...p });
    }

    static getReport(id: string) {
        const url = Api.getUrl("/device/report/report");
        return HttpClient.get<DeviceReport>(url, { id });
    }

    // noinspection JSUnusedGlobalSymbols
    static importText(text: string) {
        const url = Api.getUrl('/device/report/import-text');
        return HttpClient.post<DeviceReport>(url, {}, text);
    }
}
