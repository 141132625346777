import {Api} from "../../Api";
import {HttpClient} from "../../_http/HttpClient";

export class CommentPresetsApi {
    static getAll() {
        const url = Api.getUrl('/comment/presets');
        return HttpClient.get<string[]>(url, {});
    }

    static deletePreset(commentIndex: number) {
        const url = Api.getUrl('/comment/presets/delete_preset');
        return HttpClient.deleteAndExpectNoContent(url, {commentIndex}, {});
    }

    static addPreset(preset: string) {
        const url = Api.getUrl('/comment/presets/add_preset');
        return HttpClient.postAndExpectNoContent(url, {}, { comment: preset });
    }
}