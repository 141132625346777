import {DataTable, DataTableColumn, DataTableSortStatus, useDataTableColumns} from "mantine-datatable";
import {useEffect, useState} from "react";
import {Action, Device, DeviceApi} from "../../api/device/DeviceApi";
import Page from "../../models/api/Page";
import PaginationFooter from "../../components/pagination/PaginationFooter";
import PageContent from "../../components/layout/PageContent";
import PageHeader from "../../components/layout/PageHeader";
import {notifications, showNotification} from "@mantine/notifications";
import {ActionIcon, Menu} from "@mantine/core";
import CreateDeviceDialog from "./CreateDeviceDialog";
import {useDisclosure, useSessionStorage} from "@mantine/hooks";
import DeviceFilterDialog from "./DeviceFilterDialog";
import {modals} from "@mantine/modals";
import {useContextMenu} from "mantine-contextmenu";
import {IconEdit, IconEye, IconRubberStamp, IconTrash, IconUser} from "@tabler/icons-react";
import DeviceEditDialog from "./DeviceEditDialog";
import Cell, {CellHighlight} from "../../components/table/Cell";
import AssignClientDialog from "./AssignClientDialog";
import DeviceCustomFooterDialog from "./DeviceCustomFooterDialog";
import {useNavigate} from "react-router-dom";
import ImportDevicesDialog from "./ImportDevicesDialog";
import GenerateForSelectedDevicesDialog from "../composite/report/GenerateForSelectedDevicesDialog";
import DeviceFilter from "./DeviceFilter";

type DevicePageMenuAttrs = {
    q?: string,
    setQ: (s?: string) => void,
    reload: () => void,
    deleteSelected: () => void,
    importDevices: () => void,
    generateForSelectedDevices: () => void,
    doFilter: () => void
};

function DevicePageMenu(attrs: DevicePageMenuAttrs) {
    const [isCreateDeviceDialogOpen, createDeviceDialog] = useDisclosure();
    const [isFilterDialogOpen, filterDialog] = useDisclosure();

    const resetFilter = () => {
        attrs.setQ('');

        showNotification({
            title: 'Wyczyszczono filtry',
            message: 'Teraz zobaczysz wszystkie informacje'
        });
    }

    return (
        <>
            <Menu width={200}>
                <Menu.Target>
                    <ActionIcon>
                        <i className="fa fa-filter"></i>
                    </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Label>Urządzenia</Menu.Label>
                    <Menu.Item onClick={createDeviceDialog.open}>
                        Utwórz
                    </Menu.Item>

                    <Menu.Item onClick={attrs.importDevices}>
                        Importuj
                    </Menu.Item>

                    <Menu.Label>Filtrowanie</Menu.Label>
                    <Menu.Item onClick={attrs.doFilter}>
                        Filtruj wyniki
                    </Menu.Item>
                    <Menu.Item color={'red'} onClick={resetFilter}>
                        Wyczyść filtry
                    </Menu.Item>

                    <Menu.Label>Generowanie</Menu.Label>
                    <Menu.Item onClick={attrs.generateForSelectedDevices}>Generuj dla wybranych urządzeń</Menu.Item>

                    <Menu.Label>Zaznaczenie wielokrotne</Menu.Label>
                    <Menu.Item color="red" onClick={attrs.deleteSelected}>Usuń zaznaczone</Menu.Item>
                </Menu.Dropdown>
            </Menu>

            <CreateDeviceDialog
                isOpen={isCreateDeviceDialogOpen}
                onClose={createDeviceDialog.close}
                reload={attrs.reload}
            />

            <DeviceFilterDialog
                isOpen={isFilterDialogOpen}
                onClose={() => { attrs.setQ(attrs.q); filterDialog.close(); }}
                setQ={attrs.setQ}
                q={attrs.q}
            />
        </>
    );
}

function DevicePage() {
    const columns: DataTableColumn[] = [
        {
            accessor: 'id',
            title: '#',
            toggleable: true,
            defaultToggle: false
        },

        {
            accessor: 'unit',
            title: '#',
            width: '10%',
            sortable: true,
            draggable: true,
            render: (r) => <CellHighlight text={r.unit} searchText={q}/>
        },

        {
            accessor: 'name',
            title: 'Nazwa urządzenia',
            width: '20%',
            sortable: true,
            draggable: true,
            render: (r) => <CellHighlight text={r.name} searchText={q}/>
        },

        {
            accessor: 'description',
            title: 'Opis',
            width: '85%',
            sortable: true,
            toggleable: true,
            resizable: true,
            draggable: true,
            render: (r) => <CellHighlight text={r.description} searchText={q}/>
        },

        {
            accessor: 'location',
            title: 'Lokalizacja',
            sortable: true,
            toggleable: true,
            draggable: true,
            render: (r) => <CellHighlight text={r.location} searchText={q}/>
        },

        {
            accessor: 'generator',
            title: 'Generator',
            toggleable: true,
            defaultToggle: false,
            sortable: true,
            draggable: true,
            render: (r) => <CellHighlight text={r.generator} searchText={q}/>
        },

        {
            accessor: 'thermostat',
            title: 'Termostat',
            toggleable: true,
            defaultToggle: false,
            sortable: true,
            draggable: true,
            render: (r) => <CellHighlight text={r.thermostat} searchText={q}/>
        },

        {
            accessor: 'minAllowableTemp',
            title: 'Min. temp',
            toggleable: true,
            defaultToggle: false,
            sortable: true,
            draggable: true,
            render: (r) => <Cell orOptional={r.minAllowableTemp}/>
        },

        {
            accessor: 'maxAllowableTemp',
            title: 'Maks. temp',
            toggleable: true,
            defaultToggle: false,
            sortable: true,
            draggable: true,
            render: (r) => <Cell orOptional={r.maxAllowableTemp}/>
        },

        {
            accessor: 'ftpDirectory',
            title: 'Folder FTP',
            toggleable: true,
            defaultToggle: false,
            sortable: true,
            draggable: true,
            render: (r) => <CellHighlight text={r.location} searchText={q}/>
        },

        {
            accessor: 'client.visibleName',
            title: 'Klient',
            toggleable: true,
            render: (r) => <Cell orOptional={ (r as any).client?.visibleName } />,
            draggable: true
        },

        {
            accessor: 'doesOverrideFooterRight',
            title: 'Pieczątka',
            toggleable: true,
            defaultToggle: false,
            render: (r) => r.doesOverrideFooterRight ? <i className="fa fa-check" style={{color: 'green'}}></i> : <i className="fa fa-x" style={{color: 'red'}}></i>,
            draggable: true,
            sortable: true
        },

        {
            accessor: 'client.emailAddress',
            title: 'Adres E-mail (Klient)',
            toggleable: true,
            render: (r) => <Cell orOptional={ (r as any).client?.emailAddress } />,
            draggable: true
        },

        {
            accessor: 'client.phoneNumber',
            title: 'Numer telefonu (Klient)',
            toggleable: true,
            render: (r) => <Cell orOptional={ (r as any).client?.phoneNumber } />,
            draggable: true
        }
    ]

    const [page, setPage] = useState<Page<Device>>();
    const [limit, setLimit] = useState(50);
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
    const [fetching, setFetching] = useState(false);
    const key = "DevicePage-table-key";
    const {effectiveColumns} = useDataTableColumns({key, columns});
    const [q, setQ] = useState<string | undefined>('');
    const [editDevice, setEditDevice] = useState<Device>();
    const [isEditDeviceModalOpen, editDeviceModal] = useDisclosure();
    const {showContextMenu} = useContextMenu();
    const [isAssignClientDialogOpen, assignClientDialog] = useDisclosure();
    const [assignClientSelectedDevice, setAssignClientSelectedDevice] = useState<Device>();
    const [isDeviceFooterDialog, deviceFooterDialog] = useDisclosure();
    const [deviceFooterDialogSelectedDevice, setDeviceFooterDialogSelectedDevice] = useState<Device>();
    const nav = useNavigate();
    const [isImportDevicesDialogOpen, importDevicesDialog] = useDisclosure();
    const [isGenerateForSelectedDevicesOpen, generateForSelectedDevicesDialog] = useDisclosure();
    const [isFiltersVisible, setFiltersVisible] = useState(false);

    const startFetching = () => { setFetching(true) };

    const stopFetching = () => { setFetching(false); }

    const [sortStatus, setSortStatus] = useSessionStorage<DataTableSortStatus<Device>>({
        key: 'Device_SendStatus',
        defaultValue: {
            columnAccessor: 'unit',
            direction: 'asc'
        }
    });

    const onReloadError = () => {
        notifications.show({
            title: 'Wystąpił błąd...',
            message: "Podczas pobierania danych wystąpił błąd.",
            color: 'red'
        });
    };

    const reload = () => {
        setFetching(true);

        const query = {
            page: pageNumber,
            limit,
            sortBy: sortStatus.columnAccessor,
            sortDir: sortStatus.direction,
            q
        };

        DeviceApi.search(query)
            .then(setPage)
            .catch(onReloadError)
            .finally(() => setFetching(false));
    }

    const onDeleteSelectedError = () => {
        showNotification({
            title: 'Nie udało się usunąć urządzeń',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const onDeleteSelectedSuccess = () => {
        showNotification({
            title: 'Usunięto zaznaczone urządzenia',
            message: `Usunięto zaznaczone urządzenia.`,
            color: 'green'
        });

        reload();
    }

    const doDeleteSelected = () => {
        const action: Action = {
            action: 'delete',
            selectedElements: selectedDevices.map(device => device.id)
        }

        DeviceApi.performAction(action)
            .then(onDeleteSelectedSuccess)
            .catch(onDeleteSelectedError);
    }

    const deleteSelected = () => {
        modals.openConfirmModal({
            title: "Usuwanie zaznaczonych",
            children: `Czy na pewno chcesz usunąć zaznaczonych urządzeń.`,
            labels: { confirm: 'OK', cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: doDeleteSelected
        });
    };

    const doEditDevice = (device: Device) => {
        setEditDevice(device);
        editDeviceModal.open();
    }

    const doDeleteDeviceSuccess = () => {
        showNotification({
            title: 'Usunięto urządzenie',
            message: `Poprawnie usunięto urządzenie`,
            color: 'green'
        });
    }

    const doDeleteDeviceError = () => {
        showNotification({
            title: 'Nie udało się usunąć urządzenia',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem.',
            color: 'red'
        });
    }

    const doDeleteDevice = (device: Device) => {
        const action: Action = { action: 'delete', selectedElements: [device.id]};

        startFetching();

        DeviceApi.performAction(action)
            .then(reload)
            .then(doDeleteDeviceSuccess)
            .catch(doDeleteDeviceError)
            .finally(stopFetching);
    }

    const deleteDevice = (device: Device) => {
        modals.openConfirmModal({
            title: "Usuwanie urządzenia",
            children: `Czy na pewno chcesz usunąć urządzenie ${device?.name} (jednostka ${device?.unit})?`,
            labels: { confirm: 'Tak, usuń', cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: () => doDeleteDevice(device)
        });
    }

    const doAssignClient = (device: Device) => {
        setAssignClientSelectedDevice(device);
        assignClientDialog.open();
    }

    const doChangeCustomFooter = (device: Device) => {
        setDeviceFooterDialogSelectedDevice(device);
        deviceFooterDialog.open();
    }

    const onRowContextMenu = (data: {record: Device, event: any}) => {
        showContextMenu(
            [
                {
                    key: 'device-go-to-summary-menu-item',
                    title: 'Wyświetl',
                    icon: <IconEye size={16}/>,
                    onClick: () => nav(`/device/view/${data.record.id}`)
                },

                {
                    key: 'edit-device-menu-item',
                    title: 'Edytuj urządzenie',
                    icon: <IconEdit size={16}/>,
                    onClick: () => doEditDevice(data.record)
                },

                {
                    key: 'assign-client-menu-item',
                    title: 'Przypisz klienta',
                    icon: <IconUser size={16} />,
                    onClick: () => doAssignClient(data.record)
                },

                {
                    key: 'default-stamp-device-menu-item',
                    title: 'Pieczątka',
                    icon: <IconRubberStamp size={16}/>,
                    onClick: () => doChangeCustomFooter(data.record)
                },

                { key: 'divider' },

                {
                    key: 'delete-device-menu-item',
                    title: 'Usuń urządzenie',
                    color: 'red',
                    icon: <IconTrash size={16}/>,
                    onClick: () => deleteDevice(data.record)
                },

                {
                    key: 'delete-selected-devices-menu-item',
                    title: 'Usuń zaznaczone',
                    color: 'red',
                    icon: <IconTrash size={16}/>,
                    onClick: deleteSelected
                }
            ]
        )(data.event)
    }

    useEffect(reload, [pageNumber, limit, sortStatus, q]);


    return (
        <>
            <PageHeader
                title={'Urządzenia'}
                url="/device"
                action={<DevicePageMenu
                    doFilter={() => setFiltersVisible(!isFiltersVisible)}
                    q={q}
                    setQ={setQ}
                    reload={reload}
                    deleteSelected={deleteSelected}
                    importDevices={importDevicesDialog.open}
                    generateForSelectedDevices={generateForSelectedDevicesDialog.open}/>}
            />

            <PageContent>
                <DeviceFilter
                    isOpen={isFiltersVisible}
                    query={q ?? ''}
                    doUpdate={(newQuery) => { setQ(newQuery); reload(); }}
                />

                <DataTable
                    mt="xl"
                    height="60vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    striped
                    highlightOnHover
                    backgroundColor={'transparent'}
                    fetching={fetching}
                    minHeight={150}
                    noRecordsText={"Pusto..."}
                    loaderBackgroundBlur={1}
                    columns={effectiveColumns}
                    records={page?.content ?? []}
                    style={{marginBottom: '50px'}}
                    selectedRecords={selectedDevices}
                    onSelectedRecordsChange={setSelectedDevices}
                    sortStatus={sortStatus}
                    onSortStatusChange={(sortStatus) => setSortStatus(sortStatus)}
                    storeColumnsKey={key}
                    onRowContextMenu={onRowContextMenu}
                />

                <PaginationFooter
                    page={page}
                    setLimit={setLimit}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    limit={limit}
                />
            </PageContent>

            <DeviceEditDialog
                isOpen={isEditDeviceModalOpen}
                onUpdated={() => { editDeviceModal.close(); reload(); }}
                onClosedWithoutUpdate={editDeviceModal.close}
                device={editDevice}
            />

            <AssignClientDialog
                isOpen={isAssignClientDialogOpen}
                justClose={assignClientDialog.close}
                closeAndUpdate={() => { reload(); assignClientDialog.close(); }}
                device={assignClientSelectedDevice}
            />

            <DeviceCustomFooterDialog
                isOpen={isDeviceFooterDialog}
                justClose={deviceFooterDialog.close}
                closeAndUpdate={deviceFooterDialog.close}
                deviceId={deviceFooterDialogSelectedDevice?.id}
            />

            <ImportDevicesDialog
                isOpen={isImportDevicesDialogOpen}
                justClose={importDevicesDialog.close}
                closeAndUpdate={() => { reload(); importDevicesDialog.close(); }}
            />

            <GenerateForSelectedDevicesDialog
                deviceIds={selectedDevices.map(dev => dev.id)}
                isOpen={isGenerateForSelectedDevicesOpen}
                justClose={generateForSelectedDevicesDialog.close}
                closeAndUpdate={() => { generateForSelectedDevicesDialog.close(); reload(); }}
            />
        </>
    );
}

export default DevicePage;
