import {Button, Modal, PasswordInput, Select, Stack, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import UserApi from "../../api/user/UserApi";
import {showNotification} from "@mantine/notifications";
import {useState} from "react";

export type CreateUserDialogAttrs = {
    isOpen: boolean;
    onClose: () => void;
}

type UserFormProto = {
    username: string;
    emailAddress: string;
    password: string;
    isAdmin: string|null;
}

function CreateUserDialog(attrs: CreateUserDialogAttrs) {
    const [loading, setLoading] = useState(false);

    // noinspection JSUnusedGlobalSymbols
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { username: '', emailAddress: '', password: '', isAdmin: null },

        validate: {
            username: (u) => u.length < 2 ? 'Przynajmniej dwa znaki' : null,
            emailAddress: (u) => {
                if (!u) return null; // Without email, OK...
                return u.length < 5 ? ['Przynajmniej 5 znaków'] : null;
            },
            password: (u) => u.length < 5 ? 'Przynajmniej 5 znaków' : null,
            isAdmin: (u) => !u ? 'Proszę wybrać typ konta' : null
        }
    });

    const onCreateError = () => {
        showNotification({
            title: 'Nie udało się utworzyć użytkownika',
            message: 'Proszę upewnić się, że nazwa użytkownika nie jest zajęta i że masz odpowiednie uprawnienia.',
            color: 'red'
        });

        form.setFieldError("username", 'Upewnij się, że nazwa użytkownika nie jest zajęta.');
    };

    const onCreated = () => {
        attrs.onClose();

        showNotification({
            title: 'Utworzono nowego użytkownika',
            message: 'Może on już zalogować się przy użyciu nazwy użytkownika i hasła!',
            color: 'green'
        });

        form.reset();
    }

    const startLoading = () => { setLoading(true); };
    const stopLoading = () => { setLoading(false) };

    const create = (values: UserFormProto) => {
        const isAdmin = convertAdminToBool(values.isAdmin);
        const data = { ...values, isAdmin };

        startLoading();

        UserApi.create(data)
            .then(onCreated)
            .catch(onCreateError)
            .finally(stopLoading);
    }

    const convertAdminToBool = (u: string|null) => u === 'admin';

    const userRoles = [
        {label: 'Administrator', value: 'admin'},
        {label: 'Użytkownik', value: 'user'}
    ]

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.onClose} title="Nowy użytkownik">
            <form onSubmit={form.onSubmit((values) => create(values))}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Nazwa użytkownika"
                        placeholder="Pracownik 1"
                        variant="filled"
                        key={form.key('username')}
                        {...form.getInputProps('username')}
                    />

                    <TextInput
                        type="email"
                        label="Adres E-mail"
                        placeholder="pracownik@termmed.pl"
                        variant="filled"
                        key={form.key('emailAddress')}
                        {...form.getInputProps('emailAddress')}
                    />

                    <PasswordInput
                        withAsterisk
                        label="Hasło"
                        placeholder="****"
                        variant="filled"
                        key={form.key('password')}
                        {...form.getInputProps('password')}
                    />

                    <Select
                        withAsterisk
                        label="Typ konta"
                        placeholder="---"
                        variant="filled"
                        key={form.key('isAdmin')}
                        {...form.getInputProps('isAdmin')}
                        data={userRoles}
                    />
                    <Button
                        loading={loading}
                        loaderProps={{type: 'dots'}}
                        type="submit"
                    >
                        Utwórz
                    </Button>
                </Stack>

            </form>
        </Modal>
    );
}

export default CreateUserDialog;
