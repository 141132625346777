import {HttpClient} from "../../_http/HttpClient";
import {Api} from "../../Api";

export type SmtpConfig = {
    hostname?: string;
    port?: number;
    username?: string;
    password?: string;
    cc: string[];
    sender: string;
    startTls?: boolean;
}

// noinspection JSUnusedGlobalSymbols
export class SmtpConfigApi {
    static edit(config: SmtpConfig) {
        const url = Api.getUrl('/smtp/config/edit');
        return HttpClient.putAndExpectNoContent(url, {}, { ...config });
    }

    static doTest(emailAddress: string) {
        const url = Api.getUrl('/smtp/config/do_test');
        return HttpClient.postAndExpectNoContent(url, { emailAddress }, {});
    }

    static getActive(): Promise<SmtpConfig> {
        const url = Api.getUrl('/smtp/config');
        return HttpClient.get<SmtpConfig>(url, {});
    }
}