import {Button, Modal, Stack, TextInput} from "@mantine/core";
import {ClientApi} from "../../api/client/ClientApi";
import {useEffect, useState} from "react";

export type CreatedClientData = {
    visibleName: string;
    emailAddress?: string;
    phoneNumber?: string;
}

export type CreateClientDialogAttrs = {
    opened: boolean;

    open: () => void;
    close: () => void;

    onClientCreated: (data: CreatedClientData) => void;
}

function CreateClientDialog(attrs: CreateClientDialogAttrs) {
    const [nameError, setNameError] = useState<string>()
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const createClient = () => {
        if (name === '') {
            setNameError('Pole wymagane');
            return;
        }

        const createdClientData: CreatedClientData = {
            visibleName: name,
            emailAddress: email,
            phoneNumber: phone
        }

        const emailAddress = email === '' ? undefined : email;
        const phoneNumber = phone === '' ? undefined : phone;

        ClientApi.create({emailAddress, phoneNumber, visibleName: name})
            .then(() => attrs.onClientCreated(createdClientData))
            .catch()
    }

    useEffect(() => { setName('') }, [attrs.opened]);

    return (
        <Modal opened={attrs.opened} onClose={attrs.close} title={"Nowy klient"}>
            <Stack>
                <TextInput
                    withAsterisk
                    error={nameError}
                    onInput={(x) => setName((x.target as HTMLInputElement).value)}
                    label={"Nazwa klienta"}
                    placeholder={"Nazwa klienta"}
                />

                <TextInput
                    onInput={(x) => setEmail((x.target as HTMLInputElement).value)}
                    label={"Adres E-mail"}
                    placeholder={"Adres E-mail"}
                />

                <TextInput
                    onInput={(x) => setPhone((x.target as HTMLInputElement).value)}
                    label={"Numer telefonu"}
                    placeholder={"Numer telefonu"}
                />


                <Button title={'Utwórz'} onClick={createClient}>
                    <i className="fa fa-add"></i>
                    Utwórz
                </Button>
            </Stack>
        </Modal>
    );
}

export default CreateClientDialog;
