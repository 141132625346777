import {CommentGroup} from "./NiceCommentsDialog";
import {NavLink, ScrollArea, Stack, TextInput} from "@mantine/core";
import {useEffect, useState} from "react";
import highlight from "../../../highlight/TextHighlight";

export type SearchSectionAttrs = {
    presets: CommentGroup[];
    addOrDelete: (s: string) => void;
    isPresent: (s: string) => boolean;
}

function SearchSection(attrs: SearchSectionAttrs) {
    const [matching, setMatching] = useState<string[]>([]);
    const [query, setQuery] = useState<string>('');

    const reloadMatching = () => {
        if (query.length < 3) {
            setMatching([]);
            return;
        }

        const regex = new RegExp(query, 'gi');
        const comments = attrs.presets.flatMap(x => x.comments)
        setMatching(comments.filter((t) => regex.test(t)));
    }

    useEffect(reloadMatching, [attrs.presets, query]);

    return (
        <ScrollArea type="scroll" h="75vh" w="100%">
            <Stack style={{paddingLeft: '10px'}}>
                <TextInput
                    autoFocus
                    width="100%"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder="Szukaj, lub utwórz"
                />

                {query.length < 3 ? null : <NavLink
                    onClick={() => {
                        attrs.addOrDelete(query);
                        setQuery('')
                    }}
                    component="div"
                    label={query}
                    description="Utwórz komentarz"
                />}

                {matching.map(match =>
                    <NavLink
                        component="div"
                        active={attrs.isPresent(match)}
                        description={attrs.isPresent(match) ? 'Komentarz dodany, kliknij aby go usunąć' : 'Kliknij, aby dodać'}
                        onClick={() => attrs.addOrDelete(match)}
                        label={highlight({text: match, searchText: query})}
                    />
                )}
            </Stack>
        </ScrollArea>
    );
}

export default SearchSection;
