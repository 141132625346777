/* eslint-disable react-hooks/exhaustive-deps */
import UserApi, {EditUser, User} from "../../api/user/UserApi";
import {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import {Button, Modal, Select, Stack, TextInput} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";

export type EditUserDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
    user: User | undefined;
}

function EditUserDialog(attrs: EditUserDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            emailAddress: '',
            username: '',
            isAdmin: 'false'
        },
        validate: {
            username: (u) => u.length < 2 ? 'Przynajmniej dwa znaki' : null,
            isAdmin: (u) => u !== 'false' && u !== 'true' ? 'Proszę wybrać rodzaj konta' : null
        }
    });

    const loadFormData = () => {
        form.setValues({
            emailAddress: attrs.user?.emailAddress ?? '',
            username: attrs.user?.username!!,
            isAdmin: attrs.user?.isAdmin ? 'true' : 'false'
        });
    }

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Użytkownik zmodyfikowany',
            message: 'Udało się zapisać zmiany.',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doSubmitError = () => {
        form.setFieldError('username', 'Nie udało się zapisać konta.');

        showNotification({
            title: 'Nie udało się zapisać zmian',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doSubmit = (values: { emailAddress: string, username: string, isAdmin: string }) => {
        const editUser: EditUser = {
            id: attrs.user?.id!!,
            username: values.username,
            isAdmin: values.isAdmin === 'true',
            emailAddress: values.emailAddress !== '' ? values.emailAddress : undefined
        }

        startLoading();

        UserApi.edit(editUser)
            .then(doSubmitSuccess)
            .then(attrs.closeAndUpdate)
            .catch(doSubmitError)
            .finally(stopLoading);
    }

    const isAdminOptions = [
        { label: 'Administrator', value: 'true' },
        { label: 'Użytkownik', value: 'false' },
    ]

    useEffect(loadFormData, [attrs.user]);

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title={`Edycja użytkownika ${attrs.user?.username ?? ''}`}>
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Nazwa użytkownika"
                        placeholder="Pracownik 1"
                        variant="filled"
                        key={form.key('username')}
                        {...form.getInputProps('username')}
                    />

                    <TextInput
                        type="email"
                        label="Adres E-mail"
                        placeholder="pracownik@termmed.pl"
                        variant="filled"
                        key={form.key('emailAddress')}
                        {...form.getInputProps('emailAddress')}
                    />

                    <Select
                        withAsterisk
                        label="Typ konta"
                        placeholder="---"
                        variant="filled"
                        key={form.key('isAdmin')}
                        {...form.getInputProps('isAdmin')}
                        data={isAdminOptions}
                    />

                    <Button loading={loading} type="submit">
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
}

export default EditUserDialog;
