import UserApi, {User} from "../../api/user/UserApi";
import {useForm} from "@mantine/form";
import {Button, Modal, PasswordInput, Stack} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";
import {useState} from "react";

export type ChangePasswordDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
    user: User | undefined;
}

function ChangePasswordDialog(attrs: ChangePasswordDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { password: '' },
        validate: { password: p => p.length < 5 ? 'Przynajmniej 5 znaków' : null}
    });

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zmienić hasła',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });

        form.setFieldError('password', 'Nie udało się zmienić hasła.')
    }

    const doSubmit = (values: { password: string }) => {
        startLoading();

       UserApi.changePassword({password: values.password, id: attrs.user?.id!!})
           .then(attrs.closeAndUpdate)
           .then(form.reset)
           .catch(doSubmitError)
           .finally(stopLoading);
    }

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title={`Zmiana hasła użytkownika ${attrs.user?.username ?? ''}`}>
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <PasswordInput
                        label="Nowe hasło"
                        type="password"
                        key={form.key('password')}
                        { ...form.getInputProps('password') }
                    />

                    <Button type="submit" loading={loading}>
                        Zmień hasło
                    </Button>
                </Stack>
            </form>
        </Modal>
    )
}

export default ChangePasswordDialog;
