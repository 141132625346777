import {Api} from "../../Api";
import {HttpClient} from "../../_http/HttpClient";

export type FtpConfig = {
    id: string;
    hostname: string;
    port: number;
    username?: string;
    password?: string;
}

export type Action = {
    action: 'delete',
    selectedElements: string[]
}

export type DoTestBody = {
    ftpConfigId: string;
    filePath: string;
}

// noinspection JSUnusedGlobalSymbols
export class FtpConfigApi {
    static getActive() {
        const url = Api.getUrl('/ftp/config/get_active');
        return HttpClient.getFromJsonOrNull<FtpConfig | null>(url, {});
    }

    static getAll() {
        const url = Api.getUrl('/ftp/config');
        return HttpClient.get<FtpConfig[]>(url, {});
    }

    static save(ftpConfig: FtpConfig) {
        const url = Api.getUrl('/ftp/config/save');
        return HttpClient.post<FtpConfig>(url, {}, { ...ftpConfig });
    }

    static setActive(id: string) {
        const url = Api.getUrl('/ftp/config/set_active');
        return HttpClient.put<FtpConfig>(url, { id }, { id });
    }

    static performAction(action: Action) {
        const url = Api.getUrl('/ftp/config/action');
        return HttpClient.postAndExpectNoContent(url, {}, { ...action });
    }

    /**
     * POST /ftp/config/do_test
     * Tries to read file from FTP using given configuration, and returns file content.
     * @author Kacper Faber
     * @param doTestBody
     */
    static doTest(doTestBody: DoTestBody) {
        const url = Api.getUrl('/ftp/config/do_test');
        return HttpClient.postAndExpectNoContent(url, {}, { ...doTestBody });
    }
}
