/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Flex,
    Modal,
    NavLink,
    Pagination,
    ScrollArea,
    Stack,
    TextInput
} from "@mantine/core";
import React, {ReactNode, useEffect, useState} from "react";
import Page from "../../models/api/Page";
import highlight from "../highlight/TextHighlight";
import {showNotification} from "@mantine/notifications";
import {IconCircleX} from "@tabler/icons-react";

export type Item = {
    key?: string,
    value: string,
    title: string,
    description?: string,
    disabled?: boolean,
    right?: ReactNode,
    left?: ReactNode
}

export type PickerDialogAttrs = {
    isOpen: boolean;
    title?: string;
    justClose: () => void;
    pickAndClose: (item: Item) => void;
    loadData: (query: string, page: number, limit: number) => Promise<Page<any>>;
    mapData: (page: Page<any>) => Item[];
    active?: string;
    button?: {
        text: string;
        loading?: boolean
    },
    page: {
        limit: number
    }
}


function PickerDialog(attrs: PickerDialogAttrs) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setLoading] = useState(false);
    const [page, setPage] = useState<Page<any>>();
    const [items, setItems] = useState<Item[]>();
    const [query, setQuery] = useState('');
    const [active, setActive] = useState<Item>();
    const [pageNumber, setPageNumber] = useState<number>(0);

    const onPageChanged = (n: number) => {
        // Pagination component's first page is 1 (not 0)
        setPageNumber(n - 1);
    }

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const resetState = () => {
        setActive(undefined);
    }

    const doPickAndClose = () => {
        attrs.pickAndClose(active!!);
        resetState();
    }

    const loadInitialActive = () => {
        if (!attrs.active) return;
        setActive(items?.find(item => item.value === attrs.active) ?? undefined);
    }

    const reload = () => {
        startLoading();

        attrs.loadData(query, pageNumber, attrs.page.limit)
            .then((pageData) => { setPage(pageData); return pageData; })
            .then(attrs.mapData)
            .then(setItems)
            .then(loadInitialActive)
            .catch(loadDataError)
            .finally(stopLoading);
    }

    const loadDataError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconCircleX size={32}/>
        });

        attrs.justClose();
    }

    const isItemActive = (itemToCheck: Item) => {
        return itemToCheck.value === active?.value;
    }

    const onItemClicked = (clickedItem: Item) => {
        clickedItem.value === active?.value ? setActive(undefined) : setActive(clickedItem)
    }

    const keyOrValue = (item: Item) => item.key ?? item.value;

    const justClose = () => {
        attrs.justClose();
        resetState();
    }

    const resetQuery = () => {
        setQuery('');
    }

    const resetQueryIfOpened = () => {
        if (attrs.isOpen) { resetQuery(); }
    }

    const getTextHighlight = (text?: string) => {
        return highlight({text, searchText: query});
    }

    useEffect(reload, [attrs.isOpen, query, pageNumber]);

    useEffect(resetQueryIfOpened, [attrs.isOpen]);

    return (
        <Modal
            opened={attrs.isOpen}
            onClose={justClose}
            title={attrs.title}
            transitionProps={{transition: 'pop'}}
            overlayProps={{backgroundOpacity: 0.55, blur: 3}}
        >
            <Stack>
                <TextInput
                    data-autofocus
                    placeholder="Szukaj"
                    onChange={(ev) => setQuery(ev.target.value)}
                />

                <ScrollArea>
                    {
                        items?.map(item =>
                            <NavLink
                                href="#"
                                key={keyOrValue(item)}
                                label={getTextHighlight(item.title)}
                                onClick={() => onItemClicked(item)}
                                description={getTextHighlight(item.description)}
                                active={isItemActive(item)}
                                disabled={item.disabled ?? false}
                                rightSection={item.right}
                                leftSection={item.left}
                            />
                        )
                    }
                </ScrollArea>

                <Flex justify="center">
                    { (page?.totalPages === 1 || !page) ? null : <Pagination
                        size="sm"
                        withEdges
                        total={page?.totalPages ?? 0}
                        onChange={onPageChanged}
                    />}
                </Flex>

                <Button disabled={!active} loading={attrs.button?.loading} onClick={doPickAndClose}>
                    { attrs.button?.text ?? 'Wybierz' }
                </Button>
            </Stack>
        </Modal>
    );
}

export default PickerDialog;
