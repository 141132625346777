// noinspection DuplicatedCode

import {Button, Modal, Stack} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useState} from "react";
import {DateInput, TimeInput} from "@mantine/dates";
import {CompositeReportApi} from "../../../api/composite/report/CompositeReportApi";
import {showNotification} from "@mantine/notifications";

export type GenerateForAllDevicesDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
}

function GenerateForAllDevicesDialog(attrs: GenerateForAllDevicesDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const startLoading = () => setLoading(true);

    const stopLoading = () => setLoading(false);

    const format = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
        const day = ('0' + date.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            dateMin: '',
            dateMax: '',
            timeMin: undefined,
            timeMax: undefined
        },

        validate: {
            // dateMin: (v) => v.length === 10 ? null : 'Niepoprawna data',
            // dateMax: (v) => v.length === 10 ? null : 'Niepoprawna data'
        }
    });

    const doSubmitError = () => {
        form.setFieldError('dateMin', 'Nie udało się wygenerować raportów...');

        showNotification({
            title: 'Nie udało się wygenerować raportów',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Wygenerowano raporty',
            message: 'Możesz teraz wyświetlić je na stronie \'Raporty\'',
            color: 'green'
        });
        attrs.closeAndUpdate();
    }

    const doSubmit = (values: {dateMin: string, dateMax: string, timeMin: string | undefined, timeMax: string | undefined}) => {
        startLoading();

        const dateMin = format(values.dateMin);
        const dateMax = format(values.dateMax);

        CompositeReportApi.generateForAllDevices({dateMin, dateMax, timeMin: values.timeMin, timeMax: values.timeMax})
            .then(doSubmitSuccess)
            .catch(doSubmitError)
            .then(stopLoading);
    }

    return (
        <Modal centered opened={attrs.isOpen} onClose={attrs.justClose} title="Generowanie raportu">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <DateInput
                        withAsterisk
                        valueFormat="DD-MM-YYYY"
                        label="Od"
                        placeholder="dd-mm-yyyy"
                        key={form.key('dateMin')}
                        { ...form.getInputProps('dateMin') }
                    />

                    <DateInput
                        withAsterisk
                        valueFormat="DD-MM-YYYY"
                        label="Do"
                        placeholder="dd-mm-yyyy"
                        key={form.key('dateMax')}
                        { ...form.getInputProps('dateMax') }
                    />

                    <TimeInput
                        label="Od"
                        placeholder="HH:mm"
                        key={form.key('timeMin')}
                        { ...form.getInputProps('timeMin') }
                    />

                    <TimeInput
                        label="Do"
                        placeholder="HH:mm"
                        key={form.key('timeMax')}
                        { ...form.getInputProps('timeMax') }
                    />

                    <Button type="submit" loading={loading}>
                        Wygeneruj
                    </Button>
                </Stack>
            </form>


        </Modal>
    );
}

export default GenerateForAllDevicesDialog;