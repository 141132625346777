import {CommentGroup} from "./NiceCommentsDialog";
import {Grid, NavLink, ScrollArea} from "@mantine/core";

export type ContentSectionAttrs = {
    preset: CommentGroup;
    addOrDelete: (s: string) => void;
    isPresent: (s: string) => boolean;
}

function ContentSection(attrs: ContentSectionAttrs) {
    return (
        <ScrollArea type="scroll" h="75vh" w="100%">
            <Grid w="100%" style={{paddingLeft: '10px'}}>
                {(attrs.preset?.comments ?? []).map(comment =>
                    <Grid.Col span={4}>
                        <NavLink
                            component="div"
                            active={attrs.isPresent(comment)}
                            style={{
                                borderRight: '1px solid rgba(150,150,150,.15)',
                                borderBottom: '1px solid rgba(150,150,150,.15)'
                            }}
                            label={comment}
                            onClick={() => attrs.addOrDelete(comment)}
                        />
                    </Grid.Col>
                )}
            </Grid>
        </ScrollArea>
    );
}

export default ContentSection;
