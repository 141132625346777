/* eslint-disable */
import {Chip} from "@mantine/core";
import {
    AddCommentPayload,
    CompositeReport,
    CompositeReportApi, DeleteCommentTextPayload
} from "../../../../../api/composite/report/CompositeReportApi";
import {useEffect, useState} from "react";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";

export type PresetItemAttrs = {
    preset: string;
    compositeReport?: CompositeReport;
    reload: () => void;
}

function PresetItem(attrs: PresetItemAttrs) {
    const [checked, setChecked] = useState(false);
    const [_, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const doAddPresetSuccess = () => {
        showNotification({
            title: 'Dodano komentarz',
            message: 'Wybrany komentarz został dodany do widocznego raportu',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doAddPresetError = () => {
        showNotification({
            title: 'Nie udało się dodać komentarza',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const doAddPreset = () => {
        const p: AddCommentPayload = {
            comment: attrs.preset,
            compositeReportId: attrs.compositeReport?.id!!
        }

        startLoading();

        CompositeReportApi.addComment(p)
            .then(doAddPresetSuccess)
            .then(attrs.reload)
            .catch(doAddPresetError)
            .finally(stopLoading);
    }

    const doDeletePresetSuccess = () => {
        showNotification({
            title: 'Usunięto',
            message: 'Komentarz został usunięty z raportu',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doDeletePresetError = () => {
        showNotification({
            title: 'Nie udało się usunąć komentarza',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doDeletePreset = () => {
        const p: DeleteCommentTextPayload = {
            compositeReportId: attrs.compositeReport?.id!!,
            comment: attrs.preset
        };

        startLoading();

        CompositeReportApi.deleteCommentText(p)
            .then(doDeletePresetSuccess)
            .then(attrs.reload)
            .catch(doDeletePresetError)
            .finally(stopLoading);
    }

    const isAdded = () => {
        return attrs.compositeReport?.comments?.includes(attrs.preset) ?? false;
    }

    const onClick = () => {
        if (!isAdded()) {
            doAddPreset();
            return;
        }

        doDeletePreset();
    }

    const init = () => {
        setChecked(isAdded());
    }

    useEffect(init, [attrs.compositeReport?.comments]);

    return (
        <Chip checked={checked} onClick={onClick}>
            {attrs.preset}
        </Chip>
    );
}

export default PresetItem;
