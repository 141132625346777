/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Modal, NumberInput, Stack} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useEffect, useState} from "react";
import {
    AddOrEditRecordBody,
    CompositeReportApi,
    GetRecordOrCreateQuery
} from "../../../api/composite/report/CompositeReportApi";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";

export type EditRecordByHourDialogPayload = {
    compositeReportId: string;
    dateReportId: string;
    hour: string;
}

export type EditRecordByHourDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
    payload: EditRecordByHourDialogPayload | undefined;
}

function EditRecordByHourDialog(attrs: EditRecordByHourDialogAttrs) {
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const form = useForm({
        mode: "uncontrolled",
        initialValues: { temp1: 0.0, temp2: 0.0 }
    });

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Zmieniono odczyt',
            message: 'Zaktualizowano odczyt z wybranej godziny.',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zmienić odczytu',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doSubmit = (values: any) => {
        const body: AddOrEditRecordBody = {
            compositeReportId: attrs.payload?.compositeReportId!!,
            dateReportId: attrs.payload?.dateReportId!!,
            hour: attrs.payload?.hour!!,
            temp1: 'temp1' in values ? values['temp1'] : undefined,
            temp2: 'temp2' in values ? values['temp2'] : undefined,
        };

        startLoading();

        CompositeReportApi.addOrEditRecord(body)
            .then(doSubmitSuccess)
            .then(attrs.closeAndUpdate)
            .catch(doSubmitError)
            .finally(stopLoading)
    }

    const initForm = (values: any) => {
        if (values === undefined) return;

        form.setValues({
            temp1: 'Temp1' in values ? values['Temp1'] : undefined,
            temp2: 'Temp2' in values ? values['Temp2'] : undefined
        });
    }

    const loadFormDataError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem nie ustąpi, skontaktuj się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const loadFormData = () => {
        if (!attrs.payload) return;

        startLoading();

        const query: GetRecordOrCreateQuery = { ...attrs.payload };

        CompositeReportApi.getRecordOrCreate(query)
            .then(initForm)
            .catch(loadFormDataError)
            .finally(stopLoading);
    }

    useEffect(loadFormData, [attrs.payload]);

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Edycja odczytu">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    { `Wybrana godzina: ${attrs.payload?.hour}` }

                    <NumberInput
                        label="T1"
                        description="Można zostawić puste"
                        step={0.1}
                        key={form.key('temp1')}
                        { ...form.getInputProps('temp1') }
                    />

                    <NumberInput
                        label="T2"
                        description="Można zostawić puste"
                        step={0.1}
                        key={form.key('temp2')}
                        { ...form.getInputProps('temp2') }
                    />

                    <Button type="submit" loading={loading}>
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    )
}

export default EditRecordByHourDialog;