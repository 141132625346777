import Page from "../../models/api/Page";
import {ChangeEvent, ChangeEventHandler} from "react";
import {Transition} from "@mantine/core";

export type PaginationFooterAttrs = {
    page?: Page<any>;
    limit?: number;
    setLimit: (n: number) => void;
    setPageNumber: (n: number) => void;
    pageNumber: number;
}

function PaginationFooter(attrs: PaginationFooterAttrs) {
    const onPageNumberChange: ChangeEventHandler = (ev: ChangeEvent<HTMLInputElement>) => {
        const v = (ev.target as HTMLInputElement).value
        attrs.setPageNumber(parseInt(v));
    }

    const onLimitChange: ChangeEventHandler = (ev: ChangeEvent<HTMLInputElement>) => {
        const v = (ev.target as HTMLInputElement).value
        attrs.setLimit(parseInt(v));
    }

    // noinspection DuplicatedCode
    return (
        <Transition mounted={attrs.page !== undefined}>
            { styles =>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', ...styles}}>
                    <div>
                        Wyświetlanie {attrs.page?.numberOfElements ?? ''} z {attrs.page?.totalElements ?? '-'}
                    </div>

                    <div>
                        <form>
                            Strona:
                            <select onChange={onPageNumberChange} defaultValue={attrs.pageNumber}
                                    style={{marginLeft: '5px', marginRight: '15px', minWidth: '50px'}}>
                                {
                                    [...Array(attrs.page?.totalPages ?? 0)].map((_, index) =>
                                        <option key={'SimpleTable-page-' + index} value={index}>
                                            {index + 1}
                                        </option>
                                    )
                                }
                            </select>

                            Elementów na stronie:
                            <select value={attrs.limit} onChange={onLimitChange} style={{marginLeft: '5px', marginRight: '5px', minWidth: '50px'}}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </form>
                    </div>
                </div>
            }
        </Transition>
    );
}

export default PaginationFooter;
