import {Client} from "../../../api/client/ClientApi";
import {Box, Button, Card, LoadingOverlay, Text} from "@mantine/core";
import React from "react";
import CardField from "../../cards/CardField";
import {useDisclosure} from "@mantine/hooks";
import ClientEditModal from "../../../pages/client/ClientEditModal";

function ClientCard(attrs: { reload: () => void; client: Client | undefined }) {
    const [isEditClientOpen, editClientDialog] = useDisclosure();

    const edit = () => {
        editClientDialog.open();
    }

    return (
        <>
            <Box pos="relative">
                <LoadingOverlay visible={!attrs.client}/>

                <Card withBorder pos="relative" radius="md">
                    <Card.Section p={'md'}>
                        <Text fw={500}>Dane klienta</Text>
                    </Card.Section>

                    <CardField title="Nazwa klienta" description={attrs.client?.visibleName ?? '-'}/>

                    <CardField title="Adres E-mail" description={attrs.client?.emailAddress ?? '-'}/>

                    <CardField title="Numer telefonu" description={attrs.client?.phoneNumber ?? '-'}/>

                    <Card.Section>
                        <Button variant="transparent" fullWidth onClick={edit}>
                            Edytuj
                        </Button>
                    </Card.Section>
                </Card>
            </Box>

            <ClientEditModal
                isOpen={isEditClientOpen}
                onClose={() => { editClientDialog.close(); attrs.reload(); }}
                justClose={editClientDialog.close}
                clientId={attrs.client?.id}
            />
        </>
    )
}

export default ClientCard;