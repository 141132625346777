/* eslint-disable react-hooks/exhaustive-deps */
import {useParams} from "react-router-dom";
import {Client, ClientApi} from "../../../api/client/ClientApi";
import React, {useEffect, useState} from "react";
import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {Box, LoadingOverlay, SimpleGrid} from "@mantine/core";
import ClientCard from "../../../components/client/cards/ClientCard";
import ClientDevicesCard from "../../../components/client/cards/ClientDevicesCard";
import {showNotification} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";

function ClientView() {
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState<Client>();
    const { clientId } = useParams();

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const reloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych klienta',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const reload = () => {
        if (!clientId) return;

        startLoading();

        ClientApi.getById(clientId)
            .then(setClient)
            .catch(reloadError)
            .finally(stopLoading);
    }

    useEffect(reload, [clientId]);

    return (
        <>
            <PageHeader
                title={`Informacje o kliencie ${client?.visibleName}`}
            />

            <PageContent>
                <Box pos="relative">
                    <LoadingOverlay visible={loading}/>

                    <SimpleGrid cols={{ base: 1, sm: 1, md: 2, lg: 3 }} spacing={{ base: 10, sm: 'xl' }} verticalSpacing={{ base: 'sm', sm: 'md' }}>
                        <ClientCard reload={reload} client={client}/>

                        <ClientDevicesCard client={client}/>
                    </SimpleGrid>
                </Box>
            </PageContent>
        </>
    );
}

export default ClientView;