import {ActionIcon, Menu} from "@mantine/core";
import {IconDotsVertical} from "@tabler/icons-react";

export type CompositeReportTableDetailsMenuAttrs = {
    editData: () => void,
    editComments: () => void,
    approveReport: () => void,
    deleteReport: () => void,
    sendReport: () => void;

    approveAndNext: () => void;

    isSuggestionMode: boolean;
    setSuggestionMode: (b: boolean) => void;
    saveSuggestion: () => void;
    toggleCards: () => void;
}

function CompositeReportExcelTableDetailsMenu(attrs: CompositeReportTableDetailsMenuAttrs) {
    const toggleSuggestion = () => attrs.setSuggestionMode(!attrs.isSuggestionMode);

    return (
        <Menu>
            <Menu.Target>
                <ActionIcon variant="transparent">
                    <IconDotsVertical size={32}/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Widok</Menu.Label>
                <Menu.Item onClick={attrs.toggleCards}>Przełącz karty</Menu.Item>

                <Menu.Label>Operacje</Menu.Label>
                <Menu.Item onClick={attrs.editData}>Edytuj dane</Menu.Item>
                <Menu.Item onClick={attrs.editComments}>Komentarze</Menu.Item>
                <Menu.Item onClick={attrs.approveReport}>Zatwierdź raport</Menu.Item>
                <Menu.Item onClick={attrs.sendReport}>Wyślij raport</Menu.Item>

                <Menu.Label>Sugestia</Menu.Label>
                <Menu.Item onClick={toggleSuggestion}>{`${attrs.isSuggestionMode ? 'Wyłącz' : 'Włącz'} tryb sugestii`}</Menu.Item>
                <Menu.Item onClick={attrs.saveSuggestion} disabled={!attrs.isSuggestionMode}>Zapisz sugestię</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

export default CompositeReportExcelTableDetailsMenu;
