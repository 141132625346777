import {ReactNode, useEffect, useState} from "react";
import UserApi, {User} from "../api/user/UserApi";
import AuthContext from "./AuthContext";
import {SessionStorage} from "../api/SessionStorage";
import {SelectedPageContext} from "./SelectedPageContext";
import WrapCompositeReportContext from "./WrapCompositeReportContext";
import WrapUsersContext from "./WrapUsersContext";

async function getUser(): Promise<User | null> {
    if (!SessionStorage.getToken()) return null;
    return (await UserApi.current());
}

function AppContext({children}: {children: ReactNode}) {
    const [user, setUser] = useState<User | null>();
    const [selectedPage, setSelectedPage] = useState<string | null>("/");

    const isUserLogged = () => {
        return (user !== undefined) && (user !== null) && (SessionStorage.getToken() !== undefined);
    }

    useEffect(() => {
        getUser().then(setUser);
    }, []);

    return (
        <AuthContext.Provider value={ { user, setUser, isUserLogged }}>
            { /* Holds list of users. To use in pickers and selects. */ }
            <WrapUsersContext>
                <WrapCompositeReportContext>
                    <SelectedPageContext.Provider value={ {selectedUrl: selectedPage, setSelectedUrl: setSelectedPage }}>
                        {children}
                    </SelectedPageContext.Provider>
                </WrapCompositeReportContext>
            </WrapUsersContext>
        </AuthContext.Provider>
    );
}

export default AppContext;
