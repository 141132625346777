/* eslint-disable */
import {SendReportApi, SendReportItem} from "../../../api/send/report/SendReportApi";
import {useForm} from "@mantine/form";
import {Button, Modal, Stack, TextInput} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {useEffect, useState} from "react";
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';

export type EditSendReportDialogAttrs = {
    isOpen: boolean;
    sendReport?: SendReportItem;
    justClose: () => void;
    closeAndUpdate: () => void;
}

function EditSendReportDialog(attrs: EditSendReportDialogAttrs) {
    const [loading, setLoading] = useState(false);
    const startLoading = () => { setLoading(true); };
    const stopLoading = () => { setLoading(false); };

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content: ''
    });

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            subject: '',
            content: '',
            emailAddress: ''
        }
    });

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Zapisano!',
            message: 'Zapisano!',
            color: 'green'
        });
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać ustawień',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSubmit = (values: any) => {
        startLoading();

        const changed = {
            id: attrs.sendReport?.id!!,
            ...attrs.sendReport,
            sendStatus: attrs.sendReport?.sendStatus,
            emailAddress: values.emailAddress,
            subject: values.subject === '' ? undefined : values.subject,
            content: editor?.isEmpty ? undefined : (editor?.getHTML() ?? ''),
        } as any;

        SendReportApi.saveItem(changed)
            .then(doSubmitSuccess)
            .then(attrs.closeAndUpdate)
            .catch(doSubmitError)
            .finally(stopLoading);
    }

    const reload = () => {
        form.setValues({
            emailAddress: attrs.sendReport?.emailAddress ?? '',
            subject: attrs.sendReport?.subject ?? '',
            content: attrs.sendReport?.content ?? ''
        });

        if (editor) {
            editor.commands.setContent(attrs.sendReport?.content ?? '');
        }
    };

    useEffect(reload, [attrs.sendReport, editor]);

    // noinspection DuplicatedCode
    return (
        <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Edytuj wiadomość do wysyłki" size="100%">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Adres E-mail odbiorcy"
                        key={form.key('emailAddress')}
                        { ...form.getInputProps('emailAddress') }
                    />

                    <TextInput
                        withAsterisk
                        label="Temat wiadomości"
                        description="Pozostaw puste, aby użyć wartości domyślnej"
                        key={form.key('subject')}
                        { ...form.getInputProps('subject') }
                    />

                    <RichTextEditor editor={editor} key={form.key('content')} { ...form.getInputProps('content') }>
                        <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.Bold />
                                <RichTextEditor.Italic />
                                <RichTextEditor.Underline />
                                <RichTextEditor.Strikethrough />
                                <RichTextEditor.ClearFormatting />
                                <RichTextEditor.Highlight />
                                <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.H1 />
                                <RichTextEditor.H2 />
                                <RichTextEditor.H3 />
                                <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.Blockquote />
                                <RichTextEditor.Hr />
                                <RichTextEditor.BulletList />
                                <RichTextEditor.OrderedList />
                                <RichTextEditor.Subscript />
                                <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.Link />
                                <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.AlignLeft />
                                <RichTextEditor.AlignCenter />
                                <RichTextEditor.AlignJustify />
                                <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.Undo />
                                <RichTextEditor.Redo />
                            </RichTextEditor.ControlsGroup>
                        </RichTextEditor.Toolbar>

                        <RichTextEditor.Content />
                    </RichTextEditor>

                    <Button type="submit" loading={loading}>
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
}

export default EditSendReportDialog;