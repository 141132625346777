// noinspection JSUnusedGlobalSymbols

import {PageRequest} from "../../models/api/PageRequest";
import Page from "../../models/api/Page";
import {HttpClient} from "../_http/HttpClient";
import {Device} from "../device/DeviceApi";
import {Action} from "../../models/api/Action";
import {Api} from "../Api";
import {SortedRequest} from "../../models/api/SortedRequest";

export type Client = {
    id: string;
    visibleName?: string;
    emailAddress?: string;
    phoneNumber?: string;
}

export type SearchQuery = { q?: string } & PageRequest & SortedRequest;

export type CreateQuery = {
    visibleName: string;
    emailAddress?: string;
    phoneNumber?: string;
};

export type SearchDevicesQuery = { clientId: string } & PageRequest;

export type EditQuery = {
    id: string;
    visibleName?: string;
    emailAddress?: string;
    phoneNumber?: string;
};

/**
 * @author Kacper Faber
 * @since 0.0.1-SNAPSHOT
 */
export class ClientApi {
    static async search(query: SearchQuery): Promise<Page<Client>> {
        const url = Api.getUrl("/client");
        return HttpClient.get<Page<Client>>(url, { ...query })
    }

    static async create(query: CreateQuery): Promise<any> {
        const url = Api.getUrl("/client/create")
        return HttpClient.post(url, {}, { ...query });
    }

    static async getDevices(query: SearchDevicesQuery): Promise<Page<Device>> {
        const url = Api.getUrl("/client/devices");
        return HttpClient.get<Page<Device>>(url, { ...query })
    }

    static async edit(query: EditQuery): Promise<any> {
        const url = Api.getUrl("/client/edit");
        return HttpClient.put(url, {}, { ...query });
    }

    static async action(action: Action): Promise<any> {
        const url = Api.getUrl("/client/action");
        return HttpClient.post(url, {}, { ...action });
    }

    static async getById(clientId: string): Promise<Client> {
        const url = Api.getUrl('/client/by-id');
        return HttpClient.get<Client>(url, { clientId });
    }
}
