import {Api} from "../../Api";
import {HttpClient} from "../../_http/HttpClient";

export class PdfConfigApi {
    static uploadFooter(file: File | null) {
        const url = Api.getUrl('/pdf/config/handle-footer-upload');
        const formData = new FormData();
        formData.append('file', file ?? new File([], '.jpeg'));
        return HttpClient.postFormData(url, { dest: 'right' }, formData);
    }

    static getFooterRightUrl(): string {
        return Api.getUrl('/pdf/config/get-footer-right');
    }
}