import "./CardItem.css";
import {Card, Group, Stack, Text} from "@mantine/core";
import React, {ReactNode} from "react";

export type CardItemAttrs = {
    title: string;
    description: string | ReactNode;
    onClick: () => void;
    compact?: boolean;
}

function CardItemCompactContent({attrs}: {attrs: CardItemAttrs}) {
    return (
        <>
            <Group justify="space-between" w="100%">
                <Text>
                    {attrs.title}
                </Text>

                <Text c="dimmed" size="sm">
                    {attrs.description}
                </Text>
            </Group>
        </>
    )
}

function CardItemNormalContent({attrs}: {attrs: CardItemAttrs}) {
    return (
        <>
            <Stack>
                <Text>
                    {attrs.title}
                </Text>

                <Text c="dimmed" size="sm">
                    {attrs.description}
                </Text>
            </Stack>
        </>
    )
}

function CardItem(attrs: CardItemAttrs) {
    return (
        <Card.Section withBorder inheritPadding p={attrs.compact ? '5px 10px' : 'xs'} className="card-item" onClick={attrs.onClick}>
            <Group justify="space-between">
                { attrs.compact ? <CardItemCompactContent attrs={attrs}/> : <CardItemNormalContent attrs={attrs}/> }
            </Group>
        </Card.Section>
    )
}

export default CardItem;