import {ReactNode} from "react";
import {Box, Card, LoadingOverlay, Text} from "@mantine/core";

export type CardWrapperAttrs = {
    loading: boolean;
    title?: string;
    children: ReactNode
};

function CardWrapperTitle({title}: {title?: string}) {
    if (!title) return null;

    return (
        <Card.Section p="md">
            <Text fw={500}>
                { title }
            </Text>
        </Card.Section>
    )
}

function CardWrapper(attrs: CardWrapperAttrs) {
    return (
        <Box pos="relative">
            <Card withBorder pos="relative" radius="md">
                <LoadingOverlay visible={attrs.loading}/>

                { /* This component renders title only, if title is not undefined. */ }
                <CardWrapperTitle
                    title={attrs.title}
                />

                { attrs.children }
            </Card>
        </Box>
    );
}

export default CardWrapper;