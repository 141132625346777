import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {DataTable, DataTableColumn, DataTableSortStatus, useDataTableColumns} from "mantine-datatable";
import {useEffect, useState} from "react";
import Page from "../../../models/api/Page";
import {ActionPayload, DeviceReport, DeviceReportApi, SearchQuery} from "../../../api/device/report/DeviceReportApi";
import {ActionIcon, Menu} from "@mantine/core";
import DateView from "../../../components/date/DateView";
import PaginationFooter from "../../../components/pagination/PaginationFooter";
import {modals} from "@mantine/modals";
import {useDisclosure, useSessionStorage} from "@mantine/hooks";
import {showNotification} from "@mantine/notifications";
import {useContextMenu} from "mantine-contextmenu";
import {IconEye, IconTrash} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import DeviceReportFilter from "./DeviceReportFilter";

const columns: DataTableColumn[] = [
    {
        accessor: 'id',
        title: '_id',
        toggleable: true,
        defaultToggle: false
    },

    {
        accessor: 'unit',
        title: '#',
        toggleable: true,
        sortable: true
    },

    {
        accessor: 'name',
        title: 'Nazwa',
        toggleable: true,
        sortable: true
    },

    {
        accessor: 'date',
        title: 'Data',
        toggleable: true,
        sortable: true,
        render: (record) => <DateView date={record.date as [number, number, number]}/>
    }
];

export type DeviceReportPageMenuAttrs = {
    deleteSelected: () => void;
    filterResults: () => void;
    generateReportForSelected: () => void;
}

function DeviceReportPageMenu(attrs: DeviceReportPageMenuAttrs) {

    return (
        <Menu width={200}>
            <Menu.Target>
                <ActionIcon variant="light">
                    <i className="fa fa-filter"></i>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Filtrowanie</Menu.Label>
                <Menu.Item onClick={attrs.filterResults}>Filtrowanie wyników</Menu.Item>

                <Menu.Label>Import</Menu.Label>
                <Menu.Item>Importuj raport</Menu.Item>

                <Menu.Label>Zaznaczone</Menu.Label>
                <Menu.Item onClick={attrs.generateReportForSelected}>Wygeneruj raport</Menu.Item>
                <Menu.Item color="red" onClick={attrs.deleteSelected}>Usuń zaznaczone</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

function DeviceReportPage() {
    const [selectedReports, setSelectedReports] = useState<DeviceReport[]>([]);
    const [searchRequest, setSearchRequest] = useState<SearchQuery>({limit: 50, page: 0});
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<Page<DeviceReport>>();
    const [isFiltersVisible, filtersVisible] = useDisclosure();
    const [sortStatus, setSortStatus] = useSessionStorage<DataTableSortStatus<DeviceReport>>({
        key: 'DeviceReport_SendStatus',
        defaultValue: {
            columnAccessor: 'date',
            direction: 'desc'
        }
    });
    const navigate = useNavigate();
    const { showContextMenu } = useContextMenu();

    const {effectiveColumns} = useDataTableColumns({
        key: 'DeviceReport-Table-Key', columns
    });

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem.',
            color: 'red'
        });
    }

    const reload = () => {
        setLoading(true);

        DeviceReportApi.search({...searchRequest, sortDir: sortStatus.direction, sortBy: sortStatus.columnAccessor})
            .then(setPage)
            .catch(onReloadError)
            .finally(() => setLoading(false));
    }

    useEffect(reload, [searchRequest, sortStatus]);

    const onDeleteSelectedError = () => {
        showNotification({
            title: 'Nie udało się usunąć raportów',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem.',
            color: 'red'
        });
    };

    const doDeleteSelected = () => {
        const selectedElements = selectedReports.map(x => x.id);

        setLoading(true);

        DeviceReportApi.performAction({selectedElements, action: 'delete'})
            .then(reload)
            .catch(onDeleteSelectedError)
            .finally(() => setLoading(false));
    }

    const generateReportForSelectedError = () => {
        showNotification({
            title: 'Nie udało się wygenerować raportów',
            message: 'Niestety, nie udało się wygenerować raportów. Jeśli problem nie ustąpi skontaktuj się z administratorem',
            color: 'red'
        });
    }

    const generateReportForSelectedSuccess = () => {
        showNotification({
            title: 'Wygenerowano raporty',
            message: 'Wygenerowano raporty dla zaznaczonych raportów urządzeń.',
            color: 'green'
        });
    }

    const doGenerateReportForSelected = () => {
        const selectedElements = selectedReports.map(x => x.id);
        setLoading(true);

        DeviceReportApi.performAction({selectedElements, action: 'create_report'})
            .then(generateReportForSelectedSuccess)
            .catch(generateReportForSelectedError)
            .finally(() => setLoading(false));
    }

    const generateReportForSelected = () => {
        modals.openConfirmModal({
            title: "Generowanie raportu",
            children: `Czy na pewno chcesz wygenerować raport z zaznaczonych elementów? Spowoduje to utworzenie raportu z zaznaczonych odczytów, posortowanych po dacie.`,
            labels: { confirm: `Tak, wygeneruj`, cancel: 'Anuluj' },
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: doGenerateReportForSelected
        });
    }

    const deleteSelected = () => {
        modals.openConfirmModal({
            title: "Usuwanie zaznaczonych",
            children: 'Czy na pewno chcesz usunąć zaznaczone raporty urządzeń. Nie spowoduje to usunięcia oryginalnych plików.',
            labels: { confirm: 'OK', cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: doDeleteSelected
        });
    }

    const filterResults = () => {
        filtersVisible.toggle();
    }

    const doDeleteSingleSuccess = () => {
        showNotification({
            title: 'Usunięto',
            message: 'Usunięto raport z urządzenia.'
        });
    }

    const doDeleteSingleError = () => {
        showNotification({
            title: 'Nie udało się usunąć raportu',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem.',
            color: 'red'
        });
    }

    const doDeleteSingle = (deviceReport: DeviceReport) => {
        const action: ActionPayload = { action: 'delete', selectedElements: [deviceReport.id!!] }

        setLoading(true);

        DeviceReportApi.performAction(action)
            .then(reload)
            .then(doDeleteSingleSuccess)
            .catch(doDeleteSingleError)
            .finally(() => setLoading(false));
    }

    const deleteSingle = (deviceReport: DeviceReport) => {
        modals.openConfirmModal({
            title: <>Usuwanie raportu z dnia <DateView date={deviceReport.date}/> </>,
            children: `Czy na pewno chcesz usunąć raport z urządzenia ${deviceReport.unit}?. Nie spowoduje to usunięcia oryginalnych plików.`,
            labels: { confirm: 'Tak, usuń', cancel: 'Nie, nie usuwaj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: () => doDeleteSingle(deviceReport)
        });
    }

    const setLimit = (l: number) => {
        setSearchRequest({...searchRequest, limit: l});
    }

    const setPageNum = (l: number) => {
        setSearchRequest({...searchRequest, page: l});
    }

    return (
        <>
            <PageHeader
                title="Raporty urządzeń"
                url="/device/report"
                action={<DeviceReportPageMenu
                    generateReportForSelected={generateReportForSelected}
                    filterResults={filterResults}
                    deleteSelected={deleteSelected}
                />}
            />

            <PageContent>
                <DeviceReportFilter
                    isOpen={isFiltersVisible}
                    searchQuery={searchRequest}
                    doUpdate={setSearchRequest}
                />

                <DataTable
                    mt="xl"
                    selectedRecords={selectedReports}
                    onSelectedRecordsChange={setSelectedReports}
                    highlightOnHover
                    striped
                    height="60vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    noRecordsText="Pusto..."
                    backgroundColor="transparent"
                    loaderBackgroundBlur={1}
                    minHeight={150}
                    fetching={loading}
                    columns={effectiveColumns}
                    records={page?.content}
                    storeColumnsKey="DeviceReport-Table-Key"
                    sortStatus={sortStatus}
                    onSortStatusChange={setSortStatus}
                    onRowContextMenu={(data) => {
                        showContextMenu([
                            {
                                key: 'preview-device-report-in-new-wnd',
                                title: 'Otwórz',
                                icon: <IconEye size={16}/>,
                                onClick: () => navigate(`/device/report/preview/${data.record.id}`)
                            },
                            {
                                key: 'delete-device-report-item',
                                color: 'red',
                                title: 'Usuń',
                                icon: <IconTrash size={16}/>,
                                onClick: () => deleteSingle(data.record)
                            }
                        ])(data.event)
                    }}
                />

                <PaginationFooter
                    page={page}
                    setLimit={setLimit}
                    setPageNumber={setPageNum}
                    pageNumber={searchRequest.page}
                    limit={50}
                />
            </PageContent>

            {/*<DeviceReportFilterDialog*/}
            {/*    isOpen={isFiltersVisible}*/}
            {/*    searchQuery={searchRequest}*/}
            {/*    setSearchQuery={setSearchRequest}*/}
            {/*    onClose={filtersVisible.close}*/}
            {/*/>*/}
        </>
    );
}

export default DeviceReportPage;
