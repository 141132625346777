import {DateReport} from "../../../../api/composite/report/CompositeReportApi";
import {Card, Group, Stack, Text} from "@mantine/core";
import DateView from "../../../../components/date/DateView";
import Hours from "./Hours";

type HourRowAttrs = {
    dateReport: DateReport;
    hour: string;
    isInvalid: (temp1: number | undefined, temp2: number | undefined) => boolean;
}

function HourRow(attrs: HourRowAttrs) {
    const getRecord = () => {
        // TODO: Optimize it, pre-generate map of records.
        return attrs.dateReport.records.map(x => x as any).filter(x => x['_original_time'] === attrs.hour)[0];
    }

    const isInvalid = () => {
        const temp1 = 'Temp1' in record ? record['Temp1'] : undefined;
        const temp2 = 'Temp2' in record ? record['Temp2'] : undefined;
        return attrs.isInvalid(temp1, temp2);
    }

    const record = getRecord();

    return (
        <Card.Section withBorder inheritPadding>
            <Group justify="center">
                <Text style={{ color: isInvalid() ? 'red' : '' }}>
                    { 'Temp1' in record ? record['Temp1'] : '-' } , { 'Temp2' in record ? record['Temp2'] : '-' }
                </Text>
            </Group>
        </Card.Section>
    )
}

export type DateReportDetailsBetterAttrs = {
    dateReport: DateReport;
    isInvalid: (temp1: number | undefined, temp2: number | undefined) => boolean;
}

function DateReportDetails(attrs: DateReportDetailsBetterAttrs) {
    return (
        <Stack style={{textAlign: 'center'}}>
            <Card.Section withBorder inheritPadding>
                <DateView date={attrs.dateReport.date as unknown as [number, number, number]}/>
            </Card.Section>

            { Hours.map(hour => <HourRow key={`${attrs.dateReport}-${hour}`} isInvalid={attrs.isInvalid} dateReport={attrs.dateReport} hour={hour}/>) }
        </Stack>
    )
}

export default DateReportDetails;