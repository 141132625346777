import {Group, Stack, Text, Transition} from "@mantine/core";
import {ReactNode} from "react";

export type CardPlaceholderAttrs = {
    visible: boolean;
    text?: string;
    icon?: ReactNode;
};

function CardPlaceholder(attrs: CardPlaceholderAttrs) {
    return (
        <Transition mounted={attrs.visible}>
            {styles=>
                <Group justify="center" style={styles}>
                    <Stack gap={0} align="center">
                        { attrs.icon ?? null }

                        { attrs.text ? <Text c="dimmed"> { attrs.text } </Text> : null }
                    </Stack>
                </Group>
            }
        </Transition>
    );
}

export default CardPlaceholder;