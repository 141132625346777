/* eslint-disable */
// noinspection JSUnusedLocalSymbols,DuplicatedCode

import {useEffect, useState} from "react";
import {
    Action, AddCommentPayload, AddOrEditRecordItem, AddOrEditRecordsBody,
    CompositeReport,
    CompositeReportApi, DateReport,
    SearchQuery
} from "../../../../api/composite/report/CompositeReportApi";
import PageContent from "../../../../components/layout/PageContent";
import PageHeader from "../../../../components/layout/PageHeader";
import {Device} from "../../../../api/device/DeviceApi";
import Page from "../../../../models/api/Page";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconCircleX, IconX} from "@tabler/icons-react";
import ExcelView from "../../../../components/composite/report/excel/ExcelView";
import CompositeReportWorkMenu from "./CompositeReportWorkMenu";
import CompositeReportWorkPlaceholder from "./CompositeReportWorkPlaceholder";
import CompositeReportWorkCards from "./CompositeReportWorkCards";
import CompositeReportWorkActionDialog from "./action-dialog/CompositeReportWorkActionDialog";
import {modals} from "@mantine/modals";

function CompositeReportWork() {
    const [page, setPage] = useState<Page<CompositeReport>>();
    const [report, setReport] = useState<CompositeReport>();
    const [device, setDevice] = useState<Device>();
    const [_, setLoading] = useState(false);
    const [isCardsVisible, setIsCardsVisible] = useState(false);
    const [isSuggestionMode, setSuggestionMode] = useState(false);
    const [suggestion, setSuggestion] = useState<CompositeReport>();

    const [, , toggleCards] = [
        () => setIsCardsVisible(true),
        () => setIsCardsVisible(false),
        () => setIsCardsVisible(!isCardsVisible)
    ];

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const loadData = (cr: CompositeReport) => {
        setReport(cr);
        setDevice(cr.devices[0]);
    }

    const reloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych (Listy).',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            icon: <IconCircleX/>,
            color: 'red'
        })
    }

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych (Szczegółów).',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            icon: <IconCircleX/>,
            color: 'red'
        })
    }

    const reloadReport = () => {
        const firstId = page?.content?.at(0)?.id;

        if (!page || page?.totalElements === 0 || !firstId) {
            setReport(undefined);
            return;
        }

        startLoading();

        CompositeReportApi.details(firstId)
            .then(loadData)
            .catch(reloadError)
            .finally(stopLoading);
    }

    const reload = () => {
        const query: SearchQuery = {sortBy: 'title', sortDir: 'desc', limit: 2, page: 0, approvedBy: '<NULL>'};

        startLoading();

        CompositeReportApi.search(query)
            .then(setPage)
            .catch(onReloadError)
            .finally(stopLoading);
    }

    const onApproveError = () => {
        showNotification({
            title: 'Nie udało się zatwierdzić raportu',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doApproveAndReturn = () => {
        const act: Action = {selectedElements: [report?.id!!], action: 'approve'};
        return CompositeReportApi.performAction(act)
    }

    const onApproveSuccess = () => {
        showNotification({
            title: 'Zatwierdzono raport',
            message: 'Przeniesiono do następnego niezatwierdzonego raportu.',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const approveAndNext = () => {
        startLoading();

        doApproveAndReturn()
            .then(reload)
            .then(onApproveSuccess)
            .catch(onApproveError)
            .finally(stopLoading);
    }

    const doAddCommentSuccess = () => {
        showNotification({
            title: 'Dodano komentarz',
            message: 'Komentarz został dodany pomyślnie.',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doAddCommentFailed = () => {
        showNotification({
            title: 'Nie udało się dodać komentarza',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doAddComment = (comment: string) => {
        const payload: AddCommentPayload = {
            compositeReportId: report?.id!!,
            comment: comment
        }

        CompositeReportApi.addComment(payload)
            .then(doAddCommentSuccess)
            .then(reloadReport)
            .catch(doAddCommentFailed);
    }

    const reloadSuggestionError = () => {
        showNotification({
            title: 'Nie udało się pobrać sugestii',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const reloadSuggestion = () => {
        if (!isSuggestionMode) {
            setSuggestion(undefined);
            return;
        }

        startLoading();

        CompositeReportApi.getSuggestion({compositeReportId: report?.id!!})
            .then(setSuggestion)
            .catch(reloadSuggestionError)
            .finally(stopLoading);
    }

    const mapDateReportsToItems = (dateReports: DateReport[]) => {
        let items: AddOrEditRecordItem[] = [];

        for (const dateReport of dateReports) {
            for (const record of dateReport.records as any[]) {
                const item: AddOrEditRecordItem = {
                    dateReportId: dateReport.id,
                    hour: record["_original_time"],
                    temp1: record["Temp1"],
                    temp2: record["Temp2"]
                }

                items = [...items, item];
            }
        }

        return items;
    }

    const onSaveSuggestionSuccess = () => {
        showNotification({
            title: 'Zapisano!',
            message: 'Zapisano sugestie',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doSaveSuggestion = () => {
        if (!isSuggestionMode || !suggestion) {
            showNotification({
                title: 'Aby zapisać, musisz być w trybie sugestii.',
                message: 'Jeśli uważasz, że to błąd - proszę skontaktować się z administratorem',
                color: 'red',
                icon: <IconX/>
            });

            return;
        }

        const dateReports = suggestion?.sections?.flatMap(x => x.content) ?? [];

        const payload: AddOrEditRecordsBody = {
            compositeReportId: report?.id!!,
            items: mapDateReportsToItems(dateReports)
        }

        CompositeReportApi.addOrEditRecords(payload)
            .then(onSaveSuggestionSuccess)
            .then(() => setSuggestionMode(false))
            .then(reloadReport)
            .catch(e => { throw e });
    }

    const saveSuggestion = () => {
        modals.openConfirmModal({
            title: "Sugestia",
            children: `Czy na pewno chcesz zapisać tą sugestię? Spowoduje to wczytanie wszystkich sugerowanych (zielonych) pól.?`,
            labels: { confirm: `Tak, zapisz.`, cancel: 'Anuluj' },
            confirmProps: {color: 'yellow'},
            centered: true,
            onConfirm: doSaveSuggestion
        });
    }

    useEffect(reloadSuggestion, [isSuggestionMode, report]);

    useEffect(reload, []);

    useEffect(reloadReport, [page])

    const notImpl = () => {
        throw new Error('Not implemented!');
    }

    return (
        <>
            <PageHeader
                title={page?.totalElements !== 0 ? `Raport ${report?.title ?? '...'} i ${page?.totalElements ?? '...'} innych do zatwierdzenia` : 'Wszystko zrobione!'}
                pageTitle="Praca..."
                url="/composite/report/work"
                action={<CompositeReportWorkMenu saveSuggestion={saveSuggestion} isSuggestionMode={isSuggestionMode} setSuggestionMode={setSuggestionMode} toggleCards={toggleCards} approveAndNext={approveAndNext}/>}
            />

            <PageContent>
                <CompositeReportWorkPlaceholder
                    visible={page?.totalElements === 0}
                />

                <CompositeReportWorkCards
                    isVisible={isCardsVisible && page?.totalElements !== 0}
                    report={report}
                    reloadReport={reloadReport}
                />

                <ExcelView
                    reload={reloadReport}
                    compositeReport={isSuggestionMode ? suggestion : report}
                    device={device}
                    disableEditing={isSuggestionMode}
                />
            </PageContent>

            <CompositeReportWorkActionDialog
                isOpen={true}
                justClose={notImpl}
                closeAndUpdate={notImpl}
                doAddComment={doAddComment}
                doApprove={approveAndNext}
            />
        </>
    )
}

export default CompositeReportWork;