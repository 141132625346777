import dayjs from "dayjs";

export type DateTimeViewAttrs = {
    dateTime?: any;
}

function DateTimeView({dateTime}: DateTimeViewAttrs) {
    if (!dateTime) {
        return (
            <>
                -
            </>
        )
    }

    const formatValue = (v: number) => {
        return v < 10 ? '0' + v : v;
    }

    const year = dateTime[0];
    const month = dateTime[1];
    const day = dateTime[2];
    const hour = dateTime[3];
    const min = dateTime[4];
    const date = dayjs({year, month, day, hour, minute: min}).add(2, 'hour');

    return (
        <>
            { `${date.year()}-${formatValue(date.month())}-${formatValue(date.date())}, ${formatValue(date.hour())}:${formatValue(date.minute())}` }
        </>
    );
}

export default DateTimeView;
