import {useState} from "react";
import {Button, Modal, Stack, TextInput} from "@mantine/core";

export type UserFilterDialogAttrs = {
    q?: string;
    setQ: (s?: string) => void;

    onClose: () => void;
    isOpen: boolean;
}

function UserFilterDialog(attrs: UserFilterDialogAttrs) {
    const [q, setQ] = useState(attrs.q);

    const submit = () => {
        attrs.setQ(q);
        attrs.onClose();
    }

    return (
        <Modal opened={attrs.isOpen} onClose={attrs.onClose} title="Filtrowanie">
            <Stack>
                <TextInput
                    label="Zapytanie"
                    placeholder={"..."}
                    variant="filled"
                    defaultValue={q}
                    onChange={ev => setQ(ev.target.value)}
                />

                <Button onClick={submit}>Szukaj...</Button>
            </Stack>
        </Modal>
    );
}

export default UserFilterDialog;
