import React from "react";

export type HighlightProperties = {
    text?: string;
    searchText?: string;
    backgroundColor?: string;
    color?: string;
}

const defaultColor = '';
const defaultBackgroundColor = 'yellow';

/**
 * Highlights searchText in given text
 * @param props Properties
 */
function highlight(props: HighlightProperties) {
    const { text, searchText } = props;

    if (!text || !searchText || searchText === '') return text;

    const parts = text.split(new RegExp(`(${searchText})`, 'gi'));

    return parts.map((part, index) => {
        if (part.toLowerCase() === searchText.toLowerCase()) {
            return (
                <span
                    key={index}
                    style={{
                        backgroundColor: props.backgroundColor ?? defaultBackgroundColor,
                        color: props.color ?? defaultColor
                    }}
                >
                    {part}
                </span>
            );
        }
        else return part;
    });
}

export default highlight;
