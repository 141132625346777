import {ActionIcon, Menu} from "@mantine/core";
import {IconDotsVertical} from "@tabler/icons-react";

export type CalendarEventMenuAttrs = {
    createEvent: () => void;
    deleteSelected: () => void;
    filterResults: () => void;
}

function CalendarEventMenu(attrs: CalendarEventMenuAttrs) {
    return (
        <Menu width={250}>
            <Menu.Target>
                <ActionIcon variant="transparent">
                    <IconDotsVertical/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Filtrowanie</Menu.Label>
                <Menu.Item onClick={attrs.filterResults} children="Filtrowanie"/>

                <Menu.Label>Zarządzanie</Menu.Label>
                <Menu.Item onClick={attrs.createEvent} children="Utwórz"/>

                { /* Delete selected */ }
                <Menu.Item
                    color="red"
                    children="Usuń zaznaczone"
                    onClick={attrs.deleteSelected}
                />
            </Menu.Dropdown>
        </Menu>
    )
}

export default CalendarEventMenu;