import {ReactNode} from "react";

function PageContent({children}: {children: ReactNode}) {
    return (
        <div id="app_router_outlet">
            {children}
        </div>
    );
}

export default PageContent;
