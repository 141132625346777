import AuthenticationApi from "../../api/auth/AuthenticationApi";
import {SessionStorage} from "../../api/SessionStorage";
import UserApi, {User} from "../../api/user/UserApi";

class AuthenticateService {
    static async authenticate(username: string, password: string): Promise<User | null> {
        return AuthenticationApi.authenticate({username, password})
            .then(result => SessionStorage.setToken(result.accessToken))
            .then(() => UserApi.current());
    }

    static logout() {
        SessionStorage.setToken(null);
    }
}

export default AuthenticateService;