import {createContext} from "react";
import {User} from "../api/user/UserApi";

export type AuthContextType = {
    user?: User | null;
    setUser?: (u: User | null) => void;
    isUserLogged: () => boolean;
}

const AuthContext = createContext<AuthContextType>({
    isUserLogged: () => {throw new Error('AuthContext.isUserLogged() is not initialized.');}
});

export default AuthContext;