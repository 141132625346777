/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Card, Group, Stack, Text} from "@mantine/core";
import {Device} from "../../../api/device/DeviceApi";
import React from "react";
import {useNavigate} from "react-router-dom";
import CardPlaceholder from "../../../pages/workspace/work-panel/cards/CardPlaceholder";
import {IconX} from "@tabler/icons-react";
import {Client} from "../../../api/client/ClientApi";
import CardWrapper from "../../../pages/workspace/work-panel/cards/CardWrapper";

function Field(attrs: { title: string, description: string }) {
    return (
        <Card.Section withBorder inheritPadding p='md'>
            <Group justify="space-between">
                <Stack gap={0}>
                    <Text>
                        {attrs.title}
                    </Text>

                    <Text c="dimmed" size="sm">
                        {attrs.description}
                    </Text>
                </Stack>
            </Group>
        </Card.Section>
    );
}

function ClientCardPlaceholder({client}: { client: Client | undefined }) {
    return (
        <CardPlaceholder
            visible={client === undefined || client == null}
            text="To urządzenie nie ma klienta"
            icon={<IconX/>}
        />
    );
}

function ClientCard(attrs: { device?: Device }) {
    const nav = useNavigate();

    const showMore = () => {
        nav(`/client/view/${attrs.device?.client?.id}`);
    }

    return (
        <CardWrapper title="Informacje o kliencie" loading={attrs.device === undefined }>
            <ClientCardPlaceholder client={attrs.device?.client}/>

            {!attrs?.device?.client ? null :
                <>
                    <Card.Section p={'md'}>
                        <Text fw={500}>Dane klienta</Text>
                    </Card.Section>

                    <Field title="Nazwa klienta" description={attrs.device.client?.visibleName ?? '-'}/>

                    <Field title="Adres E-mail" description={attrs.device.client?.emailAddress ?? '-'}/>

                    <Field title="Numer telefonu" description={attrs.device.client?.phoneNumber ?? '-'}/>

                    <Card.Section>
                        <Button variant="transparent" fullWidth onClick={showMore}>
                            Pokaż więcej
                        </Button>
                    </Card.Section>
                </>
            }
        </CardWrapper>
)
    ;
}

export default ClientCard;