import PageHeader from "../components/layout/PageHeader";
import PageContent from "../components/layout/PageContent";
import {SimpleGrid, Transition} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import "./Index.css";
import {useEffect, useState} from "react";
import {Stats, StatsApi} from "../api/stats/StatsApi";
import {showNotification} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";
import Page from "../models/api/Page";
import {CompositeReport, CompositeReportApi, SearchQuery} from "../api/composite/report/CompositeReportApi";

type IndexCardAttrs = {
    onClick?: () => void;
    title: string;
    description: string;
    visible: boolean
}

function IndexCard(attrs: IndexCardAttrs) {
    const [d, setD] = useState(250);

    useEffect(() => { setD(Math.random() * 750); }, []);

    return (
        <Transition mounted={attrs.visible} transition="skew-up" duration={d}>
            {styles =>

                <div style={{...styles, cursor: 'pointer'}} onClick={attrs.onClick}>
                    <div className="small-box" style={{backgroundColor: 'rgb(20 35 117)', color: '#fff!important'}}>
                        <div className="inner">
                            <h3 style={{color: 'white', fontSize: '2.3rem!important'}}>
                                {attrs.title}
                            </h3>
                            <p style={{color: 'white'}}> {attrs.description} </p>
                        </div>
                    </div>
                </div>
            }
        </Transition>
    );
}

function IndexPage() {
    const [stats, setStats] = useState<Stats>();
    const [reports, setReports] = useState<Page<CompositeReport>>();
    const nav = useNavigate();

    const reloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            icon: <IconX/>,
            color: 'red'
        });
    }

    const reload = () => {
        StatsApi.getStats()
            .then(setStats)
            .catch(reloadError);

        const searchQuery: SearchQuery = {
            approvedBy: '<NULL>',
            page: 0,
            limit: 1,
            sortBy: 'title',
            sortDir: 'desc'
        }

        CompositeReportApi.search(searchQuery)
            .then(setReports)
            .catch()
            .finally();
    }

    useEffect(reload, []);

    const generateReports = () => nav("/composite/report");

    const goToWork = () => nav("/composite/report/work");

    return (
        <>
            <PageHeader
                title="TermoControl"
                url="/"
            />

            <PageContent>
                <SimpleGrid
                    mb="xl"
                    cols={{base: 1, sm: 1, md: 2, lg: 3}}
                    spacing={{base: 10, sm: 'xl'}}
                    verticalSpacing={{base: 'sm', sm: 'md'}}
                >
                    <IndexCard
                        title="Generuj raport"
                        description="Dla zapisanych urządzeń"
                        visible={stats !== undefined}
                        onClick={generateReports}
                    />

                    <IndexCard
                        title="Kontynuuj pracę"
                        description={`${reports ? `${reports.totalElements}` : '??'} zostało do zatwierdzenia`}
                        visible={reports !== undefined}
                        onClick={goToWork}
                    />
                </SimpleGrid>

                <SimpleGrid
                    mt="xl"
                    cols={{base: 1, sm: 1, md: 2, lg: 3}}
                    spacing={{base: 10, sm: 'xl'}}
                    verticalSpacing={{base: 'sm', sm: 'md'}}
                >
                    <IndexCard
                        title={ `${stats?.totalCompositeReports ?? '-'}` }
                        description="Wygenerowanych raportów"
                        visible={stats !== undefined}
                        onClick={() => nav('/composite/report')}
                    />

                    <IndexCard
                        title={ `${stats?.totalDevices ?? '-'}` }
                        description="Obsługiwanych urządzeń"
                        visible={stats !== undefined}
                        onClick={() => nav('/device')}
                    />

                    <IndexCard
                        title={ `${stats?.totalClients  ?? '-'}` }
                        description="Obsługiwanych klientów"
                        visible={stats !== undefined}
                        onClick={() => nav('/client')}
                    />

                    <IndexCard
                        title={ `${stats?.totalDeviceReports  ?? '-'}` }
                        description="Odczytanych raportów"
                        visible={stats !== undefined}
                        onClick={() => nav('/device/report')}
                    />

                    <IndexCard
                        title={ `${stats?.totalUsers  ?? '-'}` }
                        visible={stats !== undefined}
                        description="Użytkowników"
                        onClick={() => nav('/user')}
                    />

                    <IndexCard
                        title={ `${stats?.totalKnownFiles  ?? '-'}` }
                        visible={stats !== undefined}
                        description="Zsynchronizowanych plików"
                        onClick={() => nav('/')}
                    />

                    <IndexCard
                        title={reports ? `${reports.totalElements}` : '???'}
                        description="raportów pozostało do zatwierdzenia"
                        visible={reports !== undefined}
                        onClick={goToWork}
                    />
                </SimpleGrid>
            </PageContent>
        </>
    );
}

export default IndexPage;
