/* eslint-disable react-hooks/exhaustive-deps */
import PageHeader from "../../../../components/layout/PageHeader";
import PageContent from "../../../../components/layout/PageContent";
import {useEffect, useState} from "react";
import {CompositeReport, CompositeReportApi} from "../../../../api/composite/report/CompositeReportApi";
import {useParams} from "react-router-dom";
import {showNotification} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";
import {Stack} from "@mantine/core";
import SectionDetails from "./SectionDetails";
import {Device, DeviceApi} from "../../../../api/device/DeviceApi";

function CompositeReportViewDetails() {
    // const [loading, setLoading] = useState(false);
    const [report, setReport] = useState<CompositeReport>();
    const [device, setDevice] = useState<Device>();
    const { compositeReportId } = useParams();
    //
    // const [startLoading, stopLoading] = [
    //     () => setLoading(true),
    //     () => setLoading(false)
    // ];

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem nie ustąpi, proszę skontaktuj się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const reloadDevice = (compositeReport: CompositeReport) => {
        DeviceApi.search({q: compositeReport.unit, limit: 1, page: 0})
            .then((page) => setDevice(page?.content?.at(0)))
            .catch(() => alert("RELOAD DEVICE"));

        return compositeReport;
    }

    const reload = () => {
        if (!compositeReportId) return;

        // startLoading();

        CompositeReportApi.details(compositeReportId)
            .then(reloadDevice)
            .then(setReport)
            .catch(onReloadError)
            // .finally(stopLoading);
    }

    useEffect(reload, [compositeReportId]);

    const isInvalid = (temp1: number | undefined, temp2: number | undefined) => {
        if (!device) return false;

        const [min, max] = [device.minAllowableTemp, device.maxAllowableTemp];

        // Missing temp1 or temp2
        if (temp1 === undefined || temp2 === undefined) { return true; }

        if (min !== undefined) {
            if (temp1 < min || temp2 < min) {
                return true;
            }
        }

        if (max !== undefined) {
            if (temp1 > max || temp2 > max) {
                return true;
            }
        }

        return false;
    }

    return (
        <>
            <PageHeader
                title="Szczegóły raportu"
            />

            <PageContent>
                <Stack>
                    { report?.sections?.map(section => <SectionDetails isInvalid={isInvalid} section={section} />) }
                </Stack>
            </PageContent>
        </>
    )
}

export default CompositeReportViewDetails;