/* eslint-disable react-hooks/exhaustive-deps */
import {SearchQuery} from "../../../api/composite/report/CompositeReportApi";
import {useForm} from "@mantine/form";
import {Button, Flex, Select, TextInput, Transition} from "@mantine/core";
import {DatePickerInput} from "@mantine/dates";
import {useContext, useEffect, useState} from "react";
import {UsersContext} from "../../../contexts/WrapUsersContext";

export type CompositeReportFilterAttrs = {
    searchQuery: SearchQuery;
    doUpdate: (sq: SearchQuery) => void;
    isOpen: boolean;
}

type UserDataItem = {label: string, value: string};

const skipValue: string = '<SKIP>';
const nullValue: string = '<NULL>';

function CompositeReportFilter(attrs: CompositeReportFilterAttrs) {
    const usersContext = useContext(UsersContext);
    const [usersData, setUsersData] = useState<UserDataItem[]>();
    const [createdAtRange, setCreatedAtRange] = useState<[Date | null, Date | null]>([null, null]);

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { q: '', unit: '', createdBy: skipValue, approvedBy: skipValue, includeArchived: 'false' },
        validate: {
            q: e => {
                if (e === '') return null;
                return e.length < 3 ? 'Przynajmniej 3 znaki' : null;
            },

            unit: e => {
                if (e === '') return null;
                return e.length < 2 ? 'Przynajmniej dwa znaki' : null;
            }
        }
    });

    const reloadUsersData = () => {
        const usersMapped = usersContext.users?.map(user => ({label: user.username, value: user.id}));

        setUsersData([
            ...usersMapped ?? [],
            { label: '<Brak>', value: nullValue }
        ]);
    }

    // noinspection DuplicatedCode
    function formatDate(date: Date|null) {
        if (!date) { return undefined}
        const year = date.getFullYear();
        let month: string|number = date.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        let day: string|number = date.getDate();
        if (day < 10) {
            day = '0' + day;
        }
        return `${year}-${month}-${day}`;
    }

    const doSubmit = (values: any) => {
        // const qToSet = values.q === '' ? undefined : values.q;
        const unitToSet = values.unit === '' ? undefined : values.unit;

        attrs.doUpdate({
            ...attrs.searchQuery,
            q: values.q,
            unit: unitToSet,
            createdAtMax: formatDate(createdAtRange[1]),
            createdAtMin: formatDate(createdAtRange[0]),
            createdBy: values.createdBy ?? skipValue,
            approvedBy: values.approvedBy ?? skipValue,
            includeArchived: values.includeArchived === 'true'
        });
    }

    const reloadForm = () => {
        form.setValues({
            q: attrs.searchQuery.q ?? '',
            unit: attrs.searchQuery.unit ?? ''
        });
    }

    useEffect(reloadForm, []);

    useEffect(reloadUsersData, [usersContext.users]);

    return (
        <Transition mounted={attrs.isOpen} transition={'pop'}>
            {(styles) =>
                <form onSubmit={form.onSubmit(doSubmit)} style={styles}>
                    <Flex direction="row" align="center" gap={'md'}>
                        <TextInput
                            placeholder="Zapytanie"
                            key={form.key('q')}
                            {...form.getInputProps('q')}
                        />

                        <TextInput
                            placeholder="Jednostka"
                            key={form.key('unit')}
                            {...form.getInputProps('unit')}
                        />

                        <Select
                            placeholder="Utworzone przez"
                            data={usersData}
                            key={form.key('createdBy')}
                            { ...form.getInputProps('createdBy') }
                        />

                        <Select
                            placeholder="Zatwierdzone przez"
                            data={usersData}
                            key={form.key('approvedBy')}
                            { ...form.getInputProps('approvedBy') }
                        />

                        <DatePickerInput
                            type="range"
                            value={createdAtRange}
                            onChange={setCreatedAtRange}
                            placeholder="Data wygenerowania"
                        />

                        <Select
                            placeholder="Archiwum"
                            data={[{label: 'Uwzględnij archiwum', value: 'true'}]}
                            key={form.key('includeArchived')}
                            { ...form.getInputProps('includeArchived') }
                        />

                        <Button type="submit">
                            Szukaj
                        </Button>
                    </Flex>
                </form>
            }
        </Transition>
    );
}

export default CompositeReportFilter;
