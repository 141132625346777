import {ReactNode} from "react";
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import '@mantine/core/styles.css';
import '@mantine/core/styles.layer.css';
import 'mantine-datatable/styles.css';
import 'mantine-contextmenu/styles.layer.css';
import 'mantine-contextmenu/styles.css';
import '@mantine/notifications/styles.css';
import {ModalsProvider} from "@mantine/modals";
import {ContextMenuProvider} from "mantine-contextmenu";

function EntireMantineContext({children}: {children: ReactNode}) {
    return (
        <MantineProvider>
            <Notifications/>
            <ModalsProvider>
                <ContextMenuProvider>
                    { children }
                </ContextMenuProvider>
            </ModalsProvider>
        </MantineProvider>
    )
}

export default EntireMantineContext;
