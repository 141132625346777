/* eslint-disable react-hooks/exhaustive-deps */
import {CompositeReport, CompositeReportApi} from "../../../api/composite/report/CompositeReportApi";
import {Button, Card, Center, Flex, Space, Text} from "@mantine/core";
import {Device} from "../../../api/device/DeviceApi";
import React, {useEffect, useState} from "react";
import Page from "../../../models/api/Page";
import {showNotification} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";
import CardItem from "../../../pages/workspace/work-panel/cards/CardItem";
import {useNavigate} from "react-router-dom";
import CardWrapper from "../../../pages/workspace/work-panel/cards/CardWrapper";

function Fallback() {
    return (
        <Card.Section>
            <Center>
                <Flex direction={"column"}>
                    <Text c="dimmed" size="lg">Pusto...</Text>
                    <Text c="dimmed" size="sm">Kiedyś zobaczysz tu najnowsze raporty...</Text>
                </Flex>
            </Center>
        </Card.Section>
    );
}

function LastReportItem(attrs: {report: CompositeReport}) {
    const nav = useNavigate();

    const navToDetails = () => {
        nav(`/composite/report/excel-details/${attrs.report.id}`);
    }

    return (
        <CardItem
            compact
            title={attrs.report.title ?? '<Raport bez tytułu>'}
            description={attrs.report.description}
            onClick={navToDetails}
        />
    );
}

function LastReportsCard(attrs: {device?: Device}) {
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState<Page<CompositeReport>>();

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const reloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać ostatnich raportów',
            message: 'Jeśli problem będzie się utrzymywał, poproś o pomoc administratora',
            icon: <IconX/>,
            color: 'red'
        });
    }

    const reload = () => {
        if (!attrs.device?.unit) {
            return; // Avoid reload without unit criteria.
        }

        startLoading();

        const searchQuery = {
            sortBy: 'createdAt',
            sortDir: 'desc',
            limit: 5,
            page: 0,
            unit: attrs.device?.unit
        };

        CompositeReportApi.search(searchQuery)
            .then(setReports)
            .catch(reloadError)
            .finally(stopLoading);
    }

    useEffect(reload, [attrs.device, attrs.device?.id, attrs.device?.unit]);

    if (!attrs.device) return null;

    return (
        <CardWrapper title="Ostatnie raporty" loading={loading}>
            { reports?.content?.map(report => <LastReportItem key={report.id} report={report}/>) }

            { reports?.content?.length === 0 ? <Fallback/> : null }

            <Space/>

            { reports?.totalElements === 0 ? null :
                <Card.Section>
                    <Button variant="transparent" fullWidth>
                        Pokaż więcej
                    </Button>
                </Card.Section>
            }
        </CardWrapper>
    );
}

export default LastReportsCard;

// TODO: LastReportsCard
//  1. "Show more" is not implemented - should redirect to page with all composite reports for this unit are placed.