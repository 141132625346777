import PickerDialog, {Item} from "../../components/picker-dialog/PickerDialog";
import {Client, ClientApi} from "../../api/client/ClientApi";
import Page from "../../models/api/Page";
import {Device, DeviceApi} from "../../api/device/DeviceApi";
import {showNotification} from "@mantine/notifications";
import {IconChevronRight, IconXd} from "@tabler/icons-react";

export type AssignClientDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
    device?: Device;
}

function AssignClientDialog(attrs: AssignClientDialogAttrs) {
    // const [loading, setLoading] = useState(false);
    //
    // const startLoading = () => {
    //     setLoading(true);
    // }
    //
    // const stopLoading = () => {
    //     setLoading(false);
    // }

    const nullItem: Item = {
        value: 'null',
        title: "<Wyczyść przypisanie>",
        description: 'Nie przypisuj tego urządzenia do żadnego klienta',
        key: 'client-assignToNull-in-AssignClientDialog'
    }

    const loadData = (q: string, page: number, limit: number) => {
        return ClientApi.search({q, limit, page});
    }

    const mapData = (page: Page<any>) => {
        if (!page?.content) return [];

        const mappedItems = page.content.map(client => {
            return {
                value: client.id,
                title: client.visibleName,
                description: `${client.emailAddress ?? '<Brak adresu E-mail>'}`,
                key: `client-${client.id}-in-AssignClientDialog`,
                right: <IconChevronRight size="0.8rem" stroke={1.5} className="mantine-rotate-rtl"/>
            } as Item
        });

        return [nullItem, ...mappedItems];
    }

    const doAssignToClient = (client: Client) => {
        return DeviceApi.edit({ ...attrs.device!!, clientId: client.id!! })
    }

    const doAssignNull = () => {
        return DeviceApi.edit( { ...attrs.device!!, clientId: undefined });
    }

    const pickAndCloseSuccess = () => {}

    const pickAndCloseError = () => {
        showNotification({
            title: 'Nie udało się przypisać',
            message: '',
            color: 'red',
            icon: <IconXd size={16}/>
        });
    }

    const pickAndClose = (item: Item) => {
        // startLoading();

        if (item.value === nullItem.value) {
            doAssignNull()
                .then(attrs.closeAndUpdate)
                .then(pickAndCloseSuccess)
                .catch(pickAndCloseError);

            return;
        }

        ClientApi.getById(item.value)
            .then(doAssignToClient)
            .then(attrs.closeAndUpdate)
            .then(pickAndCloseSuccess)
            .catch(pickAndCloseError)
            // .finally(stopLoading);
    }

    return (
        <PickerDialog
            isOpen={attrs.isOpen}
            justClose={attrs.justClose}
            pickAndClose={pickAndClose}
            loadData={loadData}
            mapData={mapData}
            title="Przypisz klienta"
            button={{ text: 'Przypisz' }}
            page={{limit: 5}}
        />
    );
}

export default AssignClientDialog;
