/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Flex, TextInput, Transition} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useEffect} from "react";

export type DeviceFilterAttrs = {
    isOpen: boolean;
    query: string;
    doUpdate: (query: string) => void;
}

function DeviceFilter(attrs: DeviceFilterAttrs) {
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { query: '' }
    });

    const reload = () => {
        form.setValues({
            query: attrs.query
        });
    }

    const doSubmit = (values: any) => {
        attrs.doUpdate(values.query);
    }

    useEffect(reload, [attrs.query]);

    return (
        <Transition mounted={attrs.isOpen} transition="pop">
            { styles =>

                <form onSubmit={form.onSubmit(doSubmit)} style={styles}>
                    <Flex direction="row" align="center" gap={'md'}>
                        <TextInput
                            placeholder="Zapytanie"
                            key={form.key('query')}
                            {...form.getInputProps('query')}
                        />

                        <Button type="submit">
                            Szukaj
                        </Button>
                    </Flex>
                </form>
            }
        </Transition>
    );
}

export default DeviceFilter;
