import {Client, ClientApi} from "../../api/client/ClientApi";
import PickerDialog, {Item} from "../../components/picker-dialog/PickerDialog";
import Page from "../../models/api/Page";
import {Device} from "../../api/device/DeviceApi";
import {useNavigate} from "react-router-dom";

export type BrowseClientDevicesDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    client: Client | undefined;
}

function BrowseClientDevicesDialog(attrs: BrowseClientDevicesDialogAttrs) {
    const navigate = useNavigate();

    const loadData = (_query: string, page: number, limit: number) => {
        if (!attrs.client) return new Promise<Page<any>>((resolve) => resolve({ } as Page<any>));
        return ClientApi.getDevices({ clientId: attrs.client.id!!, page, limit})
    }

    const mapDeviceToItem = (dev: any) => {
        const device = dev satisfies Device;

        return {
            key: `device-${device.id}-in-BrowseClientDevicesDialog`,
            value: device.id,
            title: `${device.name ?? '<Brak nazwy>'} (${device.unit})`,
            description: `${device.description ?? '<Brak opisu>'}`
        } as Item;
    }

    const mapData = (page: Page<any>) => {
        const mappedItems = page?.content?.map(mapDeviceToItem)
        return mappedItems ?? [];
    }

    const onPicked = (item: Item) => {
        navigate(`/device/view/${item.value}`);
        attrs.justClose();
    }

    return (
        <PickerDialog
            title={`Urządzenia klienta ${attrs.client?.visibleName ?? ''}`}
            isOpen={attrs.isOpen}
            justClose={attrs.justClose}
            pickAndClose={onPicked}
            loadData={loadData}
            mapData={mapData}
            page={{limit: 5}}
            button={{ text: 'Otwórz urządzenie' }}
        />
    );
}

export default BrowseClientDevicesDialog;