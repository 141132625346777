import {Group, Stack, Text, Transition} from "@mantine/core";
import {IconCheck} from "@tabler/icons-react";

export type CompositeReportWorkPlaceholderAttrs = {
    visible: boolean;
}

function CompositeReportWorkPlaceholder(attrs: CompositeReportWorkPlaceholderAttrs) {
    if (!attrs.visible) {
        // using transition.
        // return null;
    }

    return (
        <Transition mounted={attrs.visible}>
            { styles =>
                <Stack h="100%">
                    <Group justify="center" style={styles} h="500px">
                        <Stack gap={0} align="center">
                            <IconCheck size={64}/>
                            <Text c="dimmed">Wszystko zrobione!</Text>
                        </Stack>
                    </Group>
                </Stack>
            }
        </Transition>
    );
}

export default CompositeReportWorkPlaceholder;