/* eslint-disable react-hooks/exhaustive-deps */
import {Client, ClientApi} from "../../../api/client/ClientApi";
import React, {useEffect, useState} from "react";
import {Device} from "../../../api/device/DeviceApi";
import {showNotification} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";
import Page from "../../../models/api/Page";
import {Box, Button, Card, LoadingOverlay, Text} from "@mantine/core";
import CardField from "../../cards/CardField";
import BrowseClientDevicesDialog from "../../../pages/client/BrowseClientDevicesDialog";
import {useDisclosure} from "@mantine/hooks";

export type ClientDevicesAttrs = {
    client: Client | undefined;
}

function ClientDevicesCard(attrs: ClientDevicesAttrs) {
    const [isBrowseClientDevicesDialogOpen, browseClientDevicesDialog] = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState<Page<Device>>()

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const reloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać urządzeń klienta',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const reload = () => {
        if (!attrs.client?.id) return;
        startLoading();

        ClientApi.getDevices({clientId: attrs.client.id, limit: 5, page: 0})
            .then(setDevices)
            .catch(reloadError)
            .finally(stopLoading);
    }

    const showMore = () => {
        browseClientDevicesDialog.open();
    }

    useEffect(reload, [attrs.client?.id]);

    return (
        <>
            <Box pos="relative">
                <LoadingOverlay visible={loading}/>

                <Card withBorder pos="relative" radius="md">
                    <Card.Section p={'md'}>
                        <Text fw={500}>Urządzenia</Text>
                    </Card.Section>

                    { devices?.content?.map(dev => <CardField title={dev.name ?? '<Urządzenie bez tytułu>'} description={dev.unit} />) }

                    <Card.Section>
                        <Button variant="transparent" fullWidth onClick={showMore}>
                            Pokaż więcej
                        </Button>
                    </Card.Section>
                </Card>
            </Box>

            <BrowseClientDevicesDialog
                isOpen={isBrowseClientDevicesDialogOpen}
                justClose={browseClientDevicesDialog.close}
                client={attrs.client}
            />
        </>
    );
}

export default ClientDevicesCard;