import {ReactNode, useState} from "react";
import {CompositeReport, SearchQuery} from "../api/composite/report/CompositeReportApi";
import CompositeReportContext from "./CompositeReportContext";
import {DataTableSortStatus} from "mantine-datatable";

const init: SearchQuery = {limit: 50, page: 0, sortBy: 'createdAt', sortDir: 'desc'};

function WrapCompositeReportContext({children}: {children: ReactNode}) {
    const [isFiltersVisible, setFiltersVisible] = useState(false);

    const [searchQuery, setSearchQuery] = useState<SearchQuery>(init);

    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<CompositeReport>>({
        columnAccessor: 'createdAt',
        direction: 'desc'
    });

    return (
        <CompositeReportContext.Provider value={{sortStatus, setSortStatus, isFiltersVisible, setFiltersVisible, searchQuery, setSearchQuery}}>
            { children }
        </CompositeReportContext.Provider>
    )
}

export default WrapCompositeReportContext;
