import {CompositeReport, SearchQuery} from "../api/composite/report/CompositeReportApi";
import {createContext} from "react";
import {DataTableSortStatus} from "mantine-datatable";

export type CompositeReportContextType = {
    searchQuery: SearchQuery;
    setSearchQuery: (s: SearchQuery) => void;

    setFiltersVisible: (x: boolean) => void;
    isFiltersVisible: boolean;

    sortStatus: DataTableSortStatus<CompositeReport>;
    setSortStatus: (s: DataTableSortStatus<CompositeReport>) => void;
}

const CompositeReportContext = createContext<CompositeReportContextType>({
    isFiltersVisible: false,
    setFiltersVisible: () => { console.error("CompositeReportContext.setFiltersVisible not implemented.")  },

    searchQuery: { limit: 50, page: 0 },
    setSearchQuery: () => { console.error("CompositeReportContext.setSearchQuery not implemented.") },

    sortStatus: { direction: 'desc', columnAccessor: 'createdAt' },
    setSortStatus: () => { console.error('CompositeReportContext.setSortStatus not implemented') }
});

export default CompositeReportContext;
