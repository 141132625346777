/* eslint-disable */
// noinspection DuplicatedCode

import {useParams} from "react-router-dom";
import {
    Action,
    CompositeReport,
    CompositeReportApi,
    DateReport
} from "../../../../api/composite/report/CompositeReportApi";
import {useEffect, useState} from "react";
import PageHeader from "../../../../components/layout/PageHeader";
import PageContent from "../../../../components/layout/PageContent";
import {DataTable, DataTableColumn} from "mantine-datatable";
import DateView from "../../../../components/date/DateView";
import {showNotification} from "@mantine/notifications";
import {IconTemperature, IconX} from "@tabler/icons-react";
import {useContextMenu} from "mantine-contextmenu";
import {useDisclosure} from "@mantine/hooks";
import EditRecordByHourDialog, {
    EditRecordByHourDialogPayload
} from "../../../../components/composite/report/EditRecordByHourDialog";
import {Device, DeviceApi} from "../../../../api/device/DeviceApi";
import NiceCommentsDialog from "../../../../components/comments/dialog/nice/NiceCommentsDialog";
import EditSimpleDataDialog from "../details/EditSimpleDataDialog";
import CompositeReportTableDetailsMenu from "./CompositeReportTableDetailsMenu";
import {modals} from "@mantine/modals";

// TODO:
//  Didn't have time to do this correctly.
const columns: DataTableColumn[] = [
    {
        accessor: 'date',
        title: 'Data',
        render: (record) => <DateView date={record.date as unknown as [number, number, number]}/>,
        noWrap: true,
        customCellAttributes: () => ({ style: { background: 'white' } })
    },
    {
        accessor: '_hour0:00',
        title: '00:00',
        render: (record) => <RenderCell dateReport={record as any} hour="00:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour1:00',
        title: '01:00',
        render: (record) => <RenderCell dateReport={record as any} hour="01:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour2:00',
        title: '02:00',
        render: (record) => <RenderCell dateReport={record as any} hour="02:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour3:00',
        title: '03:00',
        render: (record) => <RenderCell dateReport={record as any} hour="03:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour4:00',
        title: '04:00',
        render: (record) => <RenderCell dateReport={record as any} hour="04:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour5:00',
        title: '05:00',
        render: (record) => <RenderCell dateReport={record as any} hour="05:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour6:00',
        title: '06:00',
        render: (record) => <RenderCell dateReport={record as any} hour="06:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour7:00',
        title: '07:00',
        render: (record) => <RenderCell dateReport={record as any} hour="07:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour8:00',
        title: '08:00',
        render: (record) => <RenderCell dateReport={record as any} hour="08:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour09:00',
        title: '09:00',
        render: (record) => <RenderCell dateReport={record as any} hour="09:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour10:00',
        title: '10:00',
        render: (record) => <RenderCell dateReport={record as any} hour="10:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour11:00',
        title: '11:00',
        render: (record) => <RenderCell dateReport={record as any} hour="11:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour12:00',
        title: '12:00',
        render: (record) => <RenderCell dateReport={record as any} hour="12:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour13:00',
        title: '13:00',
        render: (record) => <RenderCell dateReport={record as any} hour="13:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour14:00',
        title: '14:00',
        render: (record) => <RenderCell dateReport={record as any} hour="14:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour15:00',
        title: '15:00',
        render: (record) => <RenderCell dateReport={record as any} hour="15:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour16:00',
        title: '16:00',
        render: (record) => <RenderCell dateReport={record as any} hour="16:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour17:00',
        title: '17:00',
        render: (record) => <RenderCell dateReport={record as any} hour="17:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour18:00',
        title: '18:00',
        render: (record) => <RenderCell dateReport={record as any} hour="18:00"/>,
        noWrap: true
    },
    {
        accessor: '_hour19:00',
        title: '19:00',
        render: (record) => <RenderCell dateReport={record as any} hour="19:00"/>
    },
    {
        accessor: '_hour20:00',
        title: '20:00',
        render: (record) => <RenderCell dateReport={record as any} hour="20:00"/>
    },
    {
        accessor: '_hour21:00',
        title: '21:00',
        render: (record) => <RenderCell dateReport={record as any} hour="21:00"/>
    },
    {
        accessor: '_hour22:00',
        title: '22:00',
        render: (record) => <RenderCell dateReport={record as any} hour="22:00"/>
    },
    {
        accessor: '_hour23:00',
        title: '23:00',
        render: (record) => <RenderCell dateReport={record as any} hour="23:00"/>
    }
];

function RenderCell(attrs: {dateReport: VDateReport, hour: string}) {
    const [record, setRecord] = useState<any>();

    const reload = () => {
        const rec = attrs.dateReport.records
            .map(x => x as any)
            .filter(x => x.data['_original_time'] === attrs.hour)
            .at(0);

        setRecord(rec);
    }

    useEffect(reload, [attrs.hour, attrs.dateReport]);

    if (!record) return <>,</>;

    return (
        <span style={{ color: record.red === true ? 'red' : '' }}>
            { 'Temp1' in record.data ? record.data['Temp1'] : "-" }
            ,
            { 'Temp2' in record.data ? record.data['Temp2'] : "-" }
        </span>
    );
}

type VDateReport = { red: boolean } & DateReport;

type VRecord = { red: boolean, data: any };

const isInvalid = (record: any, device: Device | undefined) => {
    if (!device) return false;

    const [temp1, temp2] = [
        'Temp1' in record ? record['Temp1'] : undefined,
        'Temp2' in record ? record['Temp2'] : undefined
    ];

    const [min, max] = [device.minAllowableTemp, device.maxAllowableTemp];

    // Missing temp1 or temp2
    if (temp1 === undefined || temp2 === undefined) { return true; }

    if (min !== undefined && min !== null) {
        if (temp1 < min || temp2 < min) {
            return true;
        }
    }

    if (max !== undefined && max !== null) {
        if (temp1 > max || temp2 > max) {
            return true;
        }
    }

    return false;
}

const validateRecords = (records: any[], dev: Device | undefined) => {
    if (!dev) {
        return records.map(rec => ({ red: false, data: rec }) as VRecord);
    }

    return records.map(rec => ({ red: isInvalid(rec, dev), data: rec }) as VRecord);
}

function mapData(compositeReport?: CompositeReport, device?: Device) {
    const dateReports = compositeReport?.sections?.flatMap(x => x.content) ?? [];
    return dateReports.map(dev => ({ red: false, ...dev, records: validateRecords(dev.records, device) }) as VDateReport);
}

function CompositeReportTableDetails() {
    const { compositeReportId } = useParams();
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState<CompositeReport>();
    const [data, setData] = useState<VDateReport[]>();
    const { showContextMenu } = useContextMenu();
    const [editRecordDialogPayload, setEditRecordDialogPayload] = useState<EditRecordByHourDialogPayload>();
    const [isEditRecordDialog, editRecordDialog] = useDisclosure();
    const [device, setDevice] = useState<Device>();

    const [isCommentsDialog, commentsDialog] = useDisclosure();
    const [isEditSimpleDataDialogOpen, editSimpleDataDialog] = useDisclosure();

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ]

    const loadData = (reportFromApi: CompositeReport) => {
        setReport(reportFromApi);
        setData(mapData(reportFromApi, device));
    }

    const reloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            icon: <IconX/>,
            color: 'red'
        });
    }

    const reload = () => {
        if (!compositeReportId) return;

        startLoading();

        CompositeReportApi.details(compositeReportId)
            .then(loadData)
            .catch(reloadError)
            .finally(stopLoading);
    }

    const loadDeviceError = () => {
        showNotification({
            title: 'Nie udało się pobrać urządzenia',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        })
    }

    const loadDevice = () => {
        if (!report) return;

        DeviceApi.search({ q: report.unit, limit: 1, page: 0 })
            .then((page) => setDevice(page.content?.at(0)))
            .catch(loadDeviceError);
    }

    const doEditRecord = (event: any) => {
        const payload: EditRecordByHourDialogPayload = {
            compositeReportId: compositeReportId!!,
            hour: event.column.title,
            dateReportId: (event.record as any).id!!
        }

        setEditRecordDialogPayload(payload);
        editRecordDialog.open();
    }

    const doApproveSuccess = () => {
        showNotification({
            title: 'Zatwierdzono raportów',
            message: `Pomyślnie zatwierdzono raport.`,
            color: 'green'
        })
    }

    const doApproveError = () => {
        showNotification({
            title: `Nie udało się zatwierdzić raportu`,
            message: 'Nie można zatwierdzić ponownie tego samego raportu. Jeśli uważasz, że to błąd skontaktuj się z administratorem.',
            color: 'red'
        });
    }

    const doApprove = () => {
        const action: Action = { action: 'approve', selectedElements: [report?.id!!] };

        startLoading();

        CompositeReportApi.performAction(action)
            .then(doApproveSuccess)
            .then(reload)
            .catch(doApproveError)
            .finally(stopLoading);
    }

    const approve = () => {
        if (!report) {
            showMissingReportNotification();
            return;
        }

        modals.openConfirmModal({
            title: "Zatwierdzanie raportów",
            children: `Czy chcesz zatwierdzić ${report?.title ?? '???'}?`,
            labels: { confirm: 'Zatwierdź', cancel: 'Anuluj' },
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: doApprove
        });
    }

    const doSendReportSuccess = () => {
        showNotification({
            title: `Utworzono prototyp wiadomości`,
            message: 'Możesz teraz edytować tą wiadomość, a później ją wysłać',
            color: 'green'
        });
    }

    const doSendReportError = () => {
        showNotification({
            title: `Nie udało się wysłać raportu`,
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSendReport = () => {
        const action = { action: 'send', selectedElements: [report?.id!!]} as Action;

        startLoading();

        CompositeReportApi.performAction(action)
            .then(doSendReportSuccess)
            .then(reload)
            .catch(doSendReportError)
            .finally(stopLoading);
    }

    const showMissingReportNotification = () => showNotification({
        title: 'Nie udało się wysłać',
        message: 'Proszę odświeżyć stronę, jeśli problem nie ustąpi proszę skontaktować się z administratorem',
        color: 'red',
        icon: <IconX/>
    });

    const sendReport = () => {
        if (!report) {
            showMissingReportNotification();
            return;
        }

        modals.openConfirmModal({
            title: "Wysyłanie raportu",
            children: (<>
                Czy na pewno chcesz wysłać {report?.title ?? '<Raport bez tytułu>'}?<br/>
                Spowoduje to utworzenie prototypu wiadomości, którą można później edytować
            </>),
            labels: {confirm: `Wyślij`, cancel: 'Nie wysyłaj'},
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: () => doSendReport()
        });
    }


    useEffect(reload, [compositeReportId]);

    useEffect(loadDevice, [report]);

    return (
        <>
            <PageHeader
                title={`Szczegóły raportu ${report?.title ?? ''}`}
                pageTitle={report?.title ?? 'TermoControl'}
                url={'/composite/report'}
                action={<CompositeReportTableDetailsMenu
                    editData={editRecordDialog.open}
                    editComments={commentsDialog.open}
                    approveReport={approve}
                    sendReport={sendReport}
                    deleteReport={() => showNotification({title: 'Usuń z poziomu tabeli', message: '...'})}
                />}
            />

            <PageContent>
                <DataTable
                    pinFirstColumn
                    height="65vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    striped
                    highlightOnHover
                    backgroundColor="transparent"
                    minHeight={150}
                    columns={columns}
                    records={mapData(report, device)}
                    fetching={loading}
                    onCellContextMenu={(data) => {
                        showContextMenu([
                            {
                                key: `edit-record-in-${data.record.id}`,
                                title: 'Edytuj odczyt',
                                icon: <IconTemperature size={16}/>,
                                onClick: () => doEditRecord(data)
                            }
                        ])(data.event);
                    }}
                />
            </PageContent>

            <EditRecordByHourDialog
                isOpen={isEditRecordDialog}
                justClose={editRecordDialog.close}
                closeAndUpdate={() => { editRecordDialog.close(); reload(); }}
                payload={editRecordDialogPayload}
            />

            <NiceCommentsDialog
                isOpen={isCommentsDialog}
                justClose={commentsDialog.close}
                closeAndUpdate={() => { commentsDialog.close(); reload(); }}
                compositeReport={report}
            />

            <EditSimpleDataDialog
                isOpen={isEditSimpleDataDialogOpen}
                justClose={editSimpleDataDialog.close}
                closeAndUpdate={() => { reload(); editSimpleDataDialog.close(); }}
                compositeReport={report}
            />
        </>
    );
}

export default CompositeReportTableDetails;

// TODO: CompositeReportTableDetails
//  1. Colours are loaded only after refresh (e.g. changing record's value)
