/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {SmtpConfig, SmtpConfigApi} from "../../../api/smtp/config/SmtpConfigApi";
import {showNotification} from "@mantine/notifications";
import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {useForm} from "@mantine/form";
import {ActionIcon, Button, Menu, PasswordInput, Select, TextInput} from "@mantine/core";
import TestSmtpConfigDialog from "./TestSmtpConfigDialog";
import {useDisclosure} from "@mantine/hooks";
import {IconDotsVertical} from "@tabler/icons-react";

type SmtpConfigPageMenuAttrs = {
    testConfig: () => void;
}

function SmtpConfigPageMenu(attrs: SmtpConfigPageMenuAttrs) {
    return (
        <Menu width={200}>
            <Menu.Target>
                <ActionIcon variant="transparent">
                    <IconDotsVertical size={32}/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item onClick={attrs.testConfig}>Przetestuj</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}

function SmtpConfigPage() {
    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [isTestConfigDialogOpen, testConfigDialog] = useDisclosure();

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            hostname: '',
            port: 0,
            username: '',
            password: '',
            sender: '',
            startTls: 'true'
        },

        validate: {
            hostname: (h) => h.length < 4 ? 'Przynajmniej 4 znaki' : null,
            username: (h) => h.length < 2 ? 'Przynajmniej 2 znaki' : null,
            password: (h) => h.length < 2 ? 'Przynajmniej 2 znaki' : null,
            port: (h) => h > 25000 || h < 1 ? 'Proszę wskazać poprawny port.' : null,
            startTls: (h) => h === 'true' || h === 'false' ? null : 'Proszę wybrać opcję',
        }
    });

    const startLoading = () => { setLoading(true); };

    const stopLoading = () => { setLoading(false); };

    const startSaving = () => { setSaving(true); };

    const stopSaving = () => { setSaving(false); };

    const onLoadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const load = () => {
        startLoading();

        SmtpConfigApi.getActive()
            .then(loadFormData)
            .catch(onLoadError)
            .finally(stopLoading);
    }

    const loadFormData = (smtpConfig: SmtpConfig) => {
        form.setValues({
            hostname: smtpConfig?.hostname ?? '',
            port: smtpConfig?.port ?? 0,
            username: smtpConfig?.username,
            password: smtpConfig?.password,
            startTls: smtpConfig?.startTls === true ? 'true' : 'false',
            sender: smtpConfig?.sender
        });
    }

    useEffect(load, []);

    const onSubmitSuccess = () => {
        showNotification({
            title: 'Zapisano',
            message: 'Teraz można przetestować konfigurację!',
            color: 'green'
        });
    }

    const onSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać konfiguracji',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem',
            color: 'red'
        });

        form.setFieldError('hostname', 'Nie udało się zapisać konfiguracji');
    }

    const doSubmit = (values: any) => {
        startSaving();

        SmtpConfigApi.edit({...values, sender: values.username, startTls: values.startTls === 'true'})
            .then(onSubmitSuccess)
            .catch(onSubmitError)
            .finally(stopSaving);
    }

    const testConfig = () => {
        testConfigDialog.open();
    }

    return (
        <>
            <PageHeader
                title="Ustawienia SMTP"
                url="/smtp/config"
                action={<SmtpConfigPageMenu testConfig={testConfig}/>}
            />

            <PageContent>
                <form onSubmit={form.onSubmit(doSubmit)}>
                    <TextInput
                        withAsterisk
                        label="Nazwa hosta"
                        description="Np. ftp.termmed.pl"
                        key={form.key('hostname')}
                        { ...form.getInputProps('hostname') }
                    />

                    <TextInput
                        withAsterisk
                        type="number"
                        label="Numer portu"
                        description="Np. 123"
                        key={form.key('port')}
                        { ...form.getInputProps('port') }
                    />

                    <TextInput
                        withAsterisk
                        label="Nazwa użytkownika"
                        description="Np. TermMed"
                        key={form.key('username')}
                        { ...form.getInputProps('username') }
                    />

                    <PasswordInput
                        withAsterisk
                        label="Hasło"
                        key={form.key('password')}
                        { ...form.getInputProps('password') }
                    />

                    <Select
                        label="Bezpieczeństwo"
                        placeholder="-----"
                        description="Zaszyfruj wysyłane wiadomości"
                        data={[{label: 'Start TLS', value: 'true'}, { label: 'Brak (niezalecane)', value: 'false' }]}
                        key={form.key('startTls')}
                        { ...form.getInputProps('startTls') }
                    />

                    <Button type="submit" loading={saving}>
                        { loading ? 'Ładowanie...' : 'Zapisz' }
                    </Button>
                </form>
            </PageContent>

            <TestSmtpConfigDialog isOpen={isTestConfigDialogOpen} justClose={testConfigDialog.close}/>
        </>
    );
}

export default SmtpConfigPage;