/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {Button, Checkbox, Modal, Stack, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {SmtpConfigApi} from "../../../api/smtp/config/SmtpConfigApi";
import {showNotification} from "@mantine/notifications";
import {IconCircleCheck, IconCircleX} from "@tabler/icons-react";

export type TestSmtpConfigDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
}

function TestSmtpConfigDialog(attrs: TestSmtpConfigDialogAttrs) {
    const [acceptRisk, setAcceptRisk] = useState(false);
    const [loading, setLoading] = useState(false);

    const form = useForm({
        mode: "uncontrolled",
        initialValues: { email: '' },
        validate: { email: e => e.length > 2 ? null : 'Pole wymagane' }
    });

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const onSubmitError = () => {
        showNotification({
            title: 'Nie udało się wysłać testowej wiadomości',
            message: 'Proszę upewnić się, że konfiguracja jest poprawna. Jeśli potrzebujesz pomocy skontaktuj się z administratorem ;)',
            color: 'red',
            icon: <IconCircleX size={32} />
        });

        form.setFieldError('email', "Nie udało się wysłać testowej wiadomości");
    }

    const onSubmitSuccess = () => {
        showNotification({
            title: 'Konfiguracja jest poprawna!',
            message: 'Wiadomość została wysłana pomyślnie',
            color: 'green',
            icon: <IconCircleCheck size={32} />
        });
    }

    const doSubmit = (values: {email: string}) => {
        startLoading();

        SmtpConfigApi.doTest(values.email)
            .then(onSubmitSuccess)
            .catch(onSubmitError)
            .finally(stopLoading);
    }

    const resetFormData = () => {
        setAcceptRisk(false);
        form.reset();
    }

    useEffect(resetFormData, [attrs.isOpen]);

    return (
        <Modal size="xl" centered opened={attrs.isOpen} onClose={attrs.justClose} title="Testowanie konfiguracji SMTP">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        type="email"
                        description="Adres E-mail do wysyłki testowej wiadomości"
                        label="Adres E-mail"
                        placeholder="Adres E-mail"
                        key={form.key('email')}
                        { ...form.getInputProps('email') }
                    />

                    <Checkbox
                        label="Spróbujemy wysłać testową wiadomość na podany adres"
                        onChange={(e) => setAcceptRisk(e.target.checked)}
                    />

                    <Button type="submit" disabled={!acceptRisk} loading={loading}>
                        Wyślij
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
}

export default TestSmtpConfigDialog;