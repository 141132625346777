import UserApi, {User} from "../api/user/UserApi";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import AuthContext from "./AuthContext";
import {showNotification} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";

export type UsersContextType = {
    users: User[] | undefined;
    setUsers: (users: User[]) => void;
}

export const UsersContext = createContext<UsersContextType>({
    users: undefined,
    setUsers: () => { throw new Error('UsersContext not initialized in WrapUsersContext.') }
});

function WrapUsersContext({children}: {children: ReactNode}) {
    const authContext = useContext(AuthContext);
    const [users, setUsers] = useState<User[]>();

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać listy użytkowników',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const reload = () => {
        if (!authContext.isUserLogged()) {
            console.log('User is not logged.\nSkipping UsersContext initialization');
            return;
        }

        UserApi.simpleList()
            .then(setUsers)
            .catch(onReloadError);
    }

    useEffect(reload, [authContext])

    return (
        <UsersContext.Provider value={{users, setUsers}}>
            {children}
        </UsersContext.Provider>
    )
}

export default WrapUsersContext;