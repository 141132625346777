import {Button, Flex, TextInput, Transition} from "@mantine/core";
import {useForm} from "@mantine/form";
import {DatePickerInput} from "@mantine/dates";
import {useState} from "react";
import {SearchQuery} from "../../../api/device/report/DeviceReportApi";

export type DeviceReportFilterAttrs = {
    isOpen: boolean;
    searchQuery: SearchQuery;
    doUpdate: (s: SearchQuery) => void;
}

function formatDate(date: Date|null) {
    if (!date) {
        return undefined
    }

    let [year, month]: [number, string|number] = [ date.getFullYear(), date.getMonth() + 1];

    if (month < 10) {
        month = '0' + month;
    }
    let day: string|number = date.getDate();
    if (day < 10) {
        day = '0' + day;
    }
    return `${year}-${month}-${day}`;
}

function DeviceReportFilter(attrs: DeviceReportFilterAttrs) {
    const [dateRange, setDateRange] = useState<[Date|null, Date|null]>([null, null]);

    const form = useForm({
        mode: 'uncontrolled',

        initialValues: {
            query: '',
            unit: ''
        }
    });

    const doSubmit = (values: any) => {
        const s: SearchQuery = {
            ...attrs.searchQuery,
            unit: values.unit === '' ? undefined : values.unit,
            q: values.query === '' ? undefined : values.query,
            dateMin: formatDate(dateRange[0]),
            dateMax: formatDate(dateRange[1])
        };

        attrs.doUpdate(s);
    }

    return (
        <Transition mounted={attrs.isOpen} transition="pop">

            { styles =>
                <form onSubmit={form.onSubmit(doSubmit)} style={styles}>
                    <Flex direction="row" align="center" gap="md">
                        <TextInput
                            placeholder="Zapytanie"
                            key={form.key('query')}
                            { ...form.getInputProps('query') }
                        />

                        <TextInput
                            placeholder="Jednostka"
                            key={form.key('unit')}
                            { ...form.getInputProps('unit') }
                        />

                        <DatePickerInput
                            type="range"
                            placeholder="Zakres dat"
                            value={dateRange}
                            onChange={setDateRange}
                        />

                        <Button type="submit">
                            Szukaj
                        </Button>
                    </Flex>
                </form>
            }

        </Transition>
    );
}

export default DeviceReportFilter;
