import {Validation} from "../CompositeReportApi";
import {IconX} from "@tabler/icons-react";
import {ActionIcon, Group, HoverCard, Text} from "@mantine/core";

export type ValidationCellAttrs = {
    validations: Validation[];
}

function ValidationCell(attrs: ValidationCellAttrs) {
    if (attrs.validations.length === 0) {
        return (<i style={{ color: 'green' }} className="fa fa-check"></i>);
    }

    return (
        <>
            <Group justify="left">
                <HoverCard width={280} shadow="md" openDelay={1500}>
                    <HoverCard.Target>
                        <ActionIcon variant="transparent">
                            <IconX color="red"/>
                        </ActionIcon>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                        <Text>
                            Łącznie {(attrs.validations as any[]).length} błędów
                        </Text>
                    </HoverCard.Dropdown>
                </HoverCard>
            </Group>
        </>
    );
}

export default ValidationCell;
