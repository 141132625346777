import {AppShell, Burger, Group} from "@mantine/core";
import "./Navbar.css";
import {ReactNode, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {SelectedPageContext} from "../../contexts/SelectedPageContext";

export type NavbarAttrs = {
    isNavOpen: boolean;
    toggle: () => void;
};

const items: NavbarItemType[] = [
    {
        url: "/",
        title: 'Strona główna',
        icon: <i className="navbar-list-item-icon fa fa-home"></i>
    },

    {
        isGroup: true,
        title: "Urządzenia"
    },

    {
        url: '/device',
        title: 'Urządzenia',
        icon: <i className="navbar-list-item-icon fa fa-computer"></i>
    },

    {
        url: '/device/report',
        title: 'Raporty urządzeń',
        icon: <i className="navbar-list-item-icon fa fa-computer"></i>
    },

    {
        url: "/client",
        title: 'Klienci',
        icon: <i className="navbar-list-item-icon fa fa-person"></i>
    },

    {
        url: '/calendar/event',
        title: 'Kalendarz',
        icon: <i className="navbar-list-item-icon fa fa-calendar"></i>
    },

    {
        isGroup: true,
        title: 'Raporty'
    },

    {
        url: '/composite/report',
        title: 'Raporty',
        icon: <i className="navbar-list-item-icon fa fa-paperclip"></i>
    },

    {
        url: '/comment/presets',
        title: 'Komentarze',
        icon: <i className="navbar-list-item-icon fa fa-paperclip"></i>
    },

    {
        url: '/pdf/config',
        title: 'Ustawienia PDF',
        icon: <i className="navbar-list-item-icon fa fa-paperclip"></i>
    },

    {
        isGroup: true,
        title: 'Wysyłka'
    },

    {
        url: '/send/report',
        title: 'Wysyłka raportów',
        icon: <i className="navbar-list-item-icon fa fa-paperclip"></i>
    },

    {
        url: '/send/report/config',
        title: 'Ustawienia wysyłki',
        icon: <i className="navbar-list-item-icon fa fa-mail-forward"></i>
    },

    {
        url: '/smtp/config',
        title: 'Ustawienia SMTP',
        icon: <i className="navbar-list-item-icon fa fa-mail-forward"></i>
    },

    {
        isGroup: true,
        title: 'FTP'
    },

    {
        url: '/ftp/config',
        title: 'Ustawienia FTP',
        icon: <i className="navbar-list-item-icon fa fa-server"></i>
    },

    {
        isGroup: true,
        title: "Użytkownicy"
    },

    {
        url: '/user',
        title: 'Użytkownicy',
        icon: <i className="navbar-list-item-icon fa fa-person"></i>
    }
]

type NavbarItemType = {
    isGroup?: boolean;
    url?: string;
    title: string;
    icon?: ReactNode;
};

function NavbarListItem({item}: {item: NavbarItemType}) {
    const { selectedUrl, setSelectedUrl } = useContext(SelectedPageContext);
    const navigate = useNavigate();

    if (item.isGroup === true){
        return (
            <p className="navbar-list-header">
                {item.title}
            </p>
        );
    }

    const click = () => {
        if(setSelectedUrl) setSelectedUrl(item.url ?? null);
        navigate(item.url ?? '/');
    }

    return (
        <div className={`navbar-item ${selectedUrl === item.url ? 'active' : ''}`} onClick={click}>
            { item.icon }
            <p>{ item.title }</p>
        </div>
    );
}

function Navbar(attrs: NavbarAttrs) {
    return (
        <AppShell.Navbar
            p="md"
            className="navbar"
            style={{ borderRadius: '0', background: 'var(--nav-background)', color: 'var(--nav-text-color)'}}
        >
            <Group>
                <Burger
                    color={'var(--nav-text-color)'}
                    opened={attrs.isNavOpen}
                    onClick={attrs.toggle}
                    hiddenFrom="sm"
                    size="sm"
                />
            </Group>

            <div className="navbar-item-list">
                {
                    items.map(item => <NavbarListItem key={item.url} item={item}/>)
                }
            </div>

        </AppShell.Navbar>
    );
}

export default Navbar;
