import {SendStatus} from "../../../api/send/report/SendReportApi";

export type SendStatusCellAttrs = {
    status: SendStatus | undefined;
    skipText?: boolean;
}

function SendReportStatusCell({status, skipText}: SendStatusCellAttrs) {
    if (!status) {
        return <i style={{color: 'grey'}} className="fa fa-circle"></i>;
    }

    const translate = () => {
        const statusLower = status.toLowerCase();

        if (statusLower === 'failed_to_read_file') {
            return 'Nie udało się odczytać pliku.';
        }

        else if (statusLower === 'ftp_error') {
            return 'Błąd FTP';
        }

        else if (statusLower === 'smtp_error') {
            return 'Błąd SMTP';
        }

        else if (statusLower === 'missing_email') {
            return 'Błąd - Brakuje maila odbiorcy';
        }

        else if (statusLower === 'missing_pdf') {
            return 'Błąd - Brakuje załącznika PDF';
        }

        else if (statusLower === 'not_approved') {
            return 'Błąd - Raport nie zatwierdzony.';
        }
    }

    if (status.toLowerCase() === 'ok') {
        return (
            <i style={{color: 'green'}} className="fa fa-check"></i>
        );
    }

    return (
        <>
            <i style={{color: 'red'}} className="fa fa-x"></i>
            { skipText === true ? '' : translate() }
        </>
    );
}

export default SendReportStatusCell;