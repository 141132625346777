import {useEffect, useState} from "react";
import PageHeader from "../../components/layout/PageHeader";
import PageContent from "../../components/layout/PageContent";
import {DataTable, DataTableColumn, useDataTableColumns} from "mantine-datatable";
import UserApi, {User} from "../../api/user/UserApi";
import {showNotification} from "@mantine/notifications";
import UserFilterDialog from "./UserFilterDialog";
import {useDisclosure} from "@mantine/hooks";
import {ActionIcon, Menu} from "@mantine/core";
import CreateUserDialog from "./CreateUserDialog";
import {modals} from "@mantine/modals";
import {IconCheck, IconCircleCheck, IconCircleX, IconKey, IconPencil, IconTrash, IconX} from "@tabler/icons-react";
import ChangePasswordDialog from "./ChangePasswordDialog";
import {useContextMenu} from "mantine-contextmenu";
import EditUserDialog from "./EditUserDialog";
import Cell from "../../components/table/Cell";

function UserAdministratorCell({user}: {user?: { isAdmin?: boolean }}) {
    if (!user) { return <strong>???</strong> }

    return (
        <>
            { user.isAdmin === true ? <i style={{color: 'green'}} className="fa fa-check"/> : <i style={{color: 'red'}} className="fa fa-x"></i> }
        </>
    );
}

type UserPageMenuAttrs = {
    newUser: () => void;
    deleteSelected: () => void;
    filterResults: () => void;
}

function UserPageMenu(attrs: UserPageMenuAttrs) {
    return (
        <Menu width={200}>
            <Menu.Target>
                <ActionIcon variant="light">
                    <i className="fa fa-filter"></i>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Filtrowanie</Menu.Label>
                <Menu.Item onClick={attrs.filterResults}>Filtrowanie wyników</Menu.Item>

                <Menu.Label>Zarządzanie</Menu.Label>
                <Menu.Item onClick={attrs.newUser}>Nowy użytkownik</Menu.Item>

                <Menu.Label>Zaznaczenie wielokrotne</Menu.Label>
                <Menu.Item color="red" onClick={attrs.deleteSelected}>Usuń zaznaczonych</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}

const columns: DataTableColumn[] = [
    { accessor: 'id', title: '#', toggleable: true, defaultToggle: false },
    { accessor: 'username', title: 'Nazwa użytkownika' },
    {
        accessor: 'emailAddress',
        title: 'Adres E-mail',
        toggleable: true,
        render: (user) => <Cell orOptional={user.emailAddress} />
    },
    {
        accessor: 'isAdmin',
        title: 'Administrator',
        render: (user) => <UserAdministratorCell user={user} />,
        toggleable: true
    }
];

function UserPage() {
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const tableKey = 'User-table-key';
    const [data, setData] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [q, setQ] = useState<undefined | string>('');
    const [isFilterDialogOpen, filterDialog] = useDisclosure();
    const [isCreateDialogOpen, createDialog] = useDisclosure();
    const [changePasswordSelectedUser, setChangePasswordSelectedUser] = useState<User>();
    const [isChangePasswordDialog, changePasswordDialog] = useDisclosure();
    const [editUserSelectedUser, setEditUserSelectedUser] = useState<User>();
    const [isEditUserDialog, editUserDialog] = useDisclosure();
    const { showContextMenu } = useContextMenu();

    const {effectiveColumns} = useDataTableColumns({key: tableKey, columns});

    const startLoading = () => { setLoading(true); };

    const stopLoading = () => { setLoading(false); };

    const newUser = () => {
        createDialog.open();
    };

    const getSelectedElementIds = () => selectedUsers.map(user => user.id);

    const doDeleteSelectedError = () => {
        showNotification({
            title: 'Nie usunięto wybranych użytkowników',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            icon: <IconCircleX size={16}/>,
            color: 'red'
        });
    }

    const doDeleteSelectedSuccess = () => {
        showNotification({
            title: 'Usunięto wybranych użytkowników',
            message: 'Nie będą mogli już logować się do systemu',
            icon: <IconCircleCheck size={16}/>,
            color: 'green'
        });
    }

    const resetSelectedElements = () => {
        setSelectedUsers([]);
    }

    const doDeleteSelected = () => {
        const action = { action: 'delete', selectedElements: getSelectedElementIds()}

        startLoading();

        UserApi.performAction(action)
            .then(doDeleteSelectedSuccess)
            .then(reload)
            .then(resetSelectedElements)
            .catch(doDeleteSelectedError)
            .finally(stopLoading);
    }

    const deleteSelected = () => {
        modals.openConfirmModal({
            title: "Generowanie raportu",
            children: (<>
                Czy na pewno chcesz usunąć zaznaczonych użytkowników?<br></br>
                Stracą oni dostęp do systemu.
            </>),
            labels: { confirm: `Tak, usuń`, cancel: 'Anuluj' },
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: doDeleteSelected
        });
    };

    const filterResults = () => {
        filterDialog.open();
    }

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Upewnij się, że masz odpowiednie uprawnienia (Administratora).',
            color: 'red'
        });
    }

    const reload = () => {
        startLoading();

        UserApi.search({q})
            .then(setData)
            .catch(onReloadError)
            .finally(stopLoading);
    }

    const doDeleteUserError = () => {
        showNotification({
            title: "Nie udało się usunąć użytkownika",
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem.',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doDeleteUserSuccess = () => {
        showNotification({
            title: "Usunięto",
            message: 'Użytkownik został usunięty z systemu i nie może już z niego korzystać.',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doDeleteUser = (user: User) => {
        const action = { action: 'delete', selectedElements: [user.id]}

        startLoading();

        UserApi.performAction(action)
            .then(doDeleteUserSuccess)
            .then(reload)
            .catch(doDeleteUserError)
            .finally(stopLoading);
    }

    const deleteUser = (user: User) => {
        modals.openConfirmModal({
            title: `Czy chcesz usunąć użytkownika ${user.username ?? ''}`,
            children: `Spowoduje to całkowite usunięcie go z systemu.`,
            labels: { confirm: 'Tak, usuń', cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: () => doDeleteUser(user)
        });
    }

    const changePassword = (user: User) => {
        setChangePasswordSelectedUser(user);
        changePasswordDialog.open();
    }

    const editUser = (user: User) => {
        setEditUserSelectedUser(user);
        editUserDialog.open();
    }

    useEffect(reload, [q]);

    return (
        <>
            <PageHeader
                title="Użytkownicy"
                url="/user"
                action={<UserPageMenu filterResults={filterResults} newUser={newUser} deleteSelected={deleteSelected}/>}
            />

            <PageContent>
                <DataTable
                    selectedRecords={selectedUsers}
                    onSelectedRecordsChange={setSelectedUsers}
                    striped
                    highlightOnHover
                    backgroundColor="transparent"
                    loaderBackgroundBlur={1}
                    height="60vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    minHeight="150px"
                    columns={effectiveColumns}
                    records={data}
                    fetching={loading}
                    storeColumnsKey={tableKey}
                    onRowContextMenu={(data) => {
                        showContextMenu([
                            {
                                title: 'Edytuj',
                                icon: <IconPencil size={16}/>,
                                key: 'edit-user-ctx-menu-item-(users)',
                                onClick: () => editUser(data.record)
                            },
                            {
                                title: 'Zmień hasło',
                                icon: <IconKey size={16}/>,
                                key: 'change-password-ctx-menu-item-(users)',
                                onClick: () => changePassword(data.record)
                            },
                            {
                                title: 'Usuń',
                                icon: <IconTrash size={16}/>,
                                key: 'delete-ctx-menu-item(users)',
                                onClick: () => deleteUser(data.record),
                                color: 'red'
                            }
                        ])(data.event)
                    }}
                />
            </PageContent>

            <UserFilterDialog
                isOpen={isFilterDialogOpen}
                onClose={filterDialog.close}
                q={q}
                setQ={setQ}
            />

            <ChangePasswordDialog
                isOpen={isChangePasswordDialog}
                justClose={changePasswordDialog.close}
                closeAndUpdate={() => { reload(); changePasswordDialog.close(); }}
                user={changePasswordSelectedUser}
            />

            <CreateUserDialog
                isOpen={isCreateDialogOpen}
                onClose={() => {createDialog.close(); reload(); }}
            />

            <EditUserDialog
                isOpen={isEditUserDialog}
                justClose={editUserDialog.close}
                closeAndUpdate={() => { editUserDialog.close(); reload(); }}
                user={editUserSelectedUser}
            />
        </>
    );
}

export default UserPage;
