import {Button, Modal, Stack, TextInput} from "@mantine/core";
import {useState} from "react";

export type DeviceFilterDialogAttrs = {
    isOpen: boolean;

    q?: string;
    setQ: (q?: string) => void;
    onClose: () => void;
};

function DeviceFilterDialog(data: DeviceFilterDialogAttrs) {
    const [q, setQ] = useState(data.q);

    const onClose = () => {
        data.onClose();
        data.setQ(q);
    }

    return (
        <>
            <Modal opened={data.isOpen} onClose={data.onClose} title={"Filtrowanie"}>
                <Stack>
                    <TextInput
                        label={"Zapytanie"}
                        placeholder={"Zapytanie"}
                        variant={"filled"}
                        value={q}
                        onChange={(ev) => setQ(ev.target.value)}
                    />

                    <Button onClick={onClose}>Szukaj...</Button>
                </Stack>
            </Modal>
        </>
    );
}

export default DeviceFilterDialog;
