import {DataTable, DataTableColumn} from "mantine-datatable";
import {Anchor, Grid, ScrollArea} from "@mantine/core";

export type CurrentCommentsSectionAttrs = {
    comments: string[];
    commentsNotSaved: string[];
    addOrDelete: (s: string) => void;
    isPresent: (s: string) => boolean;
}

function CurrentCommentsSection(attrs: CurrentCommentsSectionAttrs) {
    const columns: DataTableColumn[] = [{
        accessor: 'c',
        title: 'Komentarz (Przed zapisem)'
    }];

    const columnsNotSaved: DataTableColumn[] = [{
        accessor: 'c',
        title: 'Komentarz (Nie zapisany)',
        render: (rec) => <Anchor href="#" onClick={() => attrs.addOrDelete(rec.c as unknown as string)}>{rec.c as string}</Anchor>
    }];

    return (
        <Grid grow style={{ paddingLeft: '10px' }} w="100%" h="100%">
            <ScrollArea type="scroll" h="75vh">
                <DataTable
                    style={{ paddingLeft: '10px' }}
                    width="100%"
                    w="100%"
                    records={(attrs.comments ?? []).map(x => ({c: x}))}
                    columns={columns}
                />
            </ScrollArea>

            <ScrollArea type="scroll" h="75vh">
                <DataTable
                    style={{ paddingLeft: '10px' }}
                    width="100%"
                    w="100%"
                    records={(attrs.commentsNotSaved ?? []).map(x => ({c: x}))}
                    columns={columnsNotSaved}
                />
            </ScrollArea>
        </Grid>
    );
}

export default CurrentCommentsSection;
